//Author June Leow
//Date Jul 17th, 2024
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { callBackGenerator, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator } from '../../util/util';
import MyPlacesAutocomplete from '../util/my-places-autocomplete';
//initialize the state
const initialState = {
  self:{},
  paymentMethod:'Authorize.net',
  appraisalTypeFilters:{
    '2 Units':['1004','1073','1075','2055'],
    '3 Units':['1004','1073','1075','2055'],
    '4 Units':['1004','1073','1075','2055'],
    'Low Rise Condo':['1004','1025','2055'],
    'Mid-Rise Condo':['1004','1025','2055'],
    'High Rise Condo':['1004','1025','2055'],
    'Detached Condo':['1004','1025','2055'],
    'Manufactured Housing':['1073','1075'],
    'SFR - Detached':['1073','1025','1075'],
    'SFR - Attached':['1073','1025','1075'],
    'PUD - Attached':['1073','1075'],
    'PUD - Detached':['1073','1075'],
  },
  standAlone:[
    'Rent Survey (1007)',
    'Operating Income Statement (216)',
    'Appraisal Update / Re-Cert (1004D)',
    'Appraisal Final Inspection (1004D / HUD 92051)',
    'Appraisal Disaster Inspection In & Out (1004D)',
    'Drive By Conv Condo (1075)',
    'Drive By Conv SFR (2055)',
    'Exterior (2075)',
    'Enhanced Desk Review',
    'Field Review',
    'Other',
  ],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  apn:'',
  orderType:'',
  loanTypes:[],
  availableAppraisalTypes:[],
  availableAppraisalTypesFiltered:[],
  propertyTypes:[],
  associatedClient:[],
  clientBranches:[],
  openByClientBranches:[],
  clientEntities:[],
  appraisalType:'',
  subForms:[],
  subForm: false,
  expectedDate:'',

  propertyStreet:'',
  propertyCity:'',
  propertyState:'',
  propertyCounty:'',
  propertyZip:'',
  propertyType:'',
  address:'',
  occupant:'',

  specialInstructions:'',
  specialInstructionsForAMC:'',

  propertyAccessTitle:'',
  propertyAccessName:'',
  propertyAccessEmail:'',
  propertyAccessHomePhone:'',
  propertyAccessWorkPhone:'',
  propertyAccessMobilePhone:'',

  loanNumber:'Quote',
  loanAmount:'',
  estimatedValue:'',
  loanType:'',
  loanPurpose:'',
  salePrice:'',
  fhaNumber:'',

  isRush:'Non-rush',

  ccNumberError:'',
  ccNumberValid:true,
  ccTypeError:'',
  ccTypeValid:true,

  newcc_first_name:'',
  newcc_last_name:'',
  newcc_email:'',
  newcc_card_number:'',
  newcc_cvc:'',
  newcc_expiration_year:'',
  newcc_expiration_month:'',
  newcc_street:'',
  newcc_city:'',
  newcc_state:'',
  newcc_zip:'',
  newcc_card_type:'',
  coveredBy:'',

  errorMessage:'',
  submitDisabled: false,

  sendLink:false,
  paymentLinkEmailAddress:'',
  paymentLinkEmailName:'',

  borrowerFirstName:'Quote',
  borrowerLastName:'Quote',
  borrowerEmail:'',
  borrowerPhone:'',
  borrowerWorkPhone:'',
  borrowerMobilePhone:'',
  pdrSellerID:'',
  pdrLPAKey:'',
};

//reducer function that perform state update
const reducer = getReducer();


const NewQuoteAppraisal  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  let role = localStorage.getItem('role');
  let userID = props.userID;
  let client = 0;
  let clientBranch = 0;
  let email = localStorage.getItem('email');

  if(role==='client'||role==='client_branch_master'||role==='client_master'){
    client = localStorage.getItem('clientID');
    clientBranch = localStorage.getItem('clientBranchID');
  }

  let newInitialState = Object.assign({}, initialState, {
    userID:userID,
    email:email,
    role:role,
    openBy:client?client:0,
    openByBranch:clientBranch?clientBranch:0,
    client:client?client:0,
    clientBranch:clientBranch?clientBranch:0,
    defaultCloseBy:client?client:0,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.role==='delegator')
      getDelegatorAssociate();
    getPropertyTypes();
    getLoanTypes();
    getAppraisalTypes();
    if(role==='client'||role==='client_branch_master'||role==='client_master'){
      getClientBranches(client);
      getOpenByClientBranches(client);
    }
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.submitDisabled){
        createNewAppraisal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.submitDisabled]);

  //non API call but simpyl manage state

  const standAlone=(appraisalType)=>{
    let appraisalTypes = state.standAlone.slice();

    for(let i=0;i<appraisalTypes.length;i++){
      if(appraisalTypes[i].includes(appraisalType))
        return true;
    }

    return false;
  }

  const googlePlaceOnChange=(address)=>{
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        setState({lat:null});
        setState({lng:null});

        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let county = '';
              let state = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                  county = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  state = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              //get long lat
              getLatLng(targetResult)
                .then(
                  result=>{
                    if(result){
                      let lat = result.lat;
                      let lng = result.lng;


                      setState({lat:lat});
                      setState({lng:lng});
                    }
                  }
                )
                .catch(
                  error=>{
                    console.log(error);
                  }
                );

              if(street!==''){
                setState({propertyStreet:street});
                setState({address:street});
              }
              if(city!=='')
                setState({propertyCity:city});
              if(county!=='')
                setState({propertyCounty:county});
              if(state!=='')
                setState({propertyState:state});
              if(zip!=='')
                setState({propertyZip:zip});
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address});
        setState({propertyStreet:address});
      }
    }
  }

  const updateLoanType=(loanType)=>{
    let filteredAppraisalTypes = [];

    if(loanType==='FHA'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('FHA'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='USDA'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('USDA'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo <$1m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('<$1m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo $1m - $2m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo >$2m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.property_type)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('>$2m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Conventional'||loanType==='High Balanced'||loanType==='Private'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(!state.availableAppraisalTypes[i].appraisal_type.includes('FHA')&&!state.availableAppraisalTypes[i].appraisal_type.includes('USDA')&&!state.availableAppraisalTypes[i].appraisal_type.includes('<$1m')&&!state.availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')&&!state.availableAppraisalTypes[i].appraisal_type.includes('>$2m'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Other'){
      let excludes = [
        '1004',
        '1073',
        '1025',
        'FHA',
        'USDA',
        'Jumbo'
      ];

      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;

        let exclude = false;
        for(let j=0;j<excludes.length;j++){
          if(state.availableAppraisalTypes[i].appraisal_type.includes(excludes[j])){
            //futher check if it's 1004D
            if(state.availableAppraisalTypes[i].appraisal_type.includes('1004D')){
              if(state.availableAppraisalTypes[i].appraisal_type.includes('FHA')||state.availableAppraisalTypes[i].appraisal_type.includes('USDA')){
                exclude = true;
                break;
              }
            }
            else{
              exclude = true;
              break;
            }
          }
        }

        if(exclude===false)
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else{
      filteredAppraisalTypes = state.availableAppraisalTypes.slice();
    }

    if(loanType!=='')
      setState({availableAppraisalTypesFiltered:filteredAppraisalTypes});
    else
      updatePropertyType(state.propertyType);
  }

  const propertyTypeCheck=(appraisalType,propertyType)=>{
    let excludeTypes = state.appraisalTypeFilters[propertyType];

    for(let j=0;excludeTypes&&j<excludeTypes.length;j++){

      if(appraisalType.includes(excludeTypes[j])){
        if(appraisalType.includes('1004D'))
          return true;
        else
          return false;
      }
    }
    return true;
  }

  const updatePropertyType=(propertyType)=>{
    let filteredAppraisalTypes = [];

    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type,propertyType)===true)
        filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
    }

    setState({availableAppraisalTypesFiltered:filteredAppraisalTypes});
  }

  const handleNewAppraisalTypeChange=(appraisalType)=>{
    setState({appraisalType:appraisalType});
    setState({subForms:[]});
    setState({subForm:false});

    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(state.availableAppraisalTypes[i].appraisal_type===appraisalType&&state.availableAppraisalTypes[i].combo==='yes'){
        setState({subForm:true});
        break;
      }
    }
  }

  const buttonHandler=(e)=>{
    e.preventDefault();
    if(state.submitDisabled)
      return;
    setState({submitDisabled:true});
  }

  //API call
  const getDelegatorAssociate=()=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let associatedClient = [];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.client_fk;
          tmp.value = client.company;

          associatedClient.push(tmp);
        }
        setState({associatedClient:associatedClient});
      }
    };
    httpGet('client/delegator/associate/'+state.userID,'','Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const createNewAppraisal=()=>{
    let callBack = (response)=>{
      setState({submitDisabled:false});

      let code = response.data.code;
      if(code!=='00'){
        setState({submitDisabled:false});
      }
      else{
        history('/appraisal');
      }
    };
    
    let clientEntities=[];


    let parameters =[
      {
        field:'specialInstructions',
        value:state.specialInstructions
      },
      {
        field:'isQuote',
        value:'yes'
      },
      {
        field:'sendLink',
        value:state.sendLink
      },
      {
        field:'paymentLinkEmailAddress',
        value:state.paymentLinkEmailAddress
      },
      {
        field:'paymentLinkEmailName',
        value:state.paymentLinkEmailName
      },
      {
        field:'orderType',
        value:state.orderType
      },
      {
        field:'specialInstructionsForAMC',
        value:state.specialInstructionsForAMC
      },
      {
        field:'propertyAccessName',
        value:state.propertyAccessName
      },
      {
        field:'propertyAccessTitle',
        value:state.propertyAccessTitle
      },
      {
        field:'propertyAccessEmail',
        value:state.propertyAccessEmail
      },
      {
        field:'coveredBy',
        value:state.coveredBy
      },
      {
        field:'propertyType',
        value:state.propertyType
      },
      {
        field:'appraisalType',
        value:state.appraisalType
      },
      {
        field:'entities',
        value:clientEntities
      },
      {
        field:'propertyAccessWorkPhone',
        value:state.propertyAccessWorkPhone
      },
      {
        field:'propertyAccessHomePhone',
        value:state.propertyAccessHomePhone
      },
      {
        field:'propertyAccessMobilePhone',
        value:state.propertyAccessMobilePhone
      },
      {
        field:'salePrice',
        value:state.salePrice
      },
      {
        field:'propertyStreet',
        value:state.propertyStreet
      },
      {
        field:'propertyCity',
        value:state.propertyCity
      },
      {
        field:'propertyCounty',
        value:state.propertyCounty
      },
      {
        field:'propertyState',
        value:state.propertyState
      },
      {
        field:'propertyZip',
        value:state.propertyZip
      },
      {
        field:'pdrSellerID',
        value:state.pdrSellerID
      },
      {
        field:'pdrLPAKey',
        value:state.pdrLPAKey
      },
      {
        field:'loanNumber',
        value:state.loanNumber
      },
      {
        field:'loanAmount',
        value:state.loanAmount
      },
      {
        field:'fhaNumber',
        value:state.fhaNumber
      },
      {
        field:'loanPurpose',
        value:state.loanPurpose
      },
      {
        field:'loanType',
        value:state.loanType
      },
      {
        field:'client',
        value:state.client
      },
      {
        field:'clientBranchFk',
        value:state.clientBranch
      },
      {
        field:'openBy',
        value:state.openBy
      },
      {
        field:'openByClientBranch',
        value:state.openByBranch
      },
      {
        field:'isRush',
        value:state.isRush
      },
      {
        field:'borrowerFirstName',
        value:state.borrowerFirstName
      },
      {
        field:'borrowerLastName',
        value:state.borrowerLastName
      },
      {
        field:'borrowerEmail',
        value:state.borrowerEmail
      },
      {
        field:'billing_first_name',
        value:state.newcc_first_name
      },
      {
        field:'billing_last_name',
        value:state.newcc_last_name
      },
      {
        field:'email',
        value:state.newcc_email
      },
      {
        field:'borrowerPhone',
        value:state.borrowerPhone
      },
      {
        field:'borrowerWorkPhone',
        value:state.borrowerWorkPhone
      },
      {
        field:'borrowerMobilePhone',
        value:state.borrowerMobilePhone
      },
      {
        field:'lat',
        value:state.lat
      },
      {
        field:'lng',
        value:state.lng
      },
      {
        field:'card_number',
        value:state.newcc_card_number
      },
      {
        field:'card_type',
        value:state.newcc_card_type
      },
      {
        field:'cvc',
        value:state.newcc_cvc
      },
      {
        field:'expiration_month',
        value:state.newcc_expiration_month
      },
      {
        field:'expiration_year',
        value:state.newcc_expiration_year
      },
      {
        field:'billing_street',
        value:state.newcc_street
      },
      {
        field:'billing_city',
        value:state.newcc_city
      },
      {
        field:'billing_state',
        value:state.newcc_state
      },
      {
        field:'billing_zip',
        value:state.newcc_zip
      }
    ];

    if(state.expectedDate!==''){
      let tmp = {
        field:'expectedDate',
        value:state.expectedDate
      }

      parameters.push(tmp);
    }

    httpPost('appraisal/create', parameters, 'Turnaround time requested successfully.', 'Oops, something went wrong and could not request a turnaround time. Please try again later.', callBack);
  }

  const getPropertyTypes=()=>{
    const callBack = apiCallBack([{state:'propertyTypes', key:'data'}]);
    httpGet('propertyType/get','','Oops, something went wrong and could not load property types. Please try again later.', callBack);
  }

  const getLoanTypes=()=>{
    const callBack = apiCallBack([{state:'loanTypes', key:'data'}]);
    httpGet('loanType/get','','Oops, something went wrong and could not load loan types. Please try again later.', callBack);
  }

  const getAppraisalTypes=()=>{
    const callBack = apiCallBack([{state:'availableAppraisalTypes', key:'data'},{state:'availableAppraisalTypesFiltered', key:'data'}]);
    httpGet('appraisalType/get/externalLabel','','Oops, something went wrong and could not load appraisal types. Please try again later.', callBack);
  }

  const getClientBranches=(client)=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        setState({clientBranches:response.data.data});
        if(response.data.data.length>0)
          setState({clientBranch:response.data.data[0].ID});
        if(state.role==='delegator' && state.orderType==='Retail'){
          setState({openByBranch:response.data.data[0].ID, openByClientBranches:response.data.data});
        }
      }
    };
    httpGet('client/branch/get/'+client,'','Oops, something went wrong and could not retrieve client branch list. Please try again later.', callBack);
  }

  const getOpenByClientBranches=(client)=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        setState({openByClientBranches:response.data.data});
        if(response.data.data.length>0)
          setState({openByBranch:response.data.data[0].ID});
      }
    };
    httpGet('client/branch/get/'+client,'','Oops, something went wrong and could not retrieve client branch list. Please try again later.', callBack);
  }

  //render
  let expectedDate;
  let closeBy, closeByBranch, openBy, openByBranch;

  if(state.role==='delegator'){
    closeBy =
      <Col sm="12">
        <label>Close by</label>
        <Input type="select" required={true} value={state.client} onChange={(e)=>{
          setState({client:e.target.value});
          getClientBranches(e.target.value);
          if(state.orderType==='Retail')
            setState({openBy:e.target.value});
        }}>
          <option value={state.self.key}>{state.self.value}</option>
          {
            state.associatedClient.map(
              (client, index)=>{
                return <option key={index} value={client.key}>{client.value}</option>
              }
            )
          }
        </Input>
      </Col>;

    closeByBranch = <Col sm="12">
        <label>Close by Branch</label>
        <Input type="select" value={state.clientBranch} onChange={(e)=>{
          setState({clientBranch:e.target.value});
          if(state.orderType==='Retail')
            setState({openByBranch:e.target.value});
        }}>
          {
            state.clientBranches.map(
              (branch, index)=>{
                return <option key={index} value={branch.ID}>{branch.branch_name}</option>
              }
            )
          }
        </Input>
      </Col>

    openByBranch = <Col sm="12">
        <label>Open by Branch</label>
        <Input disabled={state.orderType==='Retail'} required={true} type="select" value={state.openByBranch} onChange={(e)=>{setState({openByBranch:e.target.value})}}>
          {
            state.openByClientBranches.map(
              (branch, index)=>{
                return <option key={index} value={branch.ID}>{branch.branch_name}</option>
              }
            )
          }
        </Input>
      </Col>

    openBy = <Col sm="12">
        <label>Open by</label>
        <Input disabled={state.orderType==='Retail'} required={true} type="select" value={state.openBy} onChange={(e)=>{setState({openBy:e.target.value});getOpenByClientBranches(e.target.value)}}>
          <option value={state.self.key}>{state.self.value}</option>
          {
            state.associatedClient.map(
              (client, index)=>{
                return <option key={index} value={client.key}>{client.value}</option>
              }
            )
          }
        </Input>
      </Col>;
  }

  let date;
  if(state.expectedDate!=='')
    date = moment(state.expectedDate).toDate();

  expectedDate =
    <Col sm="2">
      <label>Expected date (optional)</label>
      <DatePicker
        className="form-control"
        selected={date}
        onChange={(text)=>{(text)&&setState({expectedDate:text.toLocaleDateString('en-CA')})}}
      />
    </Col>;

  let states;
  let availableAppraisalTypes;
  let propertyTypes;
  let loanTypes;

  if(state.availableAppraisalTypesFiltered.length>0)
    availableAppraisalTypes = state.availableAppraisalTypesFiltered.map(
      (appraisalType, index)=>{
        if(state.appraisaltype===appraisalType)
          return <option selected value={appraisalType.appraisal_type} key={index}>{appraisalType.external_label}</option>;
        else
          return <option value={appraisalType.appraisal_type} key={index}>{appraisalType.external_label}</option>;
      }
    );

  let subFormsToAdd = [];
  if(state.subForm){
    // get all sub form
    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(state.availableAppraisalTypes[i].sub_form==='yes')
        subFormsToAdd.push(state.availableAppraisalTypes[i]);
    }
  }


  if(state.loanTypes.length>0)
    loanTypes = state.loanTypes.map(
      (loanType, index)=>{
        return <option value={loanType.loan_type} key={index}>{loanType.loan_type}</option>;
      }
    );

  if(state.propertyTypes.length>0)
    propertyTypes = state.propertyTypes.map(
      (propertyType, index)=>{
        return <option value={propertyType.property_type} key={index}>{propertyType.property_type}</option>;
      }
    );

  if(state.states.length>0)
    states = state.states.map(
      (state, index)=>{
        return <option value={state.key} key={index}>{state.value}</option>;
      }
    );





  return <div className="my-well">
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-question"/> Request Turnaround Time
      </CardHeader>
      <CardBody>
        <font color="red"><i>*Request a turnaround time & fee to get started ahead.</i></font><br/>
        <font color="red"><i>*You are not submitting an actual order but only a quote.</i></font><br/>

        <form onSubmit={buttonHandler}>
          <Row>
            <Col sm="7">
              <label><font color="red">*</font>Street</label>
              <MyPlacesAutocomplete value={state.address} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
            </Col>
            <Col sm="5">
              <label><font color="red">*</font>City</label>
              <Input required="true" type="text" value={state.propertyCity} onChange={(e)=>setState({propertyCity:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>State</label>
              <Input required="true" type="select" value={state.propertyState} onChange={(e)=>setState({propertyState:e.target.value})}>
                <option key="" value=""></option>
                {states}
              </Input>
            </Col>
            <Col sm="3">
              <label>County</label>
              <Input type="text" disabled value={state.propertyCounty} onChange={(e)=>setState({propertyCounty:e.target.value})}/>
            </Col>
            <Col sm="2">
              <label><font color="red">*</font>Zip</label>
              <Input required="true" type="text" value={state.propertyZip} onChange={(e)=>setState({propertyZip:e.target.value})}/>
            </Col>
          </Row>

          <div className="my-divider"/><br/>
          <Row>
            <Col sm="3">
              <label><font color="red">*</font>Property type</label>
              <Input required="true" type="select" value={state.propertyType} onChange={(e)=>{setState({propertyType:e.target.value});updatePropertyType(e.target.value);}}>
                <option value="" key=""></option>
                {propertyTypes}
              </Input>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Loan type</label>
              <Input required="true" type="select" value={state.loanType} onChange={(e)=>{setState({loanType:e.target.value});updateLoanType(e.target.value)}}>
                <option value="" key=""></option>
                {loanTypes}
              </Input>
            </Col>
            <Col sm="4">
              <label><font color="red">*</font>Appraisal type</label>
              <Input type="select" required="true" value={state.appraisalType} onChange={(e)=>handleNewAppraisalTypeChange(e.target.value)}>
                <option value=""></option>
                {availableAppraisalTypes}
              </Input>
            </Col>
            {expectedDate}
          </Row>
          <br/>
          <Row>
            {closeBy}
            {closeByBranch}
            {openBy}
            {openByBranch}
          </Row>
          <br/>
          <div className="align-right">
            <Button color="warning" disabled={state.submitDisabled!==false}>Submit</Button>
          </div>
        </form>
      </CardBody>
    </Card>
  </div>
}


export default NewQuoteAppraisal;
