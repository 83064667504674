//Author June Leow
//Date Jul 16th, 2024
import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import { callBackGenerator, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
import PipelineReport from './pipeline-report';
import ReceivableReport from './receivable-report';
import TurntimeReport from './turntime-report';

//initialize the state
const initialState = {
  selectedReport:-1,
  reports:[],
};

//reducer function that perform state update
const reducer = getReducer();


const Reports  = (props)=>{
  const controller = new AbortController();

  let params = useParams().params;
  let newInitialState = Object.assign({}, initialState, {
    params:params
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReports();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReports=()=>{
    const callBack = apiCallBack([{state:'reports', key:'data'}]);
    httpGet('report/client/get','','Oops, something went wrong and could not load a list of available reports. Please try again later.', callBack);
  }

  //render
  let reports, report;

  reports = state.reports.map(
    (report, index)=>{
      return <option key={index} value={report.ID}>{report.report}</option>
    }
  )
  console.log(state.selectedReport)
  switch(state.selectedReport){
    case '1':
    case '3':
      report = <TurntimeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>;
      break;
    case '2':
      report = <PipelineReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>;
      break;
    case '4':
      report = <ReceivableReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>;
      break;
    default:
      report = '';
      break;
  }


  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Report
          </div>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <Row>
        <Col sm="8">
          <label>Report</label>&nbsp;
          <Input type="select" value={state.selectedReport} onChange={(e)=>setState({selectedReport:e.target.value})}>
            <option value=""></option>
            {reports}
          </Input>
        </Col>
      </Row>
      <br/>
      <div className="my-divider"></div>

      {report}
    </div>
  );
}


export default Reports;
