//Author June Leow
//Date Jul 16th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { callBackGenerator, formatDateTime, formatNumber, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, showMessage } from '../../util/util';
import MyDropzone from '../util/my-dropzone';

let email = localStorage.getItem('email');
//initialize the state
const initialState = {
  appraisal:{
    reference_num:'',
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
    loan_num:'',
    loan_purpose:'',
    loan_type:'',
    datetime_submitted:'0000-00-00',
    status:'',
    borrower_f_name:'',
    borrower_l_name:'',
  },

  toUploadFiles:[],
  errorMessage:'',
  uploadDisabled:false,
  fileTypes:[],

  resizeFilePopUp:false,
  bigFiles:[],

  email:email,
  name:''
};

//reducer function that perform state update
const reducer = getReducer();


const FileUpload  = (props)=>{
  const controller = new AbortController();

  let name = '';

  if(props.isLoggedIn){
    name = props.userFirstName+' '+props.userLastName;
  }
  let appraisalFk = useParams().appraisalFk;
  let newInitialState = Object.assign({}, initialState, {
    appraisalFk:appraisalFk,
    name:name
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    getFileTypes();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.uploadDisabled){
        uploadAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.uploadDisabled]);

  //non API call but simpyl manage state
  const buttonHandler=()=>{
    if(state.uploadDisabled)
      return;
    setState({uploadDisabled:true});
  }

  const toggleResizeFile=()=>{
    if(state.resizeFilePopUp)
      setState({bigFiles:[]});
    setState({resizeFilePopUp: !state.resizeFilePopUp});
  }

  const onDrop = (acceptedFiles) => {
    console.log(state);
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles.slice();

    let newState = {};
    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          newState = Object.assign({}, newState,{errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'});
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      newState = Object.assign({}, newState,{resizeFilePopUp:true, bigFiles:bigFiles});
    }
    newState = Object.assign({}, newState,{toUploadFiles:existingFiles});
    setState(newState);
  };

  const removeToUploadFile = (name) => {
    const newFiles = state.toUploadFiles.filter(file => file.name !== name);
    setState({ toUploadFiles: newFiles });
  };

  const formatFileSize = (size) => {
    const intSize = parseInt(size, 10);
    if (intSize >= 1000000) {
        return formatNumber(Math.round((intSize * 10) / 1000000) / 10) + ' MB';
    } else if (intSize >= 1000) {
        return formatNumber(Math.round((intSize * 10) / 1000) / 10) + ' KB';
    } else {
        return formatNumber(intSize) + ' B';
    }
  };

  //constructing a new file object
  const deepCopyFileObject = (file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;
    newFile.description = file.description;

    return newFile
  }

  //remove file from the list after successfull http delete call

  const onFileTypeChange = (name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  const onFileDescriptionChange = (name, description)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];
        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);
          if(j===i)
            newFile.description = description;

          newToUploadFiles.push(newFile);
        }
        console.log(newToUploadFiles)
        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //API call
  const getFileTypes=()=>{
    const callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
    httpGet('file/fileType/public/get','','Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }

  const getAppraisal=()=>{
    const callBack = apiCallBack([{state:'appraisal', key:'data'}]);
    httpGet('appraisal/public/'+state.appraisalFk,'','Oops, something went wrong and could not load the appraisal information. Please try again later.', callBack);
  }

  const wait = (ms)=>{
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const uploadAll=async()=>{
    let preCheck = true;
    let errorMessage = '';

    if(state.name===''){
      preCheck = false;
      errorMessage = 'Please provide your name.';
    }

    let fileTypes = [];
    let descriptions = [];

    for(let i =0; i<state.toUploadFiles.length;i++){
      let value = state.toUploadFiles[i].fileType;
      let description = state.toUploadFiles[i].description;

      if(!value||value===''){
        if(state.toUploadFiles[i].status==='Done')
          continue;

        preCheck = false;
        fileTypes.push('');
        errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
      }
      else
        fileTypes.push(value);
      descriptions.push(description);
    }

    if(state.email===''){
      preCheck = false;
      errorMessage = 'Please provide your email.';
    }

    if(state.name===''){
      preCheck = false;
      errorMessage = 'Please provide your name.';
    }

    //only proceed when no error
    if(preCheck){
      setState({errorMessage:'Uploading...Please do not close the window.'});
      let email = state.email;
      let userID = 0;
      let name = state.name;

      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done'){
          fileCompleted++;
          continue;
        }

        const readFile = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject('File reading failed');
            reader.readAsBinaryString(file);
          });
        };

        try{
          // Wait for the file to be read
          const fileAsBinaryString = await readFile(state.toUploadFiles[i]);
          let base64 = btoa(fileAsBinaryString);
          let targetRecipients =[];

          let callBack = (response)=>{
            let code = response.data.code;

            if(code==='00'){
              //copy the array so we maintaining the immutable state
              let newToUploadFiles = [];

              //deep copy the entire file object array
              for(let j=0;j<state.toUploadFiles.length;j++){
                let newFile;

                try{
                  newFile = deepCopyFileObject(state.toUploadFiles[j]);

                  if(newFile.name===state.toUploadFiles[i].name)
                    newFile.status='Done';


                  newToUploadFiles.push(newFile);
                }
                catch(err){
                  //IE Error , not supporting file consturctor
                }
              }

              setState({toUploadFiles:newToUploadFiles});
            }
            fileCompleted++;

            if(fileCompleted>=state.toUploadFiles.length){
              setState({uploadDisabled:false});
              setState({errorMessage:''});
            }
          };

          let errorCallBack = ()=>{
            fileCompleted++;

            if(fileCompleted>=state.toUploadFiles.length){
              setState({uploadDisabled:false});
              setState({errorMessage:''});
            }
          };

          let parameters = [
            {
              field:'appraisal_fk',
              value:state.appraisalFk
            },
            {
              field:'file_type',
              value:fileTypes[i]
            },
            {
              field:'description',
              value:descriptions[i]
            },
            {
              field:'upload_type',
              value:'Orders'
            },
            {
              field:'upload_userfk',
              value:userID
            },
            {
              field:'upload_useremail',
              value:email
            },
            {
              field:'upload_username',
              value:name
            },
            {
              field:'name',
              value:state.toUploadFiles[i].name
            }
            ,{
              field:'file_encoded',
              value:base64
            },
            {
              field:'to_send_entities',
              value:targetRecipients
            }
          ];
          console.log(parameters)
          await httpPost('file/upload', parameters, 'File "'+state.toUploadFiles[i].name+'" uploaded successfully.', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack, errorCallBack);
          await wait(500);
        }
        catch(error){
          showMessage('error','File upload failed, please try again later.');
        }
      }
    }
    else{
      setState({errorMessage:errorMessage});
      setTimeout(()=>setState({uploadDisabled:false}),1000);
    }
  }

  //render
  let filesTypesOpt;

  if(state.fileTypes.length>0){
    filesTypesOpt = state.fileTypes.map(
      (fileType, index)=>{
        return(
          <option key={index} value={fileType.name}>{fileType.name}</option>
        );
      }
    );
  }

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <Input type="text" value={file.description} onChange={(e)=>onFileDescriptionChange(file.name, e.target.value)}/>
            </td>
            <td>
              <select value={file.fileType} className="form-control" onChange={(e)=>{onFileTypeChange(file.name,e.target.value)}}>
                <option value=""></option>
                {filesTypesOpt}
              </select>
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let bigFileList = state.bigFiles.join(', ');

  let topBar;

  if(!props.isLoggedIn){
    topBar = <div className="topbar">
      <div className="topbar-logo-container">
        <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
      </div>
    </div>;
  }

  return(
    <div>
      <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
        <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
          </center>

          <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
          <div>{bigFileList}</div>

          <br/><br/>
          <center>
            <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
          </center>
        </ModalBody>
      </Modal>
      {topBar}
      <div className="padding">
        <Card style={{background:'#f5f5f5'}}>
          <CardHeader className="header-color">
            <center>
                <i className="fa fa-folder"></i> Upload file
            </center>
          </CardHeader>
          <CardBody>
            <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
              <label>Reference #:&nbsp;&nbsp;</label>
              {state.appraisal.reference_num}

              <br/>
              <label>Status:&nbsp;&nbsp;</label>
              {state.appraisal.status}

              <br/>
              <label>Property address:&nbsp;&nbsp;</label>
              {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}

              <br/>
              <label>Borrower:&nbsp;&nbsp;</label>
              {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}

              <br/>
              <label>Loan purpose:&nbsp;&nbsp;</label>
              {state.appraisal.loan_purpose}

              <br/>
              <label>Loan type:&nbsp;&nbsp;</label>
              {state.appraisal.loan_type}

              <br/>
              <label>Loan #:&nbsp;&nbsp;</label>
              {state.appraisal.loan_num}

              <br/>
              <label>Date submitted:&nbsp;&nbsp;</label>
              {formatDateTime(state.appraisal.datetime_submitted)}

              <br/>
            </div>
            <br/>
            <div className="my-well">
              <MyDropzone onDrop={onDrop}/>
              <br/>
              <div className="small-scroll-container red-color flashit" style={{minHeight:'30px',height:'30px ',maxHeight:'30px'}}>
                  <b>{state.errorMessage}</b>
                </div>
              <div>
                <table className="table file-list-table" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th width="25%">Name</th>
                      <th width="10%">Description</th>
                      <th width="30%">File Type</th>
                      <th width="15%">Size</th>
                      <th width="10%"><center>Status</center></th>
                      <th width="10%">Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {toUploadFiles}
                  </tbody>
                </table>
              </div>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Name</label>
                  <Input type="text" value={state.name} disabled={props.isLoggedIn} onChange={(e)=>setState({name:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label>Email</label>
                  <Input type="text" value={state.email} disabled={props.isLoggedIn} onChange={(e)=>setState({email:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <div className="align-right">
                    <br/>
                    <Button color="warning" disabled={state.uploadDisabled} onClick={buttonHandler}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default FileUpload;
