//Author June Leow
//Date June 10th, 2024
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Col, Collapse, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, Progress, Row } from 'reactstrap';
import { callBackGenerator, formatDateTime, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, putAPICallGenerator, showMessage } from '../../util/util';
import Comment from '../comment/comment';
import AppraisalFile from '../file/file';
import BottomBar from '../structure/bottom-bar/bottom-bar';
import './appraisal.css';
//initialize the state
const initialState = {
  appraisal: {},
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0,
    has_credit_card_receivable:'no'
  },
  dropdownOpen: false,
  modificationDropDownOpen:false,
  editBorrowerFirstName:'',
  editBorrowerLastName:'',
  editBorrowerHomePhone:'',
  editBorrowerWorkPhone:'',
  editBorrowerMobilePhone:'',
  editBorrowerEmail:'',
  editAppraisalType:'',
  editAppraisalTypeLabel:'',
  editLoanNumber:'',
  editExpectedDate:'',
  editPropertyType:'',
  editLoanType:'',
  editLoanPurpose:'',
  editPropertyStreet:'',
  editPropertyCity:'',
  editPropertyState:'',
  editPropertyZip:'',
  editPropertyAccessTitle:'',
  editPropertyAccessName:'',
  editPropertyAccessEmail:'',
  editPropertyAccessWorkPhone:'',
  editPropertyAccessHomePhone:'',
  editPropertyAccessMobilePhone:'',

  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Private',value:'Private'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'Other',value:'Other'}],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  propertyTypes:[{key:'2 Units',value:'2 Units'},{key:'3 Units',value:'3 Units'},{key:'4 Units',value:'4 Units'},{key:'Co-Operative (Co-Op)',value:'Co-Operative (Co-Op)'},{key:'Low Rise Condo',value:'Low Rise Condo'},{key:'Mid-Rise Condo',value:'Mid-Rise Condo'},{key:'High Rise Condo',value:'High Rise Condo'},{key:'Detached Condo',value:'Detached Condo'},{key:'Manufactured Housing',value:'Manufactured Housing'},{key:'SFR - Detached',value:'SFR - Detached'},{key:'SFR - Attached',value:'SFR - Attached'},{key:'PUD - Detached',value:'PUD - Detached'},{key:'PUD - Attached',value:'PUD - Attached'},{key:'Other',value:'Other'}],

  appraisalTypes:[],
};

//reducer function that perform state update
const reducer = getReducer();


const Appraisal  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal(id);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //toggle function for the instruction floating note
  const floatingMemoToggle=()=>{
    setState({
      dropdownOpen: !state.dropdownOpen
    });
  }

  const orderModificationToggle=()=>{
    setState({
      modificationDropDownOpen: !state.modificationDropDownOpen
    });
  }

  //API call
  //get appraisal
  const getAppraisal=(id)=>{
    //get appraisal call back
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        if(response.data.data)
          response.data.data.special_instructions = response.data.data.special_instructions.replace(/(?:\\[rn]|[\r\n]+)+/g, "\r\n");

        setState({
          appraisal:response.data.data,
          editBorrowerEmail:response.data.data.borrower_email,
          editBorrowerMobilePhone:response.data.data.borrower_mobile_phone,
          editBorrowerWorkPhone:response.data.data.borrower_work_phone,
          editBorrowerHomePhone:response.data.data.borrower_phone,
          editBorrowerLastName:response.data.data.borrower_l_name,
          editBorrowerFirstName:response.data.data.borrower_f_name,
          editAppraisalType:response.data.data.actual_appraisal_type,
          editLoanNumber:response.data.data.loan_num,
          editExpectedDate:response.data.data.datetime_expected,
          editPropertyType:response.data.data.property_type,
          editLoanType:response.data.data.loan_type,
          editLoanPurpose:response.data.data.loan_purpose,
          editPropertyStreet:response.data.data.property_street,
          editPropertyCity:response.data.data.property_city,
          editPropertyState:response.data.data.property_state,
          editPropertyZip:response.data.data.property_zip,
          editPropertyAccessTitle:response.data.data.property_access_title,
          editPropertyAccessName:response.data.data.property_access_name,
          editPropertyAccessEmail:response.data.data.property_access_email,
          editPropertyAccessWorkPhone:response.data.data.property_access_work_phone,
          editPropertyAccessHomePhone:response.data.data.property_access_home_phone,
          editPropertyAccessMobilePhone:response.data.data.property_access_mobile_phone,
          editPropertyOccupancy:response.data.data.property_occupancy,
          editPropertyAPN:response.data.data.apn
        });
        if(response.data.data.special_instructions!=='')
          setState({dropdownOpen:true})
        setState({old_special_instructions:response.data.data.special_instructions});

        getOverallStatus(state.id);
        getAppraisalTypes();
      }
    };
    
    httpGet('appraisal/'+id, '', 'Oops, something went wrong and could not load this appraisal. Please try again later.', callBack)
  }

  //get overall status
  const getOverallStatus=()=>{
    const callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({overallStatus:response.data.data});
        if(state.appraisal.datetime_appraisal_uploaded!=='0000-00-00 00:00:00'&&response.data.data.has_credit_card_receivable==='yes')
          showMessage('warning', 'Credit card information for this loan appraisal has not yet been completed. To submit a credit card, click on the payment tab.');
      }
    }
    httpGet('appraisal/overall/status/'+state.id, '', 'Oops, something went wrong and could not load the information of this appraisal. Please try again later.', callBack)
  }

  const getAppraisalTypes=()=>{
    const callBack = apiCallBack([{state:'appraisalTypes', key:'data'}]);
    httpGet('appraisalType/get', '', 'Oops, something went wrong and could not load list of available report types. Please try again later.', callBack)
  }

  
  const modifyOrder=()=>{
    let fields = [];
    let mapping = {
      editBorrowerFirstName:'borrower_f_name',
      editBorrowerLastName:'borrower_l_name',
      editBorrowerHomePhone:'borrower_phone',
      editBorrowerWorkPhone:'borrower_work_phone',
      editBorrowerMobilePhone:'borrower_mobile_phone',
      editBorrowerEmail:'borrower_email',
      editAppraisalType:'actual_appraisal_type',
      editLoanNumber:'loan_num',
      editExpectedDate:'datetime_expected',
      editLoanType:'loan_type',
      editLoanPurpose:'loan_purpose',
      editPropertyType:'property_type',
      editPropertyStreet:'property_street',
      editPropertyCity:'property_city',
      editPropertyState:'property_state',
      editPropertyZip:'property_zip',
      editPropertyOccupancy:'property_occupancy',
      editPropertyAPN:'apn',
      editPropertyAccessTitle:'property_access_title',
      editPropertyAccessName:'property_access_name',
      editPropertyAccessEmail:'property_access_email',
      editPropertyAccessHomePhone:'property_access_home_phone',
      editPropertyAccessWorkPhone:'property_access_work_phone',
      editPropertyAccessMobilePhone:'property_access_mobile_phone',
    }

    Object.keys(mapping).forEach(key => {
      if(state[key]!==state.appraisal[mapping[key]]){
        let value = mapping[key];

        if(value==='actual_appraisal_type')
          value = 'appraisal_type';
        fields.push({
          field:value,
          value:state[key]
        });
      }
    });

    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'fields',
        value:fields
      }
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let appraisal = Object.assign({}, state.appraisal);
        Object.keys(mapping).forEach(key => {
          if(state[key]!==state.appraisal[mapping[key]]){
            let value = mapping[key];
            if(value==='actual_appraisal_type'){
              appraisal['appraisal_type'] = state.editAppraisalTypeLabel;
              appraisal['actual_appraisal_type'] = state.editAppraisalType;
            }
          }
        });

        setState({appraisal:appraisal});
      }
    };

    httpPut('appraisal/modification', parameters, 'Order information updated.', 'Oops, something went wrong and could update the order. Please try again later.', callBack);
  }

  const cloneOrder=()=>{
    let parameters = [
      {
        field:'ID',
        value:state.id
      }
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({moreActionMessage:response.data.message});
      }
    };
    httpPost('appraisal/clone', parameters, 'Clone order initiated.', 'Oops, something went wrong and could redo the order. Please try again later.', callBack);
  }





  //render
  //render the page with loading icon if the appraisal is not loaded

  let orderModificationButton;
  console.log(state.appraisal)
  if(state.appraisal.modification_allowed && state.appraisal.modification_allowed==='yes'){
    orderModificationButton = <Button color="warning" onClick={orderModificationToggle}>Order Modification</Button>;
  }
  let editExpectedDate;
  if(state.editExpectedDate&&state.editExpectedDate!=='0000-00-00 00:00:00'&&state.editExpectedDate!=='')
    editExpectedDate = moment(state.editExpectedDate).toDate();
  else
    editExpectedDate = null;

  let appraisalType;
  if(state.appraisal.appraisal_type){

    appraisalType = <div className="display-inline">{state.appraisal.appraisal_type}</div>;
  }

  let creditCardTab;

  if(state.overallStatus.has_credit_card_receivable==='yes'){
    creditCardTab = <NavItem>
      <NavLink className="nav-link" to={"/credit-card/"+state.id}>Payment</NavLink>
    </NavItem>
  }

  let loanType = 'Conventional';

  if(state.appraisal.loan_type==='FHA')
    loanType = 'FHA';

  if(state.appraisal.loan_type==='USDA')
    loanType = 'USDA';

  let progressBar;
  let actionBar;

  let expectedDate = '-';

  if(state.appraisal.datetime_expected&&state.appraisal.datetime_expected!=='0000-00-00 00:00:00')
    expectedDate = '-';


  //if(status==='Submitted'||status==='Assigned'||status==='Auto Assigning')
    //progressBar = <Progress animated value={100} className="custom-progress custom-progress-red"><b>{state.appraisal.status}</b></Progress>;
  //else if(status==='Pending Inspection'||status==='Inspection Completed'||status==='Review'||status==='Conditioned')
    //progressBar = <Progress animated value={100} className="custom-progress custom-progress-blue"><b>{state.appraisal.status}</b></Progress>;
  //else if(status==='Completed'||status==='Shipped')
    //progressBar = <Progress animated value={100} className="custom-progress custom-progress-green"><b>{state.appraisal.status}</b></Progress>;
  //else if(status==='Hold'||status==='Cancelled')
    //progressBar = <Progress animated value={100} className="custom-progress custom-progress-gray"><b>{state.appraisal.status}</b></Progress>;
  //else
  progressBar = <Progress animated value={100} className="custom-progress custom-progress-blue"><b>{state.appraisal.status}</b></Progress>;

  return (
    <div className="my-well">
      <Modal className="my-modal-wide" isOpen={state.modificationDropDownOpen} toggle={orderModificationToggle} >
        <ModalHeader hidden={true} toggle={orderModificationToggle}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-edit"></i> Order Modification</h5>
          </center>

          <Row>
            <Col sm="6">
              <label><i className="fa fa-user-circle"/> Borrower</label><br/>
              <Row>
                <Col sm="5" className={state.editBorrowerFirstName!==state.appraisal.borrower_f_name?"red-color flashit bold":""}>
                  First Name
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editBorrowerFirstName} onChange={(e)=>setState({editBorrowerFirstName:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editBorrowerLastName!==state.appraisal.borrower_l_name?"red-color flashit bold":""}>
                  Last Name
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editBorrowerLastName} onChange={(e)=>setState({editBorrowerLastName:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editBorrowerHomePhone!==state.appraisal.borrower_phone?"red-color flashit bold":""}>
                  Home Phone
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editBorrowerHomePhone} onChange={(e)=>setState({editBorrowerHomePhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editBorrowerWorkPhone!==state.appraisal.borrower_work_phone?"red-color flashit bold":""}>
                  Work Phone
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editBorrowerWorkPhone} onChange={(e)=>setState({editBorrowerWorkPhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editBorrowerMobilePhone!==state.appraisal.borrower_mobile_phone?"red-color flashit bold":""}>
                  Mobile Phone
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editBorrowerMobilePhone} onChange={(e)=>setState({editBorrowerMobilePhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editBorrowerEmail!==state.appraisal.borrower_email?"red-color flashit bold":""}>
                  Email
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editBorrowerEmail} onChange={(e)=>setState({editBorrowerEmail:e.target.value})}/>
                </Col>
              </Row>
              <div className="my-divider"/>
              <Row>
                <Col sm="5" className={state.editAppraisalType!==state.appraisal.actual_appraisal_type?"red-color flashit bold":""}>
                  <label><i className="fa fa-cogs"/> Report Type(s)</label>
                </Col>
                <Col sm="7">
                  <Input type="select" value={state.editAppraisalType} onChange={(e)=>setState({editAppraisalType:e.target.value, editAppraisalTypeLabel:e.target.options[e.target.selectedIndex].text})}>
                    {
                      state.appraisalTypes.map(
                        (appraisalType, index)=>{
                          return <option key={index} value={appraisalType.appraisal_type}>{appraisalType.external_label}</option>
                        }
                      )
                    }
                  </Input>
                </Col>
              </Row>
              <div className="my-divider"/>
              <label><i className="fa fa-info-circle"/> Loan Facts</label>
              <Row>
                <Col sm="5" className={state.editLoanNumber!==state.appraisal.loan_num?"red-color flashit bold":""}>
                  Loan Number
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editLoanNumber} onChange={(e)=>setState({editLoanNumber:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editExpectedDate!==state.appraisal.datetime_expected?"red-color flashit bold":""}>
                  Expected Date
                </Col>
                <Col sm="7">
                  <DatePicker
                    className="form-control"
                    selected={editExpectedDate}
                    onChange={(text)=>{setState({editExpectedDate:text.toLocaleDateString('en-CA')})}}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyType!==state.appraisal.property_type?"red-color flashit bold":""}>
                  Property Type
                </Col>
                <Col sm="7">
                  <Input type="select" value={state.editPropertyType} onChange={(e)=>setState({editPropertyType:e.target.value})}>
                    {
                      state.propertyTypes.map(
                        (propertyType, index)=>{
                          return <option key={index} value={propertyType.key}>{propertyType.value}</option>
                        }
                      )
                    }
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editLoanType!==state.appraisal.loan_type?"red-color flashit bold":""}>
                  Loan Type
                </Col>
                <Col sm="7">
                  <Input type="select" value={state.editLoanType} onChange={(e)=>setState({editLoanType:e.target.value})}>
                    {
                      state.loanTypes.map(
                        (loanType, index)=>{
                          return <option key={index} value={loanType.key}>{loanType.value}</option>
                        }
                      )
                    }
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editLoanPurpose!==state.appraisal.loan_purpose?"red-color flashit bold":""}>
                  Loan Purpose
                </Col>
                <Col sm="7">
                  <Input type="select" value={state.editLoanPurpose} onChange={(e)=>setState({editLoanPurpose:e.target.value})}>
                    {
                      state.loanPurposes.map(
                        (loanPurpose, index)=>{
                          return <option key={index} value={loanPurpose.key}>{loanPurpose.value}</option>
                        }
                      )
                    }
                  </Input>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <label>Property Information</label>
              <Row>
                <Col sm="5" className={state.editPropertyStreet!==state.appraisal.property_street?"red-color flashit bold":""}>
                  Street
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyStreet} onChange={(e)=>setState({editPropertyStreet:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyCity!==state.appraisal.property_city?"red-color flashit bold":""}>
                  City
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyCity} onChange={(e)=>setState({editPropertyCity:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyState!==state.appraisal.property_state?"red-color flashit bold":""}>
                  State
                </Col>
                <Col sm="7">
                  <Input type="select" value={state.editPropertyState} onChange={(e)=>setState({editPropertyState:e.target.value})}>
                    {
                      state.states.map(
                        (state, index)=>{
                          return <option key={index} value={state.key}>{state.value}</option>
                        }
                      )
                    }
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyZip!==state.appraisal.property_zip?"red-color flashit bold":""}>
                  Zip
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyZip} onChange={(e)=>setState({editPropertyZip:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyOccupancy!==state.appraisal.property_occupancy?"red-color flashit bold":""}>
                  Occupancy
                </Col>
                <Col sm="7">
                  <Input type="select" value={state.editPropertyOccupancy} onChange={(e)=>setState({editPropertyOccupancy:e.target.value})}>
                    <option value="Owner-Occupied">Owner-Occupied</option>
                    <option value="Tenant-Occupied">Tenant-Occupied</option>
                    <option value="Vacant">Vacant</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyAPN!==state.appraisal.apn?"red-color flashit bold":""}>
                  APN
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAPN} onChange={(e)=>setState({editPropertyAPN:e.target.value})}/>
                </Col>
              </Row>
              <div className="my-divider"/>
              <label><i className="fa fa-home"/> Property Access</label>
              <Row>
                <Col sm="5" className={state.editPropertyAccessTitle!==state.appraisal.property_access_title?"red-color flashit bold":""}>
                  Title
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAccessTitle} onChange={(e)=>setState({editPropertyAccessTitle:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyAccessName!==state.appraisal.property_access_name?"red-color flashit bold":""}>
                  Name
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAccessName} onChange={(e)=>setState({editPropertyAccessName:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyAccessEmail!==state.appraisal.property_access_email?"red-color flashit bold":""}>
                  Email
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAccessEmail} onChange={(e)=>setState({editPropertyAccessEmail:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyAccessWorkPhone!==state.appraisal.property_access_work_phone?"red-color flashit bold":""}>
                  Work Phone #
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAccessWorkPhone} onChange={(e)=>setState({editPropertyAccessWorkPhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyAccessHomePhone!==state.appraisal.property_access_home_phone?"red-color flashit bold":""}>
                  Home Phone #
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAccessHomePhone} onChange={(e)=>setState({editPropertyAccessHomePhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="5" className={state.editPropertyAccessMobilePhone!==state.appraisal.property_access_mobile_phone?"red-color flashit bold":""}>
                  Mobile Phone #
                </Col>
                <Col sm="7">
                  <Input type="text" value={state.editPropertyAccessMobilePhone} onChange={(e)=>setState({editPropertyAccessMobilePhone:e.target.value})}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <br/><br/><br/>
          <center>
            <Button color="info" onClick={orderModificationToggle}>Close</Button>&nbsp;
            <Button color="warning" disabled={state.orderModificationSubmit} onClick={modifyOrder}>Submit Modification</Button>
          </center>
        </ModalBody>
      </Modal>

      <div className="floating-memo">
        <div onClick={floatingMemoToggle} className="cursor-pointer"><i className="fa fa-tag"></i>&nbsp;Special Instructions</div>
        <Collapse isOpen={state.dropdownOpen}>
          <Input type="textarea" value={state.appraisal.special_instructions} className="non-editable-textarea yellow-background-textarea margin-bottom" rows="20" name="text" id="exampleText">

          </Input>
        </Collapse>
      </div>
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Appraisal Details
          </div>
          <NavLink to="/appraisal">Back to appraisal list</NavLink>
        </Col>
        <Col sm="6">
          <div className="align-right">
            <i>*Cloning an order will result in a duplciate order in submitted status*</i><br/>
            {orderModificationButton}&nbsp;&nbsp;<Button color="warning" onClick={cloneOrder}>Clone Order</Button>
          </div>
        </Col>
      </Row>
      <div className="my-divider">&nbsp;</div>

      <Nav tabs>
        <NavItem>
          <NavLink className="nav-link active" to="#">Appraisal Details</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={"/condition/"+state.id}><span className="my-badge">{state.overallStatus.condition_count}</span> Condition</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={"/rebuttal/"+state.id}><span className="my-badge">{state.overallStatus.rebuttal_count}</span> Rebuttal</NavLink>
        </NavItem>
        {creditCardTab}
      </Nav>

      <br/>

      {progressBar}

      <div className="card" style={{minHeight:'500px',borderTop:'0px'}}>
        <div className="card-header header-color">
          <i className="fa fa-home"></i>&nbsp;{state.appraisal.reference_num} - {state.appraisal.property_street} {state.appraisal.property_city}, {state.appraisal.property_state} {state.appraisal.property_zip} - {state.appraisal.borrower_f_name} {state.appraisal.borrower_l_name}
        </div>
        <div className="card-body">

          {actionBar}
          <div className="my-divider">&nbsp;</div>
          <Row>
            <Col sm="6">
              <Row><Col sm="12"><b><i className="fa fa-user-circle"></i>&nbsp;Account Manager</b></Col></Row>

              <Row>
                <Col sm="4">
                  Name
                </Col>
                <Col sm="8">
                  {state.appraisal.account_manager}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Email
                </Col>
                <Col sm="8">
                  {state.appraisal.account_manager_email}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Phone
                </Col>
                <Col sm="8">
                  415-614 3880
                </Col>
              </Row>
              <div className="my-divider">&nbsp;</div>

              <Row><Col sm="12"><b><i className="fa fa-user-circle"></i>&nbsp;Borrower</b></Col></Row>

              <Row>
                <Col sm="4">
                  First Name
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_f_name}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Last Name
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_l_name}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Home Phone
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_phone}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Work Phone
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_work_phone}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Mobile Phone
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_mobile_phone}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Email
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_email}
                </Col>
              </Row>

              <br/>
              <div className="my-divider"></div>
              <Row><Col sm="4"><b><i className="fa fa-cogs"></i>&nbsp;Report Type(s)</b></Col><Col sm="8">{appraisalType}</Col></Row>

              <div className="my-divider"></div>
              <Row><Col sm="12"><b><i className="fa fa-info-circle"></i>&nbsp;Loan Facts</b></Col></Row>
              <Row>
                <Col sm="4">
                  Loan Number
                </Col>
                <Col sm="8">
                  {state.appraisal.loan_num}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Expected Date
                </Col>
                <Col sm="8" className="red-color">
                  {expectedDate}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Property Type
                </Col>
                <Col sm="8">
                  {state.appraisal.property_type}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Loan Type
                </Col>
                <Col sm="8">
                  {loanType}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Loan Purpose
                </Col>
                <Col sm="8">
                  {state.appraisal.loan_purpose}
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row><Col sm="12"><b><i className=" fa fa-user-circle"></i>&nbsp;Client</b></Col></Row>

              <Row>
                <Col sm="4">
                  Close By
                </Col>
                <Col sm="8">
                  {state.appraisal.client_name}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Open By
                </Col>
                <Col sm="8">
                  {state.appraisal.open_by_client_name}
                </Col>
              </Row>



              <div className="my-divider"></div>
              <Row><Col sm="12"><b><i className="fa fa-info-home"></i>&nbsp;Property Information</b></Col></Row>

              <Row>
                <Col sm="4">
                  Street
                </Col>
                <Col sm="8">
                  {state.appraisal.property_street}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  County
                </Col>
                <Col sm="8">
                  {state.appraisal.property_county}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  City
                </Col>
                <Col sm="8">
                  {state.appraisal.property_city}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  State
                </Col>
                <Col sm="8">
                  {state.appraisal.property_state}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Zip
                </Col>
                <Col sm="8">
                  {state.appraisal.property_zip}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Occupancy
                </Col>
                <Col sm="8">
                  {state.appraisal.property_occupancy}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  APN
                </Col>
                <Col sm="8">
                  {state.appraisal.apn}
                </Col>
              </Row>
              <br/>
              <div className="my-divider"></div>
              <Row><Col sm="12"><b><i className="fa fa-home"></i>&nbsp;Property Access</b></Col></Row>
              <Row>
                <Col sm="4">
                  Title
                </Col>
                <Col sm="8">
                  {state.appraisal.property_access_title}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Name
                </Col>
                <Col sm="8">
                  {state.appraisal.property_access_name}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Email
                </Col>
                <Col sm="8">
                  {state.appraisal.property_access_email}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Wrok Phone #
                </Col>
                <Col sm="8">
                  {state.appraisal.property_access_work_phone}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Home Phone #
                </Col>
                <Col sm="8">
                  {state.appraisal.property_access_home_phone}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Mobile Phone #
                </Col>
                <Col sm="8">
                  {state.appraisal.property_access_mobile_phone}
                </Col>
              </Row>
              <br/>
              <div className="my-divider"></div>
              <Row>
                <Col sm="12">
                  <b><i className="fa fa-calendar"></i>&nbsp;Dates</b>
                  <Row>
                    <Col sm="4">
                      Inspection:
                    </Col>
                    <Col sm="8">
                      {formatDateTime(state.appraisal.datetime_inspection)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      Report Due:
                    </Col>
                    <Col sm="8">
                      {formatDateTime(state.appraisal.datetime_expected)}
                    </Col>
                  </Row>

                  <br/>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <br/>
      <Row>
        <Col sm="6">
          <div className="card" style={{minHeight:'300px'}}>
            <div className="card-header header-color">
              <i className="fa fa-files-o"></i>&nbsp;Documents Storage
            </div>
            <div className="card-body">
              <AppraisalFile uploadType="Orders" entities={state.appraisal.entities} appraisalFk={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
            </div>
          </div>
        </Col>
        <Col sm="6">
          <div className="card" style={{minHeight:'300px'}}>
            <div className="card-header header-color">
              <i className="fa fa-comments"></i>&nbsp;Communications Log
            </div>
            <div className="card-body">
              <Comment entities={state.appraisal.entities} appraisalFk={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
            </div>
          </div>
        </Col>
      </Row>
      <BottomBar id={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
    </div>
  );
}


export default Appraisal;
