//Author June Leow
//Date Jul 15th, 2024
import {bindActionCreators} from 'redux';
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator} from '../../util/util';
import {Button, Input, Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {login} from '../../actions/action-login';
import React, {useReducer, useEffect} from 'react';
import {useParams} from 'react-router-dom';

//initialize the state
const initialState = {
  firstName:'',
  lastName:'',
  email:'',
  role:'',
  password:'',
  confirmPassword:'',
  showPassword:false,
  isFocused:false,
  phone:'',
  submitDisabled:false,
  warningMessage:'',
  action:''
};

//reducer function that perform state update
const reducer = getReducer();


const NewClientUser  = (props)=>{
  const controller = new AbortController();

  let targetWholesale = useParams().targetWholesale;
  targetWholesale = targetWholesale?targetWholesale:-1;
  let newInitialState = Object.assign({}, initialState, {
    targetWholesale:targetWholesale
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.action==='register'){
      addNewClient();
      setState({action:''});
    }
  },[state.action])

  //non API call but simpyl manage state
  const handlePasswordFocus = () => {
    setState({ isFocused: true });
  };

  const handlePasswordBlur = () => {
    setState({ isFocused: false });
  };

  const handleToggle = () => {
    setState({ showPassword: !state.showPassword });
  }

  const isPasswordValid = (password, type='') => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCharacters = /[!@#$%^&*]/g;
    const isLowerCaseValid = password.match(lowerCaseLetters);
    const isUpperCaseValid = password.match(upperCaseLetters);
    const isNumberValid = password.match(numbers);
    const isSpecialCharacterValid = password.match(specialCharacters);
    const isLengthValid = password.length >= 12;

    if(type === 'lowercase'){
      return(isLowerCaseValid);
    }else if(type === 'uppercase'){
      return(isUpperCaseValid);
    }else if(type === 'number'){
      return(isNumberValid);
    }else if(type === 'special'){
      return(isSpecialCharacterValid);
    }else if(type === 'length'){
      return(isLengthValid);
    }else{
      return (
        isLowerCaseValid &&
        isUpperCaseValid &&
        isNumberValid &&
        isSpecialCharacterValid &&
        isLengthValid
      );
    }
  };

  const checkPassword=(password1, password2)=>{
    if(!isPasswordValid(password1)){
      setState({warningMessage:'Please match the required format.', formValid:false});
    }
    else if(password1!==password2){
      setState({warningMessage:'Your password does not match.', formValid:false});
    }
    else{
      setState({warningMessage:'', formValid:true});
    }
  }

  //API call
  const addNewClientUser=(clientBranchID)=>{
    let parameters = [
      {
        field:'first_name',
        value:state.firstName
      },
      {
        field:'last_name',
        value:state.lastName
      },
      {
        field:'role',
        value:state.role
      },
      {
        field:'branch_fk',
        value:clientBranchID
      },
      {
        field:'password',
        value:state.password
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'phone',
        value:state.phone
      },
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code!=='00'){
        setState({submitDisabled:false});
      }
      else{
        //log user in
        let credential = {
          email: state.email,
          password: state.password
        }
        let control = {
          showLoading: props.showLoading,
          hideLoading: props.hideLoading,
          logout: props.logout,
          noToken:true
        };
        props.login(credential,control);
      }
    };
    let errorCallBack = ()=>{
      setState({submitDisabled:false});
    };
    httpPost('client/branch/user/noSession/create', parameters, 'User account created successfully, logging you in.','Oops, something went wrong and could not create your user account. Please try again later.', callBack, errorCallBack);
  }

  const buttonHandler=(e)=>{
    e.preventDefault();
    if(state.submitDisabled)
      return;

    if(!isPasswordValid(state.password)){
      setState({warningMessage:'Please match the required format.'});
      return;
    }
    else if(state.password!==state.confirmPassword){
      setState({warningMessage:'Your password does not match.'});
      return;
    }

    setState({submitDisabled:true});

    //get appraisal call back
    let errorCallBack = ()=>{
      setState({submitDisabled:false});
    };
   
    let callBack = (response)=>{
      console.log(response);
      let code = response.data.code;
      if(code!=='00'){
        setState({submitDisabled:false, warningMessage:'This email had been used.'});
      }
      else{
        setState({submitDisabled:true, warningMessage:'', action:'register'});
      }
    };

    httpGet('user/check/'+state.email,'','', callBack, errorCallBack);
  }

  const addNewClient=()=>{
    setState({warningMessage:'Submitting registration please wait...'});
    let parameters = [
      {
        field:'company',
        value:state.company
      },
      {
        field:'email',
        value:''
      },
      {
        field:'phone',
        value:''
      },
      {
        field:'fax',
        value:''
      },
      {
        field:'street',
        value:''
      },
      {
        field:'city',
        value:''
      },
      {
        field:'county',
        value:''
      },
      {
        field:'state',
        value:''
      },
      {
        field:'zip',
        value:''
      },
      {
        field:'fnm',
        value:''
      },
      {
        field:'fre',
        value:''
      },
      {
        field:'businessUnit',
        value:''
      },
      {
        field:'fhaClientID',
        value:''
      },
      {
        field:'businessUnitFHA',
        value:''
      },
      {
        field:'status',
        value:'Approved'
      }
    ];

    if(state.targetWholesale!==-1){
      let tmp = {
        field:'targetWholesale',
        value:state.targetWholesale
      };

      parameters.push(tmp);
    }

    let callBack = (response)=>{
      let code = response.data.code;
      if(code!=='00'){
        setState({submitDisabled:false});
      }
      else{
        let clientBranchID = response.data.clientBranchID;
        addNewClientUser(clientBranchID);
      }
    };
    let errorCallBack = ()=>{
      setState({submitDisabled:false});
    };
    httpPost('client/noSession/create',parameters, '','Oops, something went wrong and could not create this client. Please try again later.',callBack, errorCallBack);
  }

  //render
  const isLowerCaseValid = isPasswordValid(state.password, 'lowercase');
  const isUpperCaseValid = isPasswordValid(state.password, 'uppercase');
  const isSpecialCharacterValid = isPasswordValid(state.password, 'special');
  const isNumberValid = isPasswordValid(state.password, 'number');
  const isLengthValid = isPasswordValid(state.password, 'length');

  let valid = <i className="fa fa-check-circle-o"></i>;
  let invalid = <i className="fa fa-times-circle-o"></i>;


  return(
    <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
      <div className="padding">
        <div className="login-container well padding">
          <center>
            <img alt="company logo" src="https://admin.homevms.com/img/logo_small.png" className="logo-login"/><br/>
            New User Account Registration
          </center>
          <div className="non-empty-container">
            <p className="no-padding no-margin"></p>
          </div>

          <font style={{fontSize:'11px'}}>
            <font color="red">*</font>Complete the information below and click on Create. From there, you can submit your appraisal information so that we can get the assignment started while our team works quickly in the background to activate your account.
            <br/><br/>
            For any questions, simply email <a href="mailto:Ordes@homevms.com">Ordes@homevms.com</a>
          </font>
          <form onSubmit={buttonHandler}>
            <font color="red">{state.warningMessage}</font><br/>
            <label><font color="red">*</font>Company Name</label><br/>
            <Input required={true} type="text" value={state.company} onChange={(e)=>setState({company:e.target.value})}/>
            <br/>
            <Row>
              <Col sm="6">
                <label><font color="red">*</font>First Name</label><br/>
                <Input required={true} type="text" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
              </Col>
              <Col sm="6">
                <label><font color="red">*</font>Last Name</label><br/>
                <Input required={true} type="text" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col sm="6">
                <label><font color="red">*</font>Role</label><br/>
                <Input required={true} type="select" value={state.role} onChange={(e)=>setState({role:e.target.value})}>
                  <option value=""></option>
                  <option value="Loan Officer">Loan Officer</option>
                  <option value="Loan Processor">Loan Processor</option>
                  <option value="Broker">Broker</option>
                  <option value="Other">Other</option>
                </Input>
              </Col>
              <Col sm="6">
                <label>Phone</label><br/>
                <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label><font color="red">*</font>Email (Username)</label><br/>
                <Input required={true} type="email" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
              </Col>
              <Col sm="6">
                <label>Phone</label><br/>
                <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
              </Col>
            </Row>

            <br/>
            <Row>
              <Col sm="6">
                <label><font color="red">*</font>Password</label><br/>
                <div className="input-container">
                  <Input type={state.showPassword ? 'text' : 'password'} value={state.password} onChange={(e)=>{setState({password:e.target.value});checkPassword(e.target.value, state.confirmPassword)}}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Must contain at least one number, one special character, one uppercase and lowercase letter, and at least 8 or more characters"
                    required
                  />
                  <span className="toggle-button" style={{color:'#666677'}} onClick={handleToggle}>
                    {state.showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                  </span>
                </div>
              </Col>
              <Col sm="6">
                <label><font color="red">*</font>Confirm Password</label><br/>
                <Input required={true} type="password" value={state.confirmPassword} onChange={(e)=>{setState({confirmPassword:e.target.value});checkPassword(state.password, e.target.value)}}/>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                {state.isFocused && (
                  <div id="message">
                    <label>Password must contain the following:</label>
                    <p id="letter" style={{lineHeight:'3px'}} className={(isLowerCaseValid&&isUpperCaseValid)? 'green-color' : 'red-color'}>
                      {(isLowerCaseValid&&isUpperCaseValid)? valid:invalid} Combination of uppercase letters & lowercase letters
                    </p>
                    <p id="number" style={{lineHeight:'3px'}} className={isNumberValid? 'green-color' : 'red-color'}>
                      {isNumberValid? valid:invalid} At least 1 number (0-9)
                    </p>
                    <p id="special-char" style={{lineHeight:'3px'}} className={isSpecialCharacterValid? 'green-color' : 'red-color'}>
                      {isSpecialCharacterValid? valid:invalid} At least 1 special Chracter (!@#$%^&*)
                    </p>
                    <p id="length" style={{lineHeight:'3px'}} className={isLengthValid? 'green-color' : 'red-color'}>
                      {isLengthValid? valid:invalid} Minimum <b>12 characters</b>
                    </p>
                  </div>
                )}  
              </Col>
            </Row>
            <br/>
            <div className="align-right">
              <Button color="warning" disabled={state.submitDisabled}>Create My @Home Account</Button>
            </div>
          </form>
        </div>
      </div>
    </div>);
}


//REDUX COMPONENT
function mapStateToProps(state){
  return {
    jwtToken: state.session.jwtToken,
    isLoggedIn: state.session.isLoggedIn,
    userRole: state.session.userRole,
    userFirstName: state.session.userFirstName,
    userLastName: state.session.userLastName
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    login: login
  },dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(NewClientUser);
