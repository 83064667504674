//Author June Leow
//Date June 5th, 2024
import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import { callBackGenerator, formatDate, generateSID, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
import MySelect from '../util/my-select';

//initialize the state
const initialState = {
  clients:[],
  appraisalTypes:[],
  SID:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  orderStatuses: [],
  county:'',
  state:'',
  priorities:[],
  filterCounties:[],

  loanPurposes:[],
  loanTypes:[],
  accountManagers:[],
  clientBranches:[],
  statuses:[],
  selectedStates:[],
};

//reducer function that perform state update
const reducer = getReducer();

/*
[
  {id:'from',value:'state.fromDate',updateFunc:func,width:'2'},
  {id:'to',value:'state.toDate',updateFunc:func,width:'2'},
  {id:'client',value:'state.client',updateFunc:func,width:'3'},
]
*/
const ReportFilter  = (props)=>{
  const controller = new AbortController();

  //set default appraisal type 
  let filterAppraisalTypes=[];
  for(let i=0;i<props.configs.length;i++){
    let config = props.configs[i];

    if(config.id==='appraisalType'&&config.value&&config.value.length>0){
      filterAppraisalTypes = config.value;
    }
  }

  let newInitialState = Object.assign({}, initialState, {
    filterAppraisalTypes: filterAppraisalTypes,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  const stateSID = useRef();

  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run


  //run only once when component is loaded
  useEffect(()=>{
    //loop through the configuration
    for(let i=0;i<props.configs.length;i++){
      if(props.configs[i].id==='client')
        getAllClientProfiles();
      else if(props.configs[i].id==='appraisalType')
        getAllAppraisalTypes();
      else if(props.configs[i].id==='loanPurpose')
        getAllLoanPurposes();
      else if(props.configs[i].id==='loanType')
        getAllLoanTypes();
      else if(props.configs[i].id==='accountManager')
        getAllAccountManagers();
      else if(props.configs[i].id==='appraiserStatus')
        getAllAppraiserStatuses();
      else if(props.configs[i].id==='priority')
        getPriority();
      else if(props.configs[i].id==='orderStatus')
        getAllOrderStatuses();
    }

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const handleAsyncSelectChange = (v, callBack)=>{
    callBack(v);
    setState({appraiser:v});
  }

  const removeCounty = (county, callBack)=>{
    let filterCounties = state.filterCounties.slice();
    let index = filterCounties.indexOf(county);
    if(index!==-1)
      filterCounties.splice(index,1);
    setState({filterCounties:filterCounties});
    callBack(filterCounties);
  }

  const addNewCounty = (callBack)=>{
    let filterCounties = state.filterCounties.slice();

    if(filterCounties.indexOf(state.county)===-1&&state.county!==''){
      filterCounties.push(state.county);
    }
    callBack(filterCounties);
    setState({filterCounties:filterCounties});
  }

  const removeAppraisalType = (appraisalType, callBack)=>{
    let filterAppraisalTypes = state.filterAppraisalTypes.slice();
    let index = filterAppraisalTypes.indexOf(appraisalType);
    if(index!==-1)
      filterAppraisalTypes.splice(index,1);
    setState({filterAppraisalTypes:filterAppraisalTypes});
    callBack(filterAppraisalTypes);
  }

  const addNewAppraisalType = (appraisalType, callBack)=>{
    let filterAppraisalTypes = state.filterAppraisalTypes.slice();

    if(filterAppraisalTypes.indexOf(appraisalType)===-1&&appraisalType!==''){
      filterAppraisalTypes.push(appraisalType);
    }

    callBack(filterAppraisalTypes);
    setState({filterAppraisalTypes:filterAppraisalTypes});
  }

  const addNewState = (stateName, callBack, group=false)=>{
    let selectedStates = stateName;
    if(group){
      selectedStates = state.selectedStates.slice();

      if(selectedStates.indexOf(stateName)===-1&&stateName!==''){
        selectedStates.push(stateName);
      }

      setState({selectedStates:selectedStates});
    }

    callBack(selectedStates);
  }

  const removeState = (stateName, callBack) =>{
    let states = state.selectedStates.slice();
    let index = states.indexOf(stateName);

    if(index!==-1)
      states.splice(index, 1);
    setState({selectedStates:states});
    callBack(states);
  }


  //API call
  const getAllClientProfiles = () =>{
    let callBack = apiCallBack([{state:'clients',key:'data'}]);
    httpGet('client/get/limit=-1&offset=-1', '','Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getAllAppraisalTypes = () =>{
    let callBack = apiCallBack([{state:'appraisalTypes',key:'data'}]);
    httpGet('appraisalType/get', '','Oops, something went wrong and could not retrieve appraisal types.', callBack);
  }

  const getAllLoanPurposes = () =>{
    let callBack = apiCallBack([{state:'loanPurposes',key:'data'}]);
    httpGet('loanPurpose/get', '','Oops, something went wrong and could not retrieve loan purposes.', callBack);
  }

  const getAllLoanTypes = () =>{
    let callBack = apiCallBack([{state:'loanTypes',key:'data'}]);
    httpGet('loanType/get', '','Oops, something went wrong and could not retrieve loan types.', callBack);
  }

  //get a list of appraiser status for the drop down
  const getAllAppraiserStatuses = () =>{
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet('appraiser/statuses/get', '', 'Oops, something went wrong and could not retrieve appraiser statuses.', callBack);
  }

  const getAllAccountManagers = () =>{
    let callBack = apiCallBack([{state:'accountManagers', valuekey:['ID', 'name']}]);
    httpGet('accountManager/get', '','Oops, something went wrong and could not load a list of account managers. Please try again later.', callBack);
  }

  const getClientBranches = (client) =>{
    if(client!=='' && client!=='0'){
      let callBack = apiCallBack([{state:'clientBranches', valuekey:['ID', 'branch_name']}]);
      httpGet('client/branch/get/'+client, '', 'Oops, something went wrong and could not retrieve client entities list.', callBack);
    }
  }

  const getAllOrderStatuses = () => {
    let callBack = apiCallBack([{state:'orderStatuses', key:'data'}]);
    httpGet('appraisal/statuses/get', '', 'Oops, something went wrong and could not retrieve order statuses.', callBack);
  }


  const getAppraiser = (keyword, loadOptionsCallBack)=>{
    if(keyword&&keyword!==''){
      let SID = generateSID();
      //stateRef.SID = SID;
      stateSID.current = SID;

      let url = 'appraiser/get/limit=100&name='+keyword;
      let callBack = (response)=>{
        if(SID===stateSID.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].first_name+' '+response.data.data[i].last_name+' - '+response.data.data[i].email+' - '+formatDate(response.data.data[i].datetime_last_login);
              tmp.value = response.data.data[i].ID;

              options.push(tmp);
            }

            loadOptionsCallBack(options);
            return options;
          }
        }
      };

      httpGet(url, '','Oops, something went wrong and could not search for appraiser. Please try again later.', callBack);
    }
  }

  const getPriority = () => {
    let callBack = apiCallBack([{state:'priorities', valuekey:['priority', 'priority']}]);
    httpGet('priority/get', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  let filterAppraisalType;
  let filterCounty;
  let filterState;

  //render
  return <div>
    <Row>
      {
        props.configs.map(
          (config, index)=>{
            let label = config.id;
            let input = <Input type="text" value={config.value} onChange={(e)=>config.updateFunc(e.target.value)}/>;
            let value;
            let className = '';
            let labelClassName = '';

            switch(config.id){
              case 'from':
                label = 'From';
                if(config.value&&config.value!=='0000-00-00'&&config.value!=='')
                  value = moment(config.value).toDate();
                else
                  value = null;
                input = <DatePicker
                  className="form-control"
                  selected={value}
                  onChange={(text)=>{config.updateFunc(text.toLocaleDateString('en-CA'))}}
                />;
                break;
              case 'to':
                label = 'To';
                if(config.value&&config.value!=='0000-00-00'&&config.value!=='')
                  value = moment(config.value).toDate();
                else
                  value = null;
                  input = <DatePicker
                    className="form-control"
                    selected={value}
                    onChange={(text)=>{config.updateFunc(text.toLocaleDateString('en-CA'))}}
                  />;
                break;
              case 'fromTime':
                label = 'From';
                if(config.value&&config.value!=='0000-00-00 00:00:00'&&config.value!=='')
                  value = moment(config.value).toDate();
                else
                  value = null;
                input = <DatePicker
                  className="form-control"
                  showTimeSelect
                  selected={value}
                  timeIntervals={15}
                  dateFormat='dd MMM yyyy hh:mm aa'
                  onChange={(text)=>{config.updateFunc(text)}}
                />;
                break;
              case 'toTime':
                label = 'To';
                if(config.value&&config.value!=='0000-00-00 00:00:00'&&config.value!=='')
                  value = moment(config.value).toDate();
                else
                  value = null;
                  input = <DatePicker
                    className="form-control"
                    showTimeSelect
                    selected={value}
                    timeIntervals={15}
                    dateFormat='dd MMM yyyy hh:mm aa'
                    onChange={(text)=>{config.updateFunc(text)}}
                  />;
                break;
              case 'client':
                label = 'Close By';
                if(config.label&&config.label!=='')
                  label = config.label;
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v); getClientBranches(v)}}
                  options={state.clients.map((client)=>{return {label:client.company, value:client.ID}})}
                />;
                break;
              case 'clientBranch':
                label = 'Client Branch';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={state.clientBranches.map((client)=>{return {label:client.value, value:client.key}})}
                />;
                break;
              case 'loan':
                label = 'Loan';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={[{label:"All",value:""},{label:"Retail",value:"Retail"},{label:"Wholesale",value:"Wholesale"}]}
                />;
                break;
              case 'loanPurpose':
                label = 'Loan Purpose';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={state.loanPurposes.map((loanPurpose)=>{return {label:loanPurpose.loan_purpose, value:loanPurpose.loan_purpose}})}
                />;
                break;
              case 'loanType':
                label = 'Loan Type';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={state.loanTypes.map((loanType)=>{return {label:loanType.loan_type, value:loanType.loan_type}})}
                />;
                break;
              case 'openBy':
                label = 'Open By';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={state.clients.map((client)=>{return {label:client.company, value:client.ID}})}
                />;
                break;
              case 'appraiser':
                label = 'Appraiser';
                input = <MySelect
                  type="async-select"
                  value={config.value}
                  onChange={(e)=>handleAsyncSelectChange(e, config.updateFunc)}
                  defaultOptions
                  loadOptions={(keyword, callBack)=>{getAppraiser(keyword, callBack)}}
                />;
                break;
              case 'appraiserStatus':
                label = 'Status';
                input = <MySelect
                  type="select"
                  value={config.value}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={[{label:"All",value:""}].concat(state.statuses.map((status)=>{
                    return {label:status.status, value:status.status};
                  }))}
                />;
                break;
              case 'accountManager':
                label = 'Account Manager';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={state.accountManagers.map((ac)=>{return {label:ac.value, value:ac.key}})}
                />;
                break;
              case 'priority':
                label = 'Priority';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={state.priorities.map((priority)=>{return {label:priority.value, value:priority.key}})}
                />;
                break;
              case 'rush':
                label = 'Rush';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={[{label:'Rush',value:'Rush'},{label:'Non-rush',value:'Non-rush'}]}
                />;
                break;
              case 'complex':
                label = 'Complex';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={[{label:'Complex',value:'Complex'},{label:'Non-complex',value:'Non-complex'}]}
                />;
                break;
              case 'orderStatus':
                label = 'Order Status';
                labelClassName = config.labelClassName;
                input = <MySelect
                  type="select"
                  value={config.value}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={[{label:"All",value:"All"}].concat(
                    state.orderStatuses.map((status)=>{
                      return {label:status.name, value:status.name};
                      })
                  )}
                />;
                break;
              case 'state':
                label = 'State';
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={true}
                  onChange={(v)=>{addNewState(v, config.updateFunc, config.group)}}
                  options={state.states.map((state)=>{return {label:state.value,value:state.value}})}
                />;

                if(config.group){
                  filterState = state.selectedStates.map(
                    (stateName, index)=>{
                      return <div key={index} onClick={()=>removeState(stateName, config.updateFunc)} className="display-inline my-well cursor-pointer" style={{padding:'5px', background:"#F4FAFE", fontSize:'12px', marginLeft:'5px'}}>
                        <i className="fa fa-minus red-color"></i> {stateName}&nbsp;
                      </div>;
                    }
                  )
                }
                break;
              case 'city':
                label = 'City';
                input = <Input type="text" value={config.value} onChange={(e)=>{config.updateFunc(e.target.value)}}/>
                break;
              case 'entity':
                label = 'AE/LO/Loan Processor/Broker';
                input = <Input type="text" value={config.value} onChange={(e)=>{config.updateFunc(e.target.value)}}/>
                break;
              case 'county':
                label = 'County';
                input = <Input type="text" value={config.value} onChange={(e)=>{config.updateFunc(e.target.value)}}/>
                if(config.group){
                  input = <InputGroup style={{height:'38px'}}>
                    <div className="cursor-pointer input-group-addon" addontype="prepend"  style={{height:'100%'}} onClick={()=>{addNewCounty(config.updateFunc)}}>
                      <i className="fa fa-plus green-color"></i>
                    </div>
                    <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
                  </InputGroup>;
                }
                
                filterCounty = state.filterCounties.map(
                  (county, index)=>{
                    return <div key={index} onClick={()=>removeCounty(county, config.updateFunc)} className="display-inline my-well cursor-pointer" style={{padding:'5px', background:"#F4FAFE", fontSize:'12px'}}>
                      <i className="fa fa-minus red-color"></i> {county}&nbsp;
                    </div>;
                  }
                )

                break;
              case 'appraisalType':
                label = 'Report Type';
                input = <MySelect
                  type="select"
                  value={config.value}
                  onChange={(v)=>{addNewAppraisalType(v, config.updateFunc)}}
                  options={state.appraisalTypes.map((appraisalType)=>{return {label:appraisalType.appraisal_type, value:appraisalType.appraisal_type}})}
                />;

                filterAppraisalType = state.filterAppraisalTypes.map(
                  (appraisalType, index)=>{
                    return <div key={index} onClick={()=>removeAppraisalType(appraisalType, config.updateFunc)} className="display-inline my-well cursor-pointer" style={{padding:'5px', background:"#F4FAFE", fontSize:'12px'}}>
                      <i className="fa fa-minus red-color"></i> {appraisalType}&nbsp;
                    </div>;
                  }
                );
                break;
              case 'custom':
                label = config.label;
                input = <Input type="text" placeholder={config.placeHolder} value={config.value} onChange={(e)=>{config.updateFunc(e.target.value)}}/>
                break;
              case 'customNumber':
                label = config.label;
                input = <Input type="number" placeholder={config.placeHolder} value={config.value} onChange={(e)=>{config.updateFunc(e.target.value)}}/>
                break;
              case 'customSelect':
                console.log(config.options)
                label = config.label;
                labelClassName = config.labelClassName;
                className = config.className;
                input = <MySelect
                  type="select"
                  value={config.value}
                  selectIsClearable={config.clearable}
                  onChange={(v)=>{config.updateFunc(v)}}
                  options={config.options}
                />;
                break;
              case 'button':
                label = '';
                value = config.value
                className = config.className;
                input = <Button color={config.color} onClick={config.updateFunc}>{value}</Button>;
                break;
              default:
                break;
            }

            return <Col sm={config.width} key={config.id+"-"+index} className={className} style={{height:'70px'}}>
              <label className={labelClassName}>{label}</label><br/>
              {input}
            </Col>;
          }
        )
      }
    </Row>
    {filterState}
    {filterCounty}
    {filterAppraisalType}
  </div>;
}

export default ReportFilter;
