//Author June Leow
//Date Jul 12th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Nav, NavItem, NavLink as NavLinkL, Row, TabContent, TabPane } from 'reactstrap';
import { formatDateTime, formatNumber, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, showMessage } from '../../util/util';
import valid from "card-validator";
//initialize the state
const initialState = {
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0,
    has_credit_card_receivable:'no'
  },
  appraisal:{
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
    borrower_f_name:'',
    borrower_l_name:'',
    reference_num:'',
    loan_num:''
  },

  disabled:false,

  newcc_card_type:'',
  newcc_first_name:'',
  newcc_last_name:'',
  newcc_email:'',
  newcc_card_number:'',
  newcc_cvc:'',
  newcc_expiration_year:'',
  newcc_expiration_month:'',
  newcc_street:'',
  newcc_city:'',
  newcc_state:'',
  newcc_zip:'',
  coveredBy:'',
  activeTab:'1',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  total:0
};

//reducer function that perform state update
const reducer = getReducer();


const CreditCard  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getOverallStatus(state.id);
    getAppraisal();
    getTotalPending();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{ 
    validateCCNumber();
  },[state.newcc_card_number])

  useEffect(()=>{ 
    validateCCType();
  },[state.newcc_card_type])

  //non API call but simpyl manage state
  // Validate the Credit Card Number
  const validateCCNumber=()=>{
    // console.log(state.newcc_card_number);
    let newCCNumber = state.newcc_card_number;

    let creditCard = valid.number(newCCNumber);
    let ccNumberError = "An unknown error occured. Please try again later";
    let ccNumberValid = false;

    if (newCCNumber === null || !newCCNumber.trim()) {
      ccNumberError = "Credit card number is not complete";
    } else if (creditCard.isValid) {
      ccNumberValid = true;
    } else {
      ccNumberError = "Credit card number is invalid";
    }

    setState({ccNumberValid: ccNumberValid, ccNumberError: ccNumberError});
    validateCCType();
  }

  // Validate the Credit Card Type
  const validateCCType=()=>{
    let newCCNumber = state.newcc_card_number;
    let newCCType = state.newcc_card_type;

    let creditCard = valid.number(newCCNumber);
    let ccTypeError = "An unknown error occured. Please try again later";
    let ccTypeValid = false;

    //Mapping
    if(newCCType === "amex"){
      newCCType = "american-express";
    }else if(newCCType === "dinersclub"){
      newCCType = "diners-club";
    }

    //Card Type Verification
    if (newCCType === null || !newCCType.trim() || creditCard.card === null) {
      if(creditCard.card === null){
        ccTypeError = "";
        ccTypeValid = true;
      }else{
        ccTypeError = "Credit card type is not complete";
      }
    } else if (
      creditCard.card.type &&
      creditCard.card.type.toUpperCase() === newCCType.toUpperCase()
    ) {

      ccTypeValid = true;
    } else {
      ccTypeError = "Credit card type is invalid";
    }

    setState({ccTypeValid: ccTypeValid, ccTypeError: ccTypeError});
  }

  //API call
  const getTotalPending=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let total =0;
        if(response.data.data)
          total = response.data.data;
        setState({total:total});
      }
    };
    httpGet('billing/pendingCC/'+state.id,'','Oops, something went wrong and could not load total pending payment. Please try again later.', callBack);
  }

  const registerCC=(e)=>{
    e.preventDefault();
    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'billing_first_name',
        value:state.newcc_first_name
      },
      {
        field:'billing_last_name',
        value:state.newcc_last_name
      },
      {
        field:'email',
        value:state.newcc_email
      },
      {
        field:'card_number',
        value:state.newcc_card_number
      },
      {
        field:'card_type',
        value:state.newcc_card_type
      },
      {
        field:'coveredBy',
        value:state.coveredBy
      },
      {
        field:'cvc',
        value:state.newcc_cvc
      },
      {
        field:'expiration_month',
        value:state.newcc_expiration_month
      },
      {
        field:'expiration_year',
        value:state.newcc_expiration_year
      },
      {
        field:'billing_street',
        value:state.newcc_street
      },
      {
        field:'billing_city',
        value:state.newcc_city
      },
      {
        field:'billing_state',
        value:state.newcc_state
      },
      {
        field:'billing_zip',
        value:state.newcc_zip
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        history('/appraisal/'+state.id);
      }
    };

    httpPost('billing/creditCard/create', parameters, 'Credit card registered successfully.', 'Oops, something went wrong and could not register the credit card. Please try again later.', callBack);
  }

  const emailBorrower=()=>{
    setState({disabled:true});
    let parameters = [
      {
        field:'appraisalFk',
        value:state.id
      }
    ];

    httpPost('billing/creditCard/email/borrower', parameters, 'Email sent to borrower successfully.', 'Oops, something went wrong and could not email the borrower. Please try again later.', ()=>{});
  }

  //get appraisal
  const getAppraisal=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({appraisal:response.data.data});

        let entities = [];
        for(let i=0;i<response.data.data.entities.length;i++){
          let entity = response.data.data.entities[i];
          entities.push(entity);
        }

        setState({entities:entities});
      }
    };

    httpGet('appraisal/'+state.id, '' ,'Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  //get overall status
  const getOverallStatus=()=>{
    const callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({overallStatus:response.data.data});
      }
    }
    httpGet('appraisal/overall/status/'+state.id, '', 'Oops, something went wrong and could not load the information of this appraisal. Please try again later.', callBack)
  }

  //render
  let creditCardTab;

  if(state.overallStatus.has_credit_card_receivable==='yes'){
    creditCardTab = <NavItem>
      <NavLink className="nav-link active" to={"/credit-card/"+state.id}>Payment</NavLink>
    </NavItem>
  }

  let expirationYear = [];

  let currentYear = new Date().getFullYear();

  for(let i=0;i<20;i++){
    expirationYear.push(currentYear);
    currentYear++;
  }

  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Payment
          </div>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <Nav tabs>
        <NavItem>
          <NavLink className="nav-link" to={"/appraisal/"+state.id}>Appraisal Details</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={"/condition/"+state.id}><span className="my-badge">{state.overallStatus.condition_count}</span> Condition</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={"/rebuttal/"+state.id}><span className="my-badge">{state.overallStatus.rebuttal_count}</span> Rebuttal</NavLink>
        </NavItem>
        {creditCardTab}
      </Nav>
      <br/>
      <div>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { setState({activeTab:'1'}) }}
              to="#"
            >
              Submit New Credit Card
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
              onClick={() => { setState({activeTab:'2'}) }}
              to="#"
            >
              Email Borrower URL
            </NavLinkL>
          </NavItem>
        </Nav>
        <div className="padding">
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="1">

              <Row>
                <Col sm="1">

                </Col>
                <Col sm="10">
                  <div className="align-right">
                    <font style={{fontSize:'20px'}}>
                      Total Due: <b><u>${formatNumber(state.total)}</u></b>
                    </font>
                  </div>
                  <br/>
                  <center>
                    <b>
                      <i>Thank you for your prompt payment.  This charge will appear as @home appraisal on your statement.</i>
                      <br/>
                    </b>
                  </center>
                  <br/>
                  <Card style={{background:'#f5f5f5'}}>
                    <CardHeader className="header-color">
                      <center>
                          <i className="fa-brands fa-cc-visa"></i> Register new credit card profile
                      </center>
                    </CardHeader>
                    <CardBody>
                      <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
                        <label>Reference #:&nbsp;&nbsp;</label>
                        {state.appraisal.reference_num}

                        <br/>
                        <label>Status:&nbsp;&nbsp;</label>
                        {state.appraisal.status}

                        <br/>
                        <label>Property address:&nbsp;&nbsp;</label>
                        {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}

                        <br/>
                        <label>Borrower:&nbsp;&nbsp;</label>
                        {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}

                        <br/>
                        <label>Loan purpose:&nbsp;&nbsp;</label>
                        {state.appraisal.loan_purpose}

                        <br/>
                        <label>Loan type:&nbsp;&nbsp;</label>
                        {state.appraisal.loan_type}

                        <br/>
                        <label>Loan #:&nbsp;&nbsp;</label>
                        {state.appraisal.loan_num}

                        <br/>
                        <label>Date submitted:&nbsp;&nbsp;</label>
                        {formatDateTime(state.appraisal.datetime_submitted)}

                        <br/>
                      </div>
                      <div className="my-divider"></div>

                      <form onSubmit={registerCC}>

                        <Card>
                          <CardHeader className="header-color">
                            <i className="fa fa-user"></i> Card holder information
                          </CardHeader>
                          <CardBody style={{background:'white'}}>
                            <Row>
                              <Col sm="6">
                                <Input type="text" required={true} placeholder="First name*" value={state.newcc_first_name} onChange={(e)=>setState({newcc_first_name:e.target.value})}/>
                              </Col>
                              <Col sm="6">
                                <Input type="text" required={true} placeholder="Last name*" value={state.newcc_last_name} onChange={(e)=>setState({newcc_last_name:e.target.value})}/>
                              </Col>
                              <Col sm="6">
                                <br/>
                                <Input type="text" placeholder="Email (optional, for receipt)" value={state.newcc_email} onChange={(e)=>setState({newcc_email:e.target.value})}/>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <br/>
                        <Card>
                          <CardHeader className="header-color">
                            <i className="fa fa-credit-card"></i> Credit card information
                          </CardHeader>
                          <CardBody style={{background:'white'}}>
                            <Row>
                              <Col sm="7">
                                <Input type="text" required={true} placeholder="Credit card number*" value={state.newcc_card_number} onChange={(e)=>setState({newcc_card_number:e.target.value})}/>
                                <div>{!state.ccNumberValid&&<h6><font color="red">{'*** '+state.ccNumberError+' ***'}</font></h6>}</div>
                              </Col>
                              <Col sm="5">
                                <Input type="text" required={true} placeholder="CVC*" value={state.newcc_cvc} onChange={(e)=>setState({newcc_cvc:e.target.value})}/>
                              </Col>
                            </Row>
                            <br/>
                            <Row>
                              <Col sm="6">
                                <Input type="select" required={true} value={state.newcc_card_type} onChange={(e)=>setState({newcc_card_type:e.target.value})}>
                                  <option value="">Select card type</option>
                                  <option value="visa">Visa</option>
                                  <option value="mastercard">Master Card</option>
                                  <option value="amex">American Express</option>
                                  <option value="dinersclub">Dinners Club</option>
                                  <option value="discover">Discover</option>
                                </Input>
                                <div>{!state.ccTypeValid&&<h6><font color="red">{'*** '+state.ccTypeError+' ***'}</font></h6>}</div>
                              </Col>
                              <Col sm="3">
                                <Input type="select" required={true} value={state.coveredBy} onChange={(e)=>setState({coveredBy:e.target.value})}>
                                  <option value="">Select paid by</option>
                                  <option value="visa">Borrower</option>
                                  <option value="mastercard">Broker</option>
                                  <option value="amex">Lender</option>
                                </Input>
                              </Col>
                            </Row>
                            <br/>
                            <Row>
                              <Col sm="6">
                                <Input type="select" required="true" value={state.newcc_expiration_month} onChange={(e)=>setState({newcc_expiration_month:e.target.value})}>
                                  <option value="">Select expiration month</option>
                                  <option value="01">January</option>
                                  <option value="02">Febuary</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </Input>
                              </Col>
                              <Col sm="6">
                                <Input type="select" required={true} value={state.newcc_expiration_year} onChange={(e)=>setState({newcc_expiration_year:e.target.value})}>
                                  <option value="">Select expiration year</option>
                                  {
                                    expirationYear.map(
                                      (year,index)=>{
                                        return <option key={index} value={year}>{year}</option>
                                      }
                                    )
                                  }
                                </Input>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <br/>
                        <Card>
                          <CardHeader className="header-color">
                            <i className="fa fa-bank"></i> Billing address
                          </CardHeader>
                          <CardBody style={{background:'white'}}>
                            <Row>
                              <Col sm="8">
                                <Input type="text" required={true} placeholder="Street*" value={state.newcc_street} onChange={(e)=>setState({newcc_street:e.target.value})}/>
                              </Col>
                            </Row>
                            <br/>
                            <Row>
                              <Col sm="4">
                                <Input type="text" required={true} placeholder="City*" value={state.newcc_city} onChange={(e)=>setState({newcc_city:e.target.value})}/>
                              </Col>
                              <Col sm="5">
                                <Input type="select" required={true} value={state.newcc_state} onChange={(e)=>setState({newcc_state:e.target.value})}>
                                  <option value="">Select state</option>
                                  {
                                    state.states.map(
                                      (state,index)=>{
                                        return <option key={index} value={state.key}>{state.value}</option>
                                      }
                                    )
                                  }
                                </Input>
                              </Col>
                              <Col sm="3">
                                <Input type="text" required={true} placeholder="Zip*" value={state.newcc_zip} onChange={(e)=>setState({newcc_zip:e.target.value})}/>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>

                        <br/><br/>

                        <center>
                          <Button style={{width:'350px'}} color="warning" type="submit">Submit</Button>
                        </center>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="1">

                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="1">

                </Col>
                <Col sm="10">
                  <Card>
                    <CardHeader className="header-color">
                      <i className="fa fa-envelope"/> Email URL to borrower
                    </CardHeader>
                    <CardBody>

                      <div className="align-right">
                        Your total is <b><u>$ {formatNumber(state.total)}</u></b>
                      </div>
                      <Row>
                        <Col sm="6">
                          <label>Borrower</label><br/>
                          {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}
                        </Col>
                        <Col sm="6">
                          <label>Email</label><br/>
                          {state.appraisal.borrower_email}
                        </Col>
                      </Row>
                      <br/>
                      <i>We noticed the credit card information for this loan appraisal has not yet been completed. In order to prevent delays, please have the client complete this information by sending the borrower a secured payment link. Just hit the button below and a link will go out immediately.
                        <br/><br/>Note, the credit card will be charged within 24-48 hours upon receipt but if it not received, it may halt delivery of the appraisal report.
                        <br/><br/>Contact your Account Manager if you should have any questions.<br/><br/>Thank you, </i>
                      <font color="red">{state.appraisal.borrower_email===''?"Borrower email is not available, please contact @Home to update the information":''}</font>

                      <br/>
                      <br/>
                      <Button color="warning" style={{width:'98%'}} disabled={state.disabled} onClick={emailBorrower}>Send Secured Payment Link to Borrower</Button>

                      <br/>
                      <br/>
                      <center style={{marginTop:'10px'}}>
                        <b>OR</b>
                      </center>
                      <br/><br/>
                      <i>you can copy the secured link below and share it with the borrower.</i><br/>
                      <i className="link-color fa fa-copy cursor-pointer" onClick={()=>{
                        let tempInput = document.createElement("input");
                        tempInput.value = "https://www.homevms.com/payment/update-credit-card/"+state.id;
                        document.body.appendChild(tempInput);
                        tempInput.select();
                        document.execCommand("copy");
                        document.body.removeChild(tempInput);
                        showMessage('success','Secured link copied to clipboard.')
                      }}/> <NavLink to={"#"} onClick={()=>{
                        let tempInput = document.createElement("input");
                        tempInput.value = "https://www.homevms.com/payment/update-credit-card/"+state.id;
                        document.body.appendChild(tempInput);
                        tempInput.select();
                        document.execCommand("copy");
                        document.body.removeChild(tempInput);
                        showMessage('success','Secured link copied to clipboard.')
                      }}>{"https://www.homevms.com/payment/update-credit-card/"+state.id}</NavLink>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="1">
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
}


export default CreditCard;
