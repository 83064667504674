//Author June Leow
//Date Jul 2nd, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Nav, NavItem, Row } from 'reactstrap';
import { callBackGenerator, formatDateTime, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
//initialize the state
const initialState = {
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0,
    has_credit_card_receivable:'no'
  },
  conditionInternalTickets:[],
  conditionExternalTickets:[],
  conditionActiveTab:'1',
  appraisal:{},
  internalConditionPopUp: false,
  externalConditionPopUp: false,
  entities:[],
  selectedRecipient:-1,
  templates:[],
  internalConditionCommentToAppraiser:'',
  externalConditionCommentToAppraiser:'',
  targetRecipients:[]
};

//reducer function that perform state update
const reducer = getReducer();


const Condition  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getOverallStatus();
    getAppraisal();
    getConditionTickets();
    getTemplates();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state



  //on change function for the select drop down that will populate the text area

  //API call
  const getAppraisal=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({appraisal:response.data.data});

        let entities = [];
        for(let i=0;i<response.data.data.entities.length;i++){
          let entity = response.data.data.entities[i];
          entities.push(entity);
        }
        setState({entities:entities});
      }
    };

    httpGet('appraisal/'+state.id, '' ,'Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  //get all condition tickets
  const getConditionTickets=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let internalTickets = [];
        let externalTickets = [];

        for(let i=0;i<response.data.data.length;i++){
          let condition = response.data.data[i];

          if(condition.is_internal==='yes')
            internalTickets.push(condition);
          else
            externalTickets.push(condition);
        }

        setState({conditionInternalTickets:internalTickets, conditionExternalTickets:externalTickets});
      }
    };

    httpGet('condition/'+state.id,'', 'Oops, something went wrong and could not load the condition for this appraisal. Please try again later.', callBack);
  }

  //get templates
  const getTemplates=()=>{
    const callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet('template/get/'+state.id, '' ,'Oops, something went wrong and could not load your comment template.', callBack);
  }

  //get overall status
  const getOverallStatus=()=>{
    const callBack = apiCallBack([{state:'overallStatus', key:'data'}]);
    httpGet('appraisal/overall/status/'+state.id, '', 'Oops, something went wrong and could not load the information of this appraisal. Please try again later.', callBack);
  }

  //render
  let creditCardTab;

  if(state.overallStatus.has_credit_card_receivable==='yes'){
    creditCardTab = <NavItem>
      <NavLink className="nav-link" to={"/credit-card/"+state.id}>Payment</NavLink>
    </NavItem>
  }

  let appraisalType;

  if(state.appraisal.appraisal_type){

    appraisalType = <div className="display-inline">{state.appraisal.appraisal_type}</div>;
  }

  let internalConditionTickets, externalConditionTickets;

  if(state.conditionInternalTickets.length>0){
    internalConditionTickets = state.conditionInternalTickets.map(
      (ticket,index)=>{
        let icon;
        let ticketCategory = ticket.type;

        if(ticket.type==='Other')
          ticketCategory += " "+ticket.other;

        if(ticket.status==='Completed')
          icon = <i className="fa fa-check green-color"></i>
        else
          icon = <i className="fa fa-clock-o link-color"></i>

        return(
          <div className="ex-margin-bottom" key={ticket.ID}>
            <Card>
              <CardHeader className="gray-color">
                {icon}{" "+(index+1)+". "}{ticketCategory}
              </CardHeader>
              <CardBody>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Submitted</label><br/>
                    {formatDateTime(ticket.datetime_created)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Started</label><br/>
                    {formatDateTime(ticket.datetime_started)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Processed</label><br/>
                    {formatDateTime(ticket.datetime_processed)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Completed</label><br/>
                    {formatDateTime(ticket.datetime_completed)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Requester Name</label><br/>
                    {ticket.requester_name}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Is Internal</label><br/>
                    {ticket.is_internal}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="12" className="low-line-height">
                    <label>Requester Email</label><br/>
                    {ticket.requester_email}
                  </Col>
                </Row>
                <div className="my-divider"></div>
                <div dangerouslySetInnerHTML={{__html:ticket.subject_facts}}/>
              </CardBody>
            </Card>
          </div>
        )
      }
    );
  }

  if(state.conditionExternalTickets.length>0){

    externalConditionTickets = state.conditionExternalTickets.map(
      (ticket,index)=>{
        let icon;
        let ticketCategory = ticket.type;

        if(ticket.type==='Other')
          ticketCategory += " "+ticket.other;

        if(ticket.status==='Completed')
          icon = <i className="fa fa-check green-color"></i>
        else
          icon = <i className="fa fa-clock-o link-color"></i>

        return(
          <div className="ex-margin-bottom" key={ticket.ID}>
            <Card>
              <CardHeader className="gray-color">
                {icon}{" "+index+". "}{ticketCategory}
              </CardHeader>
              <CardBody>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Submitted</label><br/>
                    {formatDateTime(ticket.datetime_created)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Started</label><br/>
                    {formatDateTime(ticket.datetime_started)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Processed</label><br/>
                    {formatDateTime(ticket.datetime_processed)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Completed</label><br/>
                    {formatDateTime(ticket.datetime_completed)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Requester Name</label><br/>
                    {ticket.requester_name}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Is Internal</label><br/>
                    {ticket.is_internal}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="12" className="low-line-height">
                    <label>Requester Email</label><br/>
                    {ticket.requester_email}
                  </Col>
                </Row>
                <div className="my-divider"></div>
                <div dangerouslySetInnerHTML={{__html:ticket.subject_facts}}/>
              </CardBody>
            </Card>
          </div>
        )
      }
    );
  }

  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Condition
          </div>
          <NavLink to="/condition">Back to condition list</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <Nav tabs>
        <NavItem>
          <NavLink className="nav-link" to={"/appraisal/"+state.id}>Appraisal Details</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link active" to="#"><span className="my-badge">{state.overallStatus.condition_count}</span> Condition</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={"/rebuttal/"+state.id}><span className="my-badge">{state.overallStatus.rebuttal_count}</span> Rebuttal</NavLink>
        </NavItem>
        {creditCardTab}
      </Nav>

      <br/>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-home"></i>&nbsp;{state.appraisal.reference_num+" - "+state.appraisal.loan_num}
        </CardHeader>
        <CardBody>
          <Row className="ex-margin-bottom">
            <Col sm="6" className="low-line-height">
              <label>Property Street</label><br/>
              {state.appraisal.property_street}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Property Type</label><br/>
              {state.appraisal.property_type}
            </Col>
          </Row>
          <Row className="ex-margin-bottom ex-margin-top">
            <Col sm="6" className="low-line-height">
              <label>Property City</label><br/>
              {state.appraisal.property_city}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Report Type</label><br/>
              {appraisalType}
            </Col>
          </Row>
          <Row className="ex-margin-bottom ex-margin-top">
            <Col sm="6" className="low-line-height">
              <label>Property State</label><br/>
              {state.appraisal.property_state}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Loan Purpose</label><br/>
              {state.appraisal.loan_purpose}
            </Col>
          </Row>
          <Row className="ex-margin-top">
            <Col sm="6" className="low-line-height">
              <label>Property Zip</label><br/>
              {state.appraisal.property_zip}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Loan Type</label><br/>
              {state.appraisal.loan_type}
            </Col>
          </Row>
        </CardBody>
      </Card>


      <br/>
      <div className="align-right">
        <NavLink to={"/condition/new-condition/"+state.id}>
          <Button color="warning">
            <i className="fa fa-plus green-color"></i> New condition
          </Button>
        </NavLink>
        <br/><br/>
      </div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-home"></i>&nbsp;Condition - ({state.conditionExternalTickets.length+state.conditionInternalTickets.length} tickets)
        </CardHeader>
        <CardBody>
          {internalConditionTickets}
          <br/>
          {externalConditionTickets}
        </CardBody>
      </Card>
    </div>
  );
}


export default Condition;
