//Author June Leow
//Date June 5th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getReducer, getSetStateFunction, postAPICallGenerator, showMessage } from '../../util/util';
import './login.css';

//initialize the state
const initialState = {
  password1:'',
  password2:'',
  isFocused:false,
  showPassword:false,
};

//reducer function that perform state update
const reducer = getReducer();


const ResetPassword  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  let token = useParams().token;
  let email = useParams().email;
  let newInitialState = Object.assign({}, initialState, {
    token:token,
    email:email
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpPostNoToken = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handlePasswordChange = (e) => {
    setState({ password1: e.target.value });
  };

  const handlePasswordFocus = () => {
    setState({ isFocused: true });
  };

  const handlePasswordBlur = () => {
    setState({ isFocused: false });
  };

  const handleToggle = () => {
    setState({ showPassword: !state.showPassword});
  }

  const isPasswordValid = (password, type='') => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCharacters = /[!@#$%^&*]/g;
    const isLowerCaseValid = password.match(lowerCaseLetters);
    const isUpperCaseValid = password.match(upperCaseLetters);
    const isNumberValid = password.match(numbers);
    const isSpecialCharacterValid = password.match(specialCharacters);
    const isLengthValid = password.length >= 12;

    if(type === 'lowercase'){
      return(isLowerCaseValid);
    }else if(type === 'uppercase'){
      return(isUpperCaseValid);
    }else if(type === 'number'){
      return(isNumberValid);
    }else if(type === 'special'){
      return(isSpecialCharacterValid);
    }else if(type === 'length'){
      return(isLengthValid);
    }else{
      return (
        isLowerCaseValid &&
        isUpperCaseValid &&
        isNumberValid &&
        isSpecialCharacterValid &&
        isLengthValid
      );
    }
  };
 
  //API call
  const onSubmit=(e)=>{
    e.preventDefault();

    if(state.password1!==state.password2){
      showMessage('error','Your password does not match.');
    }
    else if(!isPasswordValid(state.password1)){
      showMessage('error','Please match the required format.');
    }
    else if(state.password1===''){
      showMessage('error','Please specify your new password');
    }
    else{
      let parameters = [
        {
          field:'email',
          value:state.email
        },
        {
          field:'token',
          value:state.token
        },
        {
          field:'password',
          value:state.password1
        }
      ];

      const callBack = (response)=>{
        let code = response.data.code;

        if(code==='00')
          showMessage('success', response.data.message+'. Please login.');
        else
          showMessage('error', response.data.message);
        history('/')
      }
      httpPostNoToken('session/resetPassword',parameters, '','',callBack);
    }
  }

  //render
  const isLowerCaseValid = isPasswordValid(state.password1, 'lowercase');
  const isUpperCaseValid = isPasswordValid(state.password1, 'uppercase');
  const isSpecialCharacterValid = isPasswordValid(state.password1, 'special');
  const isNumberValid = isPasswordValid(state.password1, 'number');
  const isLengthValid = isPasswordValid(state.password1, 'length');

  let valid = <i className="fa fa-check-circle"></i>;
  let invalid = <i className="fa fa-times-circle"></i>;

  return(
    <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
      <form name="loginForm" onSubmit={onSubmit}>
        <div className="login-container well padding">
          <br/>
          <center>
            <img alt="company logo" src="/img/logo_small.png" className="logo-login"/><br/>
            Client portal - Forgot password
          </center>
          <div style={{minHeight:'120px',maxHeight:'120px'}}>
            {state.isFocused && (
              <div id="message">
                <label className="font-white">Password must contain the following:</label>
                <p id="letter" style={{lineHeight:'3px'}} className={(isLowerCaseValid&&isUpperCaseValid)? 'green-color' : 'red-color'}>
                  {(isLowerCaseValid&&isUpperCaseValid)? valid:invalid} Combination of uppercase letters & lowercase letters
                </p>
                <p id="number" style={{lineHeight:'3px'}} className={isNumberValid? 'green-color' : 'red-color'}>
                  {isNumberValid? valid:invalid} At least 1 number (0-9)
                </p>
                <p id="special-char" style={{lineHeight:'3px'}} className={isSpecialCharacterValid? 'green-color' : 'red-color'}>
                  {isSpecialCharacterValid? valid:invalid} At least 1 special Chracter (!@#$%^&*)
                </p>
                <p id="length" style={{lineHeight:'3px'}} className={isLengthValid? 'green-color' : 'red-color'}>
                  {isLengthValid? valid:invalid} Minimum <b>12 characters</b>
                </p>
            </div>
            )}
          </div>
          <div className="align-right">
            <NavLink to="/">Back to login</NavLink>
          </div>
          <div className="form-group" style={{height:'38px'}}>
            <label className="font-white">New password:</label>
            <div className="input-container">
              <input className="form-control" type={state.showPassword ? 'text' : 'password'} value={state.password1} onChange={handlePasswordChange}
                onFocus={handlePasswordFocus}
                onBlur={handlePasswordBlur}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number, one special character, one uppercase and lowercase letter, and at least 12 or more characters"
                required
                id="password1"
                name="username" 
                autoComplete="on"
              />
              <span className="toggle-button" style={{color:'#666677'}} onClick={handleToggle}>
                {state.showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
              </span>
            </div>
          </div>
          <br/>
          <label className="font-white">Confirm password:</label>
          <div className="form-group input-group" style={{height:'38px'}}>
            <input type="password"  name="username" autoComplete="on" onChange={e => setState({password2: e.target.value})} className="form-control" id="password2"/>
          </div>
          
          <br/>
          <Button color="warning" className="form-control">Update Password</Button>
          <br/>
        </div>
      </form>
    </div>
  );
}


export default ResetPassword;
