//Author Sooyoung Kim
//Date May 31,2023
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { callBackGenerator, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, putAPICallGenerator, showMessage, getSession} from '../../../util/util';
//import FeedsBar from '../../notification/feeds-bar';
import React, { useEffect, useReducer, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import NotificationContainer from '../../notification/notification-container';
import './top-bar.css';

//initialize the state
const initialState = {
  dropdownOpen: false,
  searchKeyword:'',
  searchCollapse:true,
  searchHistoryOpen:false,
  searchResults:[],
  searching:false,
  id: -1,
  logs:[],
  limit: 20,
  offset: 0,
  loading: false,
  hasMoreLogs:true,

  flagsPopUp:[],
  flaggedPopUp:false,
  announcementPopUp:[],
  announcementPopUpOpen:false
};

//reducer function that perform state update
const reducer = getReducer();


const TopBar  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();
  const dropdownRef = useRef(null);
  const [state, dispatch] = useReducer(reducer,initialState);


  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //profile photo
  let profileUrl = localStorage.getItem('profilePhoto');

  //run only once when component is loaded
  useEffect(()=>{
    getAnnouncement();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(()=>{
    if(state.logs.length<=0 && state.hasMoreLogs && state.searchHistoryOpen){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const toggleFlag=()=>{
    setState({flaggedPopUp:!state.flaggedPopUp});
  }

  const toggleAnnouncement=()=>{
    setState({announcementPopUpOpen:!state.announcementPopUpOpen});
  }

  const onChangeHandler = (e) =>{
    setState({searchKeyword:e.target.value});

    if(e.target.value===''){
      setState({searchCollapse:true,searchResults:[]});
    }
  }

  const keyPressHandler = (e) =>{
    if(e.keyCode === 13){
      setState({searchCollapse:false, searching:true});
      search();
    }
  }

  const resetSearch = () =>{
    setState({searching:false, searchCollapse:true, searchKeyword:'', searchResults:[]});
  }

  //non API call but simpyl manage state
  const toggle = () =>{
    setState({dropdownOpen: !state.dropdownOpen});
  }

  const logoutHandler = () =>{
    let control = {
      showLoading: props.showLoading,
      hideLoading: props.hideLoading,
      logout: props.logout
    };
    props.logout(control);
  }

  //API call
  const getAnnouncement=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({announcementPopUp:response.data.data, announcementPopUpOpen:response.data.data.length>0});
      }
    };
    httpGet('client/announcement/'+getSession().userID, '', '', callBack);
  }

  const search = () =>{
    if(state.message!==''){
      let parameters = [{field:'keyword',value:state.searchKeyword}];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          setState({searchResults:response.data.data, searching:false});

          if(state.searchKeyword.length <= 4){
            showMessage('success','Searched by borrower name for keywords 4 characters or less.');
          }
        }
      };
      callBack = callBack.bind(this);

      let errorCallBack = apiCallBack([{state:'searching', value:false}]);
      httpPost('appraisal/keyword/search', parameters, '', 'Oops, something went wrong and could not search appraisal. Please try again later.', callBack, errorCallBack);
    }
  }

  const loadMore = () =>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreLogs&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});
      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreLogs:false});
        }
        else{
          if(state.searchHistoryOpen){
            let newLogs = response.data.data;
            let hasMoreLogs = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newLogs.length<=0){
              hasMoreLogs = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.logs.length>0){
              let temp = [...state.logs,...newLogs];

              setState({logs:temp});
            }
            else
              setState({logs:newLogs});

            setState({hasMoreLogs:hasMoreLogs, offset:newOffset, totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreLogs', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet('admin/activity/limit='+state.limit+'&offset='+state.offset+'&search=appraisal_fk', '', 'Oops, something went wrong and could not load users. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const updateSeenIndex=()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      console.log(code)
      if(code==='00'){
        toggleAnnouncement();
      }
    };
    httpPut('announcement/seenIndex', [], '', '', callBack);
  }

  //render
  let params = [];
  for(let i=0;i<state.flagsPopUp.length;i++){
    if(state.flagsPopUp[i].indexOf('W9')!==-1)
      params.push('w9=true');
    else if(state.flagsPopUp[i].indexOf('License')!==-1)
      params.push('license=true');
    else if(state.flagsPopUp[i].indexOf('E&O')!==-1)
      params.push('eandoexpiration=true&eando=true');
  }

  params = [];
  //default everything if it glitch
  if(state.flaggedPopUp && params.length<=0){
    params.push('w9=true');
    params.push('license=true');
    params.push('eandoexpiration=true&eando=true');
  }

  let newAnnouncement;
  let newAnnouncementCount = 0;
  if(state.announcementPopUp.length>0){
    newAnnouncement = state.announcementPopUp.map(
      (announcement)=>{

        return(
          <div key={announcement.ID}>
            <div className="card">
              <div className="card-header header-color">
                <Row>
                  <Col sm="6">
                    <b>{announcement.title}</b>
                  </Col>
                  <Col className="align-right">
                    <b>{announcement.creator}&nbsp;-&nbsp;{announcement.datetime_created}</b>
                  </Col>
                </Row>
              </div>

              <div className="card-body">
                <div dangerouslySetInnerHTML={{__html:announcement.body}}/>
              </div>
            </div>
            <br/>
          </div>
        );
      }
    );

    newAnnouncementCount = state.announcementPopUp.length;
  }
  let collapseList;
  let result;

  if(state.searching===true){
    collapseList = <div className="search-container">
      <div className="search-result">
        <b>Searching for appraisal...</b>
      </div>
    </div>;
  }
  else if(state.searchCollapse===false){
    if(state.searchResults.length<=0){
      result = <div className="search-result">
        <b>No appraisal found.</b>
      </div>;
    }
    else {
      result = state.searchResults.map(
        (row, index)=>{
          return <div key={index}>
            <div className="search-result">
              <NavLink to={"/appraisal/"+row.ID} target="_blank" onClick={resetSearch} className="align-left">
                <div className="search-result-header">{row.reference_num} - {row.loan_num} - {row.borrower_f_name+' '+row.borrower_l_name}</div>
                <div className="search-result-details">{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</div>
              </NavLink>
            </div>
          </div>
        }
      );
    }

    collapseList = <div className="search-container">
      {result}
    </div>;
  }else if(state.searchHistoryOpen){
    if(state.logs.length<=0){
      result = <div className="search-result">
      </div>;
    }
    else {
      result = state.logs.map(
        (log, index)=>{
          let message = log.message.split(' - ');

          return <div key={index}>
            <div className="search-result">
              <NavLink to={"/appraisal/"+log.appraisal_fk} target="_blank" onClick={resetSearch} className="align-left">
                <div className="search-result-header">{message[0]} - {message[1]} - {message[2]}</div>
                <div className="search-result-details">{message[3]}</div>
              </NavLink>
            </div>
          </div>
        }
      );
    }

    collapseList = <div ref={dropdownRef} className="search-container smaller">
      <div className="search-history">
        <b>Recently Viewed Orders</b>
      </div>
      <InfiniteScroll
        next={loadMore}
        dataLength={state.logs.length}
        hasMore={state.hasMoreLogs}
        loader={<div key="nill" className="loader"><center>Loading more activity history...</center></div>}
        initialLoad = {true}
        className="my-well"
        scrollableTarget="contentContainer"
      >
      {result}
      </InfiniteScroll>
    </div>;
  }

  let profilePhoto = <i className="fa fa-user topbar-icon"></i>;

  if(profileUrl)
    profilePhoto = <img width="35" src={profileUrl}/>;

  return <div className="topbar">
    <Modal className="my-modal-medium" isOpen={state.announcementPopUpOpen} toggle={toggleAnnouncement} >
      <ModalHeader hidden={true} toggle={toggleAnnouncement}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-bullhorn"></i> New Announcement</h5>
        </center>
        <div>
          <center>
            Hello, we have <font color="red">{newAnnouncementCount}</font> new announcement(s). You can view them <b>below</b> or go to <NavLink to="/announcement"><b>Announcement</b></NavLink>.
          </center>
          <br/>
          <div className="ex-large-scroll-container">{newAnnouncement}</div>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleAnnouncement}>Read Later</Button>&nbsp;
          <Button color="warning" onClick={()=>updateSeenIndex()}>Confirm</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.flaggedPopUp} toggle={toggleFlag} >
      <ModalHeader hidden={true} toggle={toggleFlag}></ModalHeader>
      <ModalBody>
        <br/><br/>
        <center>
          <div style={{height:'350px'}}>
            Hello, Looks like your information/document below is not up to date. Please see items listed below for more information:
            <br/><br/><br/><br/><br/><br/>
            {
              state.flagsPopUp.map(
                (flag, index)=>{
                  return (
                    <div key={index}>
                      <font color="red"><b>{flag}</b></font>
                    </div>
                  )
                }
              )
            }


          </div>
          Please click <NavLink to={"/file/appraiser-file-upload/"+params.join('&')} onClick={toggleFlag}>here</NavLink> to keep your profile up to date.
          <br/><br/>
          <Button color="warning" onClick={toggleFlag}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Row style={{marginLeft:'0px',marginRight:'0px'}}>
      <Col sm="2">
        <div className="topbar-logo-container">
          <NavLink to="/announcement"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
        </div>
      </Col>
      <Col sm="10">
        <div className="align-right">
          <div className="topbar-action-panel" style={{width:'300px'}}>
            <Input type="text" style={{marginTop:'5px'}} onKeyDown={keyPressHandler} placeholder="Search by keyword..." onChange={onChangeHandler} value={state.searchKeyword}/>
            <div className="search-container-parent">{collapseList}</div>
          </div>
          <div className="topbar-action-panel-container">
            <div className="topbar-action-panel">
              <NotificationContainer pusher={props.pusher} logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
            </div>
            <div className="topbar-action-panel">
              <div className="valign-middle cursor-pointer" onClick={()=>history('/support')}><i className="topbar-icon fa fa-question-circle"></i>&nbsp;Support</div>
            </div>
            <div className="topbar-action-panel">
              <ButtonDropdown className="topbar-action-panel-dropdown" isOpen={state.dropdownOpen} toggle={toggle}>
                <DropdownToggle className="topbar-action-panel-dropdown-button" caret>
                  {profilePhoto}&nbsp;&nbsp;{props.userFirstName} {props.userLastName}&nbsp;
                </DropdownToggle>
                <DropdownMenu className="topbar-action-panel-dropdown-menu" end>
                  <DropdownItem className="topbar-action-panel-dropdown-item" onClick={()=>history("/setting")}><i className="fa fa-gear topbar-icon"></i>&nbsp;&nbsp;Settings</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className="topbar-action-panel-dropdown-item" onClick={logoutHandler}><i className="fa fa-power-off topbar-icon"></i>&nbsp;&nbsp;Sign Out</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>;
}

export default TopBar;
