//Author June Yee Leow
//Date Oct 3, 2017
//Reducer for session related operation like login, logout etc
export default function session(state={
  jwtToken: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('rtoken'),
  isLoggedIn: localStorage.getItem('token') ? true : false,
  userRole: '',
  userFirstName: localStorage.getItem('firstName'),
  userLastName: localStorage.getItem('lastName')
}, action){
  let newState = state;
  switch(action.type){
    case "INITIATE_SESSION":
      return state;
    case "LOGIN_SUCCESS":
      let jwtToken = action.payload.token;
      let refreshToken = action.payload.rtoken;
      let firstName = action.payload.first_name;
      let lastName = action.payload.last_name;
      let role = action.payload.role;
      let expire = action.payload.expire;
      let userID = action.payload.user_id;
      let typeID = action.payload.type_id;
      let clientID = action.payload.client_id;
      let clientBranchID = action.payload.client_branch_id;
      let method = action.payload.method;
      let email = action.payload.email;
      let profilePhoto = action.payload.profile_photo;

      console.log(action.payload);
      console.log('test'+method);

      //STORE IT IN LOCAL STORAGE
      localStorage.setItem('profilePhoto', profilePhoto);
      localStorage.setItem('token', jwtToken);
      localStorage.setItem('rtoken', refreshToken);
      localStorage.setItem('firstName', firstName);
      localStorage.setItem('lastName', lastName);
      localStorage.setItem('email', email);
      localStorage.setItem('role', role);
      localStorage.setItem('expire', expire);
      localStorage.setItem('userID', userID);
      localStorage.setItem('typeID', typeID);
      localStorage.setItem('clientID', clientID);
      localStorage.setItem('clientBranchID', clientBranchID);
      localStorage.setItem('method', method);

      newState = Object.assign({},state,{isLoggedIn: true,jwtToken: jwtToken,refreshToken:refreshToken,userFirstName:firstName,userLastName:lastName});

      return newState;
    case "LOGIN_FAILED":
      return newState;
    case "LOGOUT_SUCCESS":
      localStorage.setItem('token', '');
      localStorage.setItem('rtoken', '');
      localStorage.setItem('profilePhoto', '');

      newState = Object.assign({},state,{isLoggedIn: false,jwtToken: '',refreshToken:''});

      return newState;
    case "LOGOUT_FAILED":
      return newState;
    default:
      return state;
  }
}
