//Author June Leow
//Date June 6th, 2024
import React, { useEffect, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { formatDateTime, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
import './dashboard.css';

//initialize the state
const initialState = {
  dashboardActiveTab:'1',
  limit: 25,
  maxTabIndex:'7',

  pendingInspections:[],
  pendingInspectionsLoading:false,
  pendingInspectionsInitialLoad:true,
  pendingInspectionsHasMore:true,
  pendingInspectionsTotalCount:0,
  pendingInspectionsOffset:0,
  pendingInspectionsOrder:'ASC',
  pendingInspectionsSort:'datetime_submitted',

  upcomingInspections:[],
  upcomingInspectionsLoading:false,
  upcomingInspectionsInitialLoad:true,
  upcomingInspectionsHasMore:true,
  upcomingInspectionsTotalCount:0,
  upcomingInspectionsOffset:0,
  upcomingInspectionsOrder:'ASC',
  upcomingInspectionsSort:'datetime_submitted',

  todayInspections:[],
  todayInspectionsLoading:false,
  todayInspectionsInitialLoad:true,
  todayInspectionsHasMore:true,
  todayInspectionsTotalCount:0,
  todayInspectionsOffset:0,
  todayInspectionsOrder:'ASC',
  todayInspectionsSort:'datetime_submitted',

  inspectionCompleted:[],
  inspectionCompletedLoading:false,
  inspectionCompletedInitialLoad:true,
  inspectionCompletedHasMore:true,
  inspectionCompletedTotalCount:0,
  inspectionCompletedOffset:0,
  inspectionCompletedOrder:'ASC',
  inspectionCompletedSort:'datetime_submitted',

  reportsDue:[],
  reportsDueLoading:false,
  reportsDueInitialLoad:true,
  reportsDueHasMore:true,
  reportsDueTotalCount:0,
  reportsDueOffset:0,
  reportsDueOrder:'ASC',
  reportsDueSort:'datetime_submitted',

  completed:[],
  completedLoading:false,
  completedInitialLoad:true,
  completedHasMore:true,
  completedTotalCount:0,
  completedOffset:0,
  completedOrder:'ASC',
  completedSort:'datetime_submitted',

  conditionsToBeHandled:[],
  conditionToBeHandledLoading:false,
  conditionsToBeHandledInitialLoad:true,
  conditionsToBeHandledHasMore:true,
  conditionsToBeHandledTotalCount:0,
  conditionsToBeHandledOffset:0,
  conditionsToBeHandledOrder:'ASC',
  conditionsToBeHandledSort:'datetime_submitted',

  rebuttalsToBeHandled:[],
  rebuttalToBeHandledLoading:false,
  rebuttalsToBeHandledInitialLoad:true,
  rebuttalsToBeHandledHasMore:true,
  rebuttalsToBeHandledTotalCount:0,
  rebuttalsToBeHandledOffset:0,
  rebuttalsToBeHandledOrder:'ASC',
  rebuttalsToBeHandledSort:'datetime_submitted',
};

//reducer function that perform state update
const reducer = getReducer();


const Dashboard  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);
  const history = useNavigate();

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    //LOAD EVERYTHING ONCE
    initialLoadMore(
      'appraisal',
      'todayInspectionsHasMore',
      'todayInspectionsTotalCount',
      'todayInspectionsOffset',
      'todayInspectionsSort',
      'todayInspectionsOrder',
      'todayInspections',
      'today inspections',
      'Today Inspection'
    );

    initialLoadMore(
      'appraisal',
      'upcomingInspectionsHasMore',
      'upcomingInspectionsTotalCount',
      'upcomingInspectionsOffset',
      'upcomingInspectionsSort',
      'upcomingInspectionsOrder',
      'upcomingInspections',
      'upcoming inspections',
      'Upcoming Inspection'
    );

    initialLoadMore(
      'appraisal',
      'pendingInspectionsHasMore',
      'pendingInspectionsTotalCount',
      'pendingInspectionsOffset',
      'pendingInspectionsSort',
      'pendingInspectionsOrder',
      'pendingInspections',
      'pending inspection',
      'Pending Inspection'
    );

    initialLoadMore(
      'appraisal',
      'inspectionCompletedHasMore',
      'inspectionCompletedTotalCount',
      'inspectionCompletedOffset',
      'inspectionCompletedSort',
      'inspectionCompletedOrder',
      'inspectionCompleted',
      'inspection completed',
      'Inspection Completed'
    );

    initialLoadMore(
      'appraisal',
      'reportsDueHasMore',
      'reportsDueTotalCount',
      'reportsDueOffset',
      'reportsDueSort',
      'reportsDueOrder',
      'reportsDue',
      'reports due',
      'Report Due'
    );

    initialLoadMore(
      'condition',
      'conditionsToBeHandledHasMore',
      'conditionsToBeHandledTotalCount',
      'conditionsToBeHandledOffset',
      'conditionsToBeHandledSort',
      'conditionsToBeHandledOrder',
      'conditionsToBeHandled',
      'conditions to be handled',
      'Condition To Be Handled'
    );

    initialLoadMore(
      'rebuttal',
      'rebuttalsToBeHandledHasMore',
      'rebuttalsToBeHandledTotalCount',
      'rebuttalsToBeHandledOffset',
      'rebuttalsToBeHandledSort',
      'rebuttalsToBeHandledOrder',
      'rebuttalsToBeHandled',
      'rebuttals to be handled',
      'Rebuttal To Be Handled'
    );

    initialLoadMore(
      'appraisal',
      'completedHasMore',
      'completedTotalCount',
      'completedOffset',
      'completedSort',
      'completedOrder',
      'completed',
      'completed orders',
      'Completed'
    );

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const renderSortIcon = (type,col)=>{
    if(state[type+'Sort']===col){
      if(state[type+'Order']==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  const renderAppraisal = (appraisals,type)=>{
    return appraisals.map(
      (appraisal,index)=>{
        let ID ;
        if(type==='appraisal')
          ID = appraisal.ID;
        else
          ID = appraisal.appraisal_fk;
        return (
          <tr key={index} onClick={()=>history("/"+type+"/"+ID)}>
            <td><NavLink style={{verticalAlign:'top',padding:'0px',margin:'0px'}} className="link-color" to={"/"+ID}><b>{appraisal.reference_num}</b></NavLink></td>
            <td><b>{formatDateTime(appraisal.datetime_expected)}</b></td>
            <td>{appraisal.loan_num}</td>
            <td>{appraisal.borrower_f_name+' '+appraisal.borrower_l_name}</td>
            <td>{appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}</td>
          </tr>
        );
      }
    );
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (type,col)=>{
    if(state[type+'Sort']===col){
      if(state[type+'Order']==='ASC')
        setState({[type+'Order']:'DESC'});
      else
        setState({[type+'Order']:'ASC'});
    }
    else
      setState({[type+'Sort']:col});
    refreshList(type);
  }

  //this function refresh the list of orders pipeline
  const refreshList = (type)=>{
    setState({[type]:[]});
    setState({[type+'TotalCount']:0});
    setState({[type+'HasMore']:true});
    setState({[type+'Offset']:0});
  }

  //get the tab index by state object name
  const getIndex = (objects)=>{
    switch(objects){
      case 'todayInspections':
        return '4';
      case 'upcomingInspections':
        return '5';
      case 'pendingInspections':
        return '6';
      case 'inspectionCompleted':
        return '7';
      case 'reportsDue':
        return '1';
      case 'conditionsToBeHandled':
        return '2';
      case 'rebuttalsToBeHandled':
        return '3';
      case 'recentUploads':
        return '9';
      case 'completed':
        return '10';
      default:
        return '-1';
    }

  }

  const dashboardActiveTabToggle = (index)=>{
    setState({assignmentsLoading:true,todayInspectionsLoading:true,upcomingInspectionsLoading:true,pendingInspectionsLoading:true,inspectionCompletedLoading:true,reportsDueLoading:true,conditionToBeHandledLoading:true,rebuttalToBeHandledLoading:true,recentUploadsLoading:true,completedLoading:true});

    switch(index){
      case '1':
        setState({assignmentsLoading:false});
        break;
      case '2':
        setState({todayInspectionsLoading:false});
        break;
      case '3':
        setState({upcomingInspectionsLoading:false});
        break;
      case '4':
        setState({pendingInspectionsLoading:false});
        break;
      case '5':
        setState({inspectionCompletedLoading:false});
        break;
      case '6':
        setState({reportsDueLoading:false});
        break;
      case '7':
        setState({conditionToBeHandledLoading:false});
        break;
      case '8':
        setState({rebuttalToBeHandledLoading:false});
        break;
      case '9':
        setState({recentUploadsLoading:false});
        break;
      case '10':
        setState({completedLoading:false});
        break;
      default:

        break;
    }
    setState({dashboardActiveTab:index});
  }

  //API call
  //function for initial call of each tab on the dashboard
  //do not load more until user flip the tab
  const initialLoadMore = (pipeline,hasMore,count,offset,sort,order,objects,label,type)=>{
    let promise = loadMoreHttpCall(pipeline,hasMore,count,offset,sort,order,objects,label,type);

    //auto switch tab if the current tab is empty
    promise.then(

    );
  }

  //http call for fetch more item
  const loadMoreHttpCall = (pipeline,hasMore,count,offset,sort,order,objects,label,type)=>{
    let url = pipeline+'/get/limit='+state.limit+'&offset='+state[offset]+'&order='+state[order]+'&sort='+state[sort]+'&type='+type;

    //callback handler that update the state when http request return
    let callBack = (response)=>{
      let code = response.data.code;
      if(code!=='00'){
        setState({[hasMore]:false});
      }
      else{
        let newItem = response.data.data;
        let hasMoreItem = true;
        let newOffset = state[offset];
        let totalCount = response.data.count;

        //if http request return empty then no more results, end of list
        if(newItem.length<=0){
          hasMoreItem = false;
        }
        else{
          //increment the offset
          newOffset = state[offset] + 1;
        }

        //concat the current array of announcement
        if(state[objects].length>0){
          let temp = [...state[objects],...newItem];

          setState({[objects]:temp});
        }
        else
          setState({[objects]:newItem});

        setState({[hasMore]:hasMoreItem,[offset]:newOffset,[count]:totalCount});
      }
    };

    //error handler when the http request return with error
    let errorCallBack = ()=>{
      //no more fetching data when error occur
      setState({[hasMore]:false});
    };
    let promise = httpGet(url, '','Oops, something went wrong and could not load '+label+'. Please try again later.', callBack, errorCallBack);
    promise.then(
      function(){
        if(state[objects+'InitialLoad'])
          setState({[objects+'InitialLoad']:false});
      }
    )
    return promise;
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = (pipeline,hasMore,loading,count,offset,sort,order,objects,label,type)=>{
    //do not load if there is no more appraisals or it's loading data
    //do not load if the initial load is still on going
    //do not load if this is not the current active tab
    console.log(objects);
    console.log(pipeline);
    console.log(state[objects+'InitialLoad']);
    console.log(state[loading]);
    console.log(state[hasMore]);
    console.log(state.dashboardActiveTab);

    let index = getIndex(objects);
    console.log(index);
    if(state[hasMore]&&!state[loading]&&!state[objects+'InitialLoad']&&state.dashboardActiveTab===index){
      //set loading equals to true so it won't fire off before we are done
      console.log(objects+' loading');
      setState({[loading]:true});

      //collect the promise and wait for it to finish performing it's task
      let promise = loadMoreHttpCall(pipeline,hasMore,count,offset,sort,order,objects,label,type);
      promise
        .then(
          function(){
            //set loading equals to false so the function could be fire off once again
            setState({[loading]:false});
          }
        );
    }
  }

  console.log(state.dashboardActiveTab)

  //render
  return (
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Dashboard
          </div>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>

      <Row>
        <Col sm="3">
          <Nav tabs className="dashboard-pane">
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '1'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('1'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Reports Due
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.reportsDueTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '2'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('2'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Condition Requested
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.conditionsToBeHandledTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '3'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('3'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Rebuttal Requested
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.rebuttalsToBeHandledTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '4'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('4'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Today Inspection
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.todayInspectionsTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '5'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('5'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Upcoming Inspection
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.upcomingInspectionsTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '6'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('6'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Pending Inspection
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.pendingInspectionsTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '7'?"active":"inactive" )}
                onClick={() => { dashboardActiveTabToggle('7'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Inspection Completed
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.inspectionCompletedTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="dashboard-tab last-item">
              <NavLink
                className={"nav-link "+(state.dashboardActiveTab === '10'?"active":"inactive" ) }
                onClick={() => { dashboardActiveTabToggle('10'); }}
                to="#"
              >
                <Row>
                  <Col sm="10">
                    Recent Completed
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <span className="my-badge">{state.completedTotalCount}</span>
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col sm="9" className="no-padding">
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'appraisal',
                          'reportsDueHasMore',
                          'reportsDueLoading',
                          'reportsDueTotalCount',
                          'reportsDueOffset',
                          'reportsDueSort',
                          'reportsDueOrder',
                          'reportsDue',
                          'reports due',
                          'Report Due'
                        );
                      }}
                      hasMore={state.reportsDueHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.reportsDueOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('reportsDue','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reportsDue','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('reportsDue','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('reportsDue','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('reportsDue','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('reportsDue','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('reportsDue','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('reportsDue','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('reportsDue','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('reportsDue','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.reportsDue,'appraisal')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="2">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'condition',
                          'conditionsToBeHandledHasMore',
                          'conditionToBeHandledLoading',
                          'conditionsToBeHandledTotalCount',
                          'conditionsToBeHandledOffset',
                          'conditionsToBeHandledSort',
                          'conditionsToBeHandledOrder',
                          'conditionsToBeHandled',
                          'conditions to be handled',
                          'Condition To Be Handled'
                        );
                      }}
                      hasMore={state.conditionsToBeHandledHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.conditionsToBeHandledOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('conditionsToBeHandled','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('conditionsToBeHandled','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('conditionsToBeHandled','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('conditionsToBeHandled','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('conditionsToBeHandled','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('conditionsToBeHandled','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('conditionsToBeHandled','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('conditionsToBeHandled','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('conditionsToBeHandled','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('conditionsToBeHandled','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.conditionsToBeHandled,'condition')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="3">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'rebuttal',
                          'rebuttalsToBeHandledHasMore',
                          'rebuttalToBeHandledLoading',
                          'rebuttalsToBeHandledTotalCount',
                          'rebuttalsToBeHandledOffset',
                          'rebuttalsToBeHandledSort',
                          'rebuttalsToBeHandledOrder',
                          'rebuttalsToBeHandled',
                          'rebuttals to be handled',
                          'Rebuttal To Be Handled'
                        );
                      }}
                      hasMore={state.rebuttalsToBeHandledHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.rebuttalsToBeHandledOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('rebuttalsToBeHandled','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('rebuttalsToBeHandled','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('rebuttalsToBeHandled','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('rebuttalsToBeHandled','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('rebuttalsToBeHandled','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('rebuttalsToBeHandled','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('rebuttalsToBeHandled','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('rebuttalsToBeHandled','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('rebuttalsToBeHandled','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('rebuttalsToBeHandled','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.rebuttalsToBeHandled,'rebuttal')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="4">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'appraisal',
                          'todayInspectionsHasMore',
                          'todayInspectionsLoading',
                          'todayInspectionsTotalCount',
                          'todayInspectionsOffset',
                          'todayInspectionsSort',
                          'todayInspectionsOrder',
                          'todayInspections',
                          'today inspections',
                          'Today Inspection'
                        );
                      }}
                      hasMore={state.todayInspectionsHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.todayInspectionsOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('todayInspections','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('todayInspections','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('todayInspections','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('todayInspections','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('todayInspections','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('todayInspections','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('todayInspections','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('todayInspections','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('todayInspections','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('todayInspections','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.todayInspections,'appraisal')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="5">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'appraisal',
                          'upcomingInspectionsHasMore',
                          'upcomingInspectionsLoading',
                          'upcomingInspectionsTotalCount',
                          'upcomingInspectionsOffset',
                          'upcomingInspectionsSort',
                          'upcomingInspectionsOrder',
                          'upcomingInspections',
                          'upcoming inspections',
                          'Upcoming Inspection'
                        );
                      }}
                      hasMore={state.upcomingInspectionsHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.upcomingInspectionsOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('upcomingInspections','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('upcomingInspections','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('upcomingInspections','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('upcomingInspections','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('upcomingInspections','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('upcomingInspections','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('upcomingInspections','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('upcomingInspections','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('upcomingInspections','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('upcomingInspections','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.upcomingInspections,'appraisal')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="6">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'appraisal',
                          'pendingInspectionsHasMore',
                          'pendingInspectionsLoading',
                          'pendingInspectionsTotalCount',
                          'pendingInspectionsOffset',
                          'pendingInspectionsSort',
                          'pendingInspectionsOrder',
                          'pendingInspections',
                          'pending inspection',
                          'Pending Inspection'
                        );
                      }}
                      hasMore={state.pendingInspectionsHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.pendingInspectionsOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('pendingInspections','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('pendingInspections','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('pendingInspections','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('pendingInspections','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('pendingInspections','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('pendingInspections','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('pendingInspections','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('pendingInspections','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('pendingInspections','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('pendingInspections','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.pendingInspections,'appraisal')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={state.dashboardActiveTab}>
            <TabPane tabId="7">
              <Row>
                <Col sm="12" style={{paddingRight:'30px'}}>
                  <div className="ex-large-scroll-container">
                    <InfiniteScroll
                      loadMore={()=>{
                        loadMore(
                          'appraisal',
                          'inspectionCompletedHasMore',
                          'inspectionCompletedLoading',
                          'inspectionCompletedTotalCount',
                          'inspectionCompletedOffset',
                          'inspectionCompletedSort',
                          'inspectionCompletedOrder',
                          'inspectionCompleted',
                          'inspection completed',
                          'Inspection Completed'
                        );
                      }}
                      hasMore={state.inspectionCompletedHasMore}
                      loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                      useWindow={false}
                      initialLoad = {true}
                      pageStart={state.inspectionCompletedOffset}
                      className="my-well"
                    >
                      <table className="primary-table">
                        <thead>
                          <tr>
                            <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('inspectionCompleted','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('inspectionCompleted','reference_num')}</th>
                            <th className="cursor-pointer" width="18%" onClick={()=>columnClickHandler('inspectionCompleted','datetime_expected')}><i className="fa fa-calendar"></i>&nbsp;Expected {renderSortIcon('inspectionCompleted','datetime_expected')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('inspectionCompleted','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('inspectionCompleted','loan_num')}</th>
                            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('inspectionCompleted','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('inspectionCompleted','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('inspectionCompleted','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('inspectionCompleted','property_street')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          renderAppraisal(state.inspectionCompleted,'appraisal')
                        }
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="10">
              <div className="ex-large-scroll-container">
                <InfiniteScroll
                  loadMore={()=>{
                    loadMore(
                      'appraisal',
                      'completedHasMore',
                      'completedLoading',
                      'completedTotalCount',
                      'completedOffset',
                      'completedSort',
                      'completedOrder',
                      'completed',
                      'completed orders',
                      'Completed'
                    );
                  }}
                  hasMore={state.completedHasMore}
                  loader={<div key="null" className="loader"><center>Loading more appraisals...</center></div>}
                  useWindow={false}
                  initialLoad = {true}
                  pageStart={state.completedOffset}
                  className="my-well"
                >
                  <table className="primary-table">
                    <thead>
                      <tr>
                        <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('completed','reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('completed','reference_num')}</th>
                        <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('completed','loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('completed','loan_num')}</th>
                        <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('completed','CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('completed','CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
                        <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('completed','property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('completed','property_street')}</th>
                        <th className="cursor-pointer" width="30%" onClick={()=>columnClickHandler('completed','')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('completed','')}</th>
                        <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('completed','datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('completed','datetime_submitted')}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      renderAppraisal(state.completed,'appraisal')
                    }
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>);
}


export default Dashboard;
