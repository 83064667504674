//Author June Leow
//Date Jul 12th, 2024
import React, { useEffect, useReducer } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { formatDateTime, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
import './tracker.css';
//initialize the state
const initialState = {
  appraisal:{},
  popUpOpen: false,
  timeline:[],
  trackerActiveTab:'6',
  appraisalLogs:[],
  conditionRebuttalLogs:[],
  paymentLogs:[],
  shippingLogs:[],
  allLogs:[],
};

//reducer function that perform state update
const reducer = getReducer();


const Tracker  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //toggle function
  const togglePopUp=()=>{
    if(Object.keys(state.appraisal).length===0){
      //extract the ID
      let url = window.location.href;
      let tokens = url.split('/');
      let ID;

      if(url.includes('audit'))
        ID = tokens[tokens.length-3];
      else
        ID = tokens[tokens.length-1];
      setState({id:ID});

      getAppraisal(ID);
      getTrackLog(ID);
    }
    if(state.popUpOpen){
      setState({appraisal:{},timeline:[],appraisalLogs:[],conditionRebuttalLogs:[],paymentLogs:[],shippingLogs:[],allLogs:[]});
    }
    setState({popUpOpen:!state.popUpOpen});
  }

  //toggle function between tab
  const trackerActiveTabToggle=(tab)=>{
    setState({trackerActiveTab:tab});
  }

  const customSort=(a, b)=>{
    if(a.datetime_created<b.datetime_created)
      return -1;
    if(a.datetime_created>b.datetime_created)
      return 1;
    return 0;
  }

  //API call
  //get appraisal
  const getAppraisal=(id)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({appraisal:response.data.data});
        if(response.data.data.special_instructions!=='')
          setState({dropdownOpen:true})
        setState({old_special_instructions:response.data.data.special_instructions});

        //order the date variable for tracker
        let appraisal = response.data.data;

        //submitted date always present
        let timeline = [];
        let temp = {};
        temp.stage = 'Order Submitted';
        temp.date = appraisal.datetime_submitted;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Assignment Accepted';
        temp.date = appraisal.datetime_accepted;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Inspection Scheduled';
        temp.date = appraisal.datetime_scheduled;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Inspection Completed';
        temp.date = appraisal.datetime_inspection_completed;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Review Completed';
        temp.date = appraisal.datetime_review_completed;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Order Delivered';
        temp.date = appraisal.datetime_ship;
        timeline.push(temp);

        setState({timeline:timeline});
      }
    };
    httpGet('appraisal/'+id,'','Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  //get track log
  const getTrackLog=(id)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let appraisalLogs = response.data.data.appraisal;

        let keyword = 'sent assignment to ';
        for(let i=0;i<appraisalLogs.length;i++){
          let index = appraisalLogs[i].message.indexOf(keyword);
          if(index!==-1){
            let str = appraisalLogs[i].message.substring(0, index+keyword.length);
            str +='appraiser.';
            appraisalLogs[i].message = str;
          }
        }

        let conditionRebuttalLogs = response.data.data.condition;
        let paymentLogs = response.data.data.payment;
        let shippingLogs = response.data.data.shipping;
        let allLogs = appraisalLogs.concat(conditionRebuttalLogs).concat(paymentLogs).concat(shippingLogs);

        allLogs.sort(customSort);

        setState({appraisalLogs:appraisalLogs,conditionRebuttalLogs:conditionRebuttalLogs,paymentLogs:paymentLogs,shippingLogs:shippingLogs,allLogs:allLogs});
      }
    };

    httpGet('tracker/'+id, '' , 'Oops, something went wrong and could not load this appraisal\'s tracking information. Please try again later.', callBack);
  }

  //render
  let timeline;
  let appraisalType;

  if(state.appraisal.appraisal_type){
    appraisalType = state.appraisal.appraisal_type;
  }

  if(state.timeline.length>0){
    timeline = state.timeline.map(
      (entry,index)=>{
        let status = '';
        if(entry.date!=='0000-00-00 00:00:00')
          status = 'complete';
        return(
          <li key={index} className={"li "+status}>
            <div className="timestamp">
              <span className="date">
                {formatDateTime(entry.date)}
              </span>
            </div>
            <div className="status">
              <br/>{entry.stage}
            </div>
          </li>
        );
      }
    );
  }

  let appraisalLogs, conditionRebuttalLogs, shippingLogs, allLogs;

  if(state.appraisalLogs.length>0){
    appraisalLogs = state.appraisalLogs.map(
      (appraisalLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(appraisalLog.datetime_created)}</td>
            <td>{appraisalLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.conditionRebuttalLogs.length>0){
    conditionRebuttalLogs = state.conditionRebuttalLogs.map(
      (conditionRebuttalLogs, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(conditionRebuttalLogs.datetime_created)}</td>
            <td>{conditionRebuttalLogs.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.paymentLogs.length>0){
  }

  if(state.shippingLogs.length>0){
    shippingLogs = state.shippingLogs.map(
      (shippingLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(shippingLog.datetime_created)}</td>
            <td>{shippingLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.allLogs.length>0){
    allLogs = state.allLogs.map(
      (shippingLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(shippingLog.datetime_created)}</td>
            <td>{shippingLog.message}</td>
          </tr>
        );
      }
    );
  }

  return(
    <div>
      <div className="cursor-pointer" onClick={togglePopUp}><i className="fa fa-search"></i> Tracker</div>
      <Modal className="my-modal-wide" isOpen={state.popUpOpen} toggle={togglePopUp} >
        <ModalHeader hidden={true} toggle={togglePopUp}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-search"></i> Tracking - {state.appraisal.reference_num}</h5>
          </center>
          <br/>


          <div className="timeline">
            {timeline}
          </div>
          <br/>
          <div>
            <label>Due Date:&nbsp;</label>
            {formatDateTime(state.appraisal.datetime_expected)}<br/>
            <label>Property:&nbsp;</label>
            {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}
            &nbsp;&nbsp;
            <label>Borrower:&nbsp;</label>
            {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}
            &nbsp;&nbsp;
            &nbsp;&nbsp;
            <label>Loan Number:&nbsp;</label>
            {state.appraisal.loan_num}
            &nbsp;&nbsp;
            <label>Property Type:&nbsp;</label>
            {state.appraisal.property_type}
            &nbsp;&nbsp;
            <label>Loan Type:&nbsp;</label>
            {state.appraisal.loan_type}
            &nbsp;&nbsp;
            <label>Report Type:&nbsp;</label>
            {appraisalType}
          </div>
          <br/>
          <Row>
            <Col sm="12">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={"cursor-pointer nav-link "+(state.trackerActiveTab === '6'?"active":"inactive" ) }
                    onClick={() => { trackerActiveTabToggle('6'); }}
                  >
                    All Log <span className="my-badge">{state.allLogs.length}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={"cursor-pointer nav-link "+(state.trackerActiveTab === '1'?"active":"inactive" )}
                    onClick={() => { trackerActiveTabToggle('1'); }}
                  >
                    Appraisal <span className="my-badge">{state.appraisalLogs.length}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={"cursor-pointer nav-link "+(state.trackerActiveTab === '2'?"active":"inactive" )}
                    onClick={() => { trackerActiveTabToggle('2'); }}
                  >
                    Condition/Rebuttal <span className="my-badge">{state.conditionRebuttalLogs.length}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={"cursor-pointer nav-link "+(state.trackerActiveTab === '4'?"active":"inactive" )}
                    onClick={() => { trackerActiveTabToggle('4'); }}
                  >
                    Shipping <span className="my-badge">{state.shippingLogs.length}</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={state.trackerActiveTab}>
                <TabPane tabId="6">
                  <Row>
                    <Col sm="12">
                      <div className="large-scroll-container">
                        <Table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="header-color" width="15%">Date</th>
                              <th className="header-color" >Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allLogs}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="large-scroll-container">
                        <Table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="header-color" width="15%">Date</th>
                              <th className="header-color" >Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {appraisalLogs}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="large-scroll-container">
                        <Table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="header-color" width="15%">Date</th>
                              <th className="header-color" >Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {conditionRebuttalLogs}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <div className="large-scroll-container">
                        <Table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="header-color" width="15%">Date</th>
                              <th className="header-color" >Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shippingLogs}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>

          <br/>
          <center>
            <Button color="info" onClick={togglePopUp}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
    </div>
  );
}


export default Tracker;
