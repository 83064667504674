//Author June Leow
//Date Jul 2nd, 2024
import ImageResize from 'quill-image-resize-module-react';
import React, { useEffect, useReducer } from 'react';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Nav, NavItem, Row } from 'reactstrap';
import { callBackGenerator, formatDate, formatDateTime, formatNumber, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
import './rebuttal.css';


// Register the image resize module
Quill.register('modules/imageResize', ImageResize);

//initialize the state
const initialState = {
  appraisal:{},
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0
  },
  rebuttals:[],
  rebuttalPopUp: false,
  entities:[],
  selectedRecipient:-1,
  templates:[],
  ticketsToSend:[],
  filesToSend:[],
  rebuttalCommentToAppraiser:'',
  appraisalFiles:[],
  targetRecipients:[],
  statuses:[]
};

//reducer function that perform state update
const reducer = getReducer();


const Rebuttal  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    getRebuttal();
    getTemplates();
    getAppraisalFiles();
    getAllRebuttalStatuses();
    getOverallStatus(state.id);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state





  //add all entity associated with this appraisal to target recipients that will receive rebuttal notification

  //remove a recipient from target recipient that will receive rebuttal notification

  //on change function for the select drop down that will populate the text area

  //on change function that update the state to point to the current selected recipient

  //add the selected recipient to target recipient

  //API call
  //get overall status
  const getOverallStatus=()=>{
    const callBack = apiCallBack([{state:'overallStatus', key:'data'}]);
    httpGet('appraisal/overall/status/'+state.id, '', 'Oops, something went wrong and could not load the information of this appraisal. Please try again later.', callBack);
  }
  
  //get appraisal

  const getAppraisal=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({appraisal:response.data.data});

        let entities = [];
        for(let i=0;i<response.data.data.entities.length;i++){
          let entity = response.data.data.entities[i];
          entities.push(entity);
        }

        setState({entities:entities});
      }
    };
    httpGet('appraisal/'+state.id, '' , 'Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  //get all the status of rebuttal for the drop down filter
  const getAllRebuttalStatuses=()=>{
    const callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet('rebuttal/status/get', '', 'Oops, something went wrong and could not retrieve rebuttal statuses.', callBack);
  }

  //get all rebuttal tickets
  const getRebuttal=()=>{
    const callBack = apiCallBack([{state:'rebuttals', key:'data'}]);
    httpGet('rebuttal/'+state.id, '', 'Oops, something went wrong and could not load the rebuttal for this appraisal. Please try again later.', callBack);
  }

  //get templates
  const getTemplates=()=>{
    const callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet('template/get/'+state.id, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getAppraisalFiles=()=>{
    const callBack = apiCallBack([{state:'appraisalFiles', key:'data'}]);
    httpGet('file/get/'+state.id, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //render
  let creditCardTab;

  if(state.overallStatus.has_credit_card_receivable==='yes'){
    creditCardTab = <NavItem>
      <NavLink className="nav-link" to={"/credit-card/"+state.id}>Payment</NavLink>
    </NavItem>
  }

  let appraisalType;

  if(state.appraisal.appraisal_type){

    appraisalType = <div className="display-inline">{state.appraisal.appraisal_type}</div>;
  }

  let rebuttals;

  if(state.rebuttals.length>0){

    rebuttals = state.rebuttals.map(
      (ticket,index)=>{
        let comps;

        if(ticket.comps&&ticket.comps.length>0){
          comps = ticket.comps.map(
            (comp,index)=>{
              return(
                <div key={index}>
                  <b>{index+1}. {comp.street+' '+comp.city+', '+comp.state+' '+comp.zip}</b><br/>
                  <Row className="no-margin no-padding">
                    <Col sm="3" className="low-line-height">
                      <label>Site size</label><br/>
                      {formatNumber(comp.site_size)} sqft
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Living area</label><br/>
                      {formatNumber(comp.sq_footage)} sqft
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Age</label><br/>
                      {formatNumber(comp.age)}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Status</label><br/>
                      {comp.status}
                    </Col>
                  </Row>
                  <Row className="no-margin no-padding">
                    <Col sm="3" className="low-line-height">
                      <label>Source data</label><br/>
                      {comp.source_data}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>APN MLS #</label><br/>
                      {comp.apn_mls_num}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Sale price</label><br/>
                      {formatNumber(comp.sale_price)}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Sale date</label><br/>
                      {formatDate(comp.sale_date)}
                    </Col>
                  </Row>
                  <Row className="no-margin no-padding">
                    <Col sm="12" className="low-line-height">
                      <label>Comment</label><br/>
                      <i>{comp.comment}</i>
                    </Col>
                  </Row>
                  <div className="my-divider"></div>
                </div>
              );
            }
          );
        }

        let icon;

        if(ticket.status==='Completed')
          icon = <i className="fa fa-check green-color"></i>
        else
          icon = <i className="fa fa-clock-o link-color"></i>

        return(
          <div className="ex-margin-bottom" key={ticket.ID}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    {icon}{" "+index+". "} - by {ticket.requester_name}
                  </Col>
                  <Col sm="4">
                    <div className="align-right">
                      {ticket.status}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Submitted</label><br/>
                    {formatDateTime(ticket.datetime_created)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Started</label><br/>
                    {formatDateTime(ticket.datetime_started)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Processed</label><br/>
                    {formatDateTime(ticket.datetime_processed)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Completed</label><br/>
                    {formatDateTime(ticket.datetime_completed)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Requester Name</label><br/>
                    {ticket.requester_name}
                  </Col>
                  <Col sm="6" className="low-line-height">
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="12" className="low-line-height">
                    <label>Requester Email</label><br/>
                    {ticket.requester_email}
                  </Col>
                </Row>
                <div className="my-divider"></div>

                <b><i className="fa fa-home"></i> Comparables</b>
                <div className="large-scroll-container-no-min">
                  {comps}
                </div>

                <div className="my-divider"></div>
                <div dangerouslySetInnerHTML={{__html:ticket.subject_facts}}/>
              
              </CardBody>
            </Card>
          </div>
        )
      }
    );
  }


  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Rebuttal
          </div>
          <NavLink to="/rebuttal">Back to rebuttal list</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>

      <Nav tabs>
        <NavItem>
          <NavLink className="nav-link" to={"/appraisal/"+state.id}>Appraisal Details</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to={"/condition/"+state.id}><span className="my-badge">{state.overallStatus.condition_count}</span> Condition</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link active" to="#"><span className="my-badge">{state.overallStatus.rebuttal_count}</span> Rebuttal</NavLink>
        </NavItem>
        {creditCardTab}
      </Nav>
      <br/>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-home"></i>&nbsp;{state.appraisal.reference_num+" - "+state.appraisal.loan_num}
        </CardHeader>
        <CardBody>
          <Row className="ex-margin-bottom">
            <Col sm="6" className="low-line-height">
              <label>Property Street</label><br/>
              {state.appraisal.property_street}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Property Type</label><br/>
              {state.appraisal.property_type}
            </Col>
          </Row>
          <Row className="ex-margin-bottom ex-margin-top">
            <Col sm="6" className="low-line-height">
              <label>Property City</label><br/>
              {state.appraisal.property_city}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Report Type</label><br/>
              {appraisalType}
            </Col>
          </Row>
          <Row className="ex-margin-bottom ex-margin-top">
            <Col sm="6" className="low-line-height">
              <label>Property State</label><br/>
              {state.appraisal.property_state}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Loan Purpose</label><br/>
              {state.appraisal.loan_purpose}
            </Col>
          </Row>
          <Row className="ex-margin-top">
            <Col sm="6" className="low-line-height">
              <label>Property Zip</label><br/>
              {state.appraisal.property_zip}
            </Col>
            <Col sm="6" className="low-line-height">
              <label>Loan Type</label><br/>
              {state.appraisal.loan_type}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <br/>
      <div className="align-right">
        <NavLink to={"/rebuttal/new-rebuttal/"+state.id}>
          <Button color="warning">
            <i className="fa fa-plus green-color"></i> New rebuttal
          </Button>
        </NavLink>
        <br/><br/>
      </div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-home"></i>&nbsp;Rebuttal - ({state.rebuttals.length} tickets)
        </CardHeader>
        <CardBody>
          {rebuttals}
        </CardBody>
      </Card>
    </div>
  );
}


export default Rebuttal;
