//Author June Leow
//Date Jul 16th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { callBackGenerator, confirmation, deleteAPICallGenerator, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, putAPICallGenerator, sliceFromArray } from '../../util/util';
import MyXEditable from '../util/my-xeditable';
//initialize the state
const initialState = {
  relationships:[],
  relationshipDropDownOpen:false,
  name:'',
  phone:'',
  email:'',
  label:''
};

//reducer function that perform state update
const reducer = getReducer();


const Relationship  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getRelationships();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleAddNewRelationshipDropDown=()=>{
    setState({relationshipDropDownOpen:!state.relationshipDropDownOpen});
  }

  //API call
  const deleteRelationship=(id)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let relationships = sliceFromArray(state.relationships,'ID', id);
        setState({relationships:relationships});
      }
    };
   
    httpDelete('client/relationship/'+id,'Template deleted successfully.','Oops, something went wrong and could not delete the template. Please try again later.', callBack);
  }

  const generateUpdateFunction=(url, parameters, successMessage, failedMessage)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      httpPut(url, parameters, successMessage, failedMessage, callBack, errorCallBack);
    }
  };

  const addNewRelationship=(e)=>{
    e.preventDefault();
    let parameters = [
      {
        field:'label',
        value:state.label
      },
      {
        field:'name',
        value:state.name
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'phone',
        value:state.phone
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let relationships = state.relationships.slice();
        relationships.push(response.data.data);
        setState({relationships:relationships});
        toggleAddNewRelationshipDropDown();
      }
    };

    httpPost('client/relationship/create',parameters, 'Contact created successfully.','Oops, something went wrong and could not create the contact. Please try again later.', callBack);
  }

  const getRelationships=()=>{
    const callBack = apiCallBack([{state:'relationships', key:'data'}]);
    httpGet('client/relationship/get','','Oops, something went wrong and could not load list of contact list. Please try again later.', callBack);
  }

  //render
  let successMessage = 'Contact updated successfully.';
  let failedMessage = 'Failed to update the value of this contact, please try again later.';
  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Contact Auto Populate
          </div>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="#" onClick={toggleAddNewRelationshipDropDown}>Add new contact</NavLink>
          <Modal className="my-modal" isOpen={state.relationshipDropDownOpen} toggle={toggleAddNewRelationshipDropDown} >
            <ModalHeader hidden={true} toggle={toggleAddNewRelationshipDropDown}></ModalHeader>
            <ModalBody>
              <center>
                <h5><i className="fa fa-plus"></i> New contact</h5>
              </center>
              <br/>
              <form onSubmit={addNewRelationship}>
                <Row>
                  <Col sm="4">
                    <label>Label</label>
                  </Col>
                  <Col sm="8">
                    <Input required="true" type="select" onChange={(e)=>setState({label:e.target.value})} value={state.label}>
                      <option value=""></option>
                      <option value="AE">AE</option>
                      <option value="Loan Officer">Loan Officer</option>
                      <option value="Loan Processor">Loan Processor</option>
                      <option value="Broker">Broker</option>
                      <option value="Broker Company">Broker Company</option>
                      <option value="Email Notification Subscriber">Email Notification Subscriber</option>
                      <option value="Other">Other</option>
                    </Input>
                  </Col>
                </Row>
                <Row className="margin-top">
                  <Col sm="4">
                    <label>Name</label>
                  </Col>
                  <Col sm="8">
                    <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
                  </Col>
                </Row>
                <Row className="margin-top">
                  <Col sm="4">
                    <label>Email</label>
                  </Col>
                  <Col sm="8">
                    <Input type="email" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                  </Col>
                </Row>
                <Row className="margin-top">
                  <Col sm="4">
                    <label>Phone</label>
                  </Col>
                  <Col sm="8">
                    <Input required="true" type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
                  </Col>
                </Row>
                <br/>
                <center>
                  <Button color="warning">Add</Button>&nbsp;
                  <Button color="info" onClick={toggleAddNewRelationshipDropDown}>Close</Button>
                </center>
              </form>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
      <div className="my-divider"></div>

      <Card>
        <CardHeader className="my-header">
          <i className="fa fa-user"></i> Contact List
        </CardHeader>
        <CardBody>
          <Table className="table table-striped">
            <tbody>
              {
                state.relationships.map(
                  (relationship, index)=>{
                    return (
                      <tr key={index}>
                        <td><i className="fa fa-times red-color cursor-pointer" onClick={
                          (e)=>{
                            confirmation(
                              ()=>{deleteRelationship(relationship.ID)},
                              ()=>{},
                              'Delete contact?',
                              'Are you sure you want to delete this contact?');
                          }
                        }></i> {relationship.label}</td>
                        <td>
                          <MyXEditable
                            type="text"
                            value={relationship.name}
                            updateFunc={
                              generateUpdateFunction('client/relationship/update',[{field:'field',value:'entity_name'},{field:'ID',value:relationship.ID}],successMessage, failedMessage)
                            }
                          />
                        </td>
                        <td>
                          <MyXEditable
                            type="text"
                            value={relationship.email}
                            updateFunc={
                              generateUpdateFunction('client/relationship/update',[{field:'field',value:'entity_email'},{field:'ID',value:relationship.ID}],successMessage, failedMessage)
                            }
                          />
                        </td><td>
                          <MyXEditable
                            type="text"
                            value={relationship.phone}
                            updateFunc={
                              generateUpdateFunction('client/relationship/update',[{field:'field',value:'entity_email'},{field:'ID',value:relationship.ID}],successMessage, failedMessage)
                            }
                          />
                        </td>
                      </tr>
                    )
                  }
                )
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}


export default Relationship;
