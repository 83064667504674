import Pusher from 'pusher-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import config from '../../config';
import { refreshToken, tokenExpired } from '../../util/util';

const PusherContext = createContext();

function PusherProvider({ pusher, children }) {
  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

// Create custom hook for using the Pusher Context
// Fail fast if not within a PusherProvider (thx Kent C. Dodds)
function usePusher() {
  const context = useContext(PusherContext);
  if (!context) {
    throw new Error("usePusher must be used within a PusherProvider");
  }

  const { pusher } = context;
  return pusher;
}

function usePresencePusher() {
  const [presencePusher, setPresencePusher] = useState(null);

  useEffect(() => {
    let presencePusherInstance = null;

    const initializePresencePusher = async () => {
      if (!presencePusherInstance) {
        if (tokenExpired()) {
          await refreshToken().then(
            function () {
              presencePusherInstance = new Pusher(config.pusherAppKey, {
                authEndpoint: config.baseAPIUrl + 'session/order/presence',
                auth: {
                  params: { url: window.location.href },
                  headers: {
                    Authorization: 'Authorization: Bearer ' + localStorage.getItem('token'),
                  },
                },
                cluster: config.pusherCluster,
              });
              setPresencePusher(presencePusherInstance);
            },
            function () {
              setPresencePusher(null);
            }
          );
        } else {
          presencePusherInstance = new Pusher(config.pusherAppKey, {
            authEndpoint: config.baseAPIUrl + 'session/order/presence',
            auth: {
              params: { url: window.location.href },
              headers: {
                Authorization: 'Authorization: Bearer ' + localStorage.getItem('token'),
              },
            },
            cluster: config.pusherCluster,
          });
          setPresencePusher(presencePusherInstance);
        }
      }
    };

    initializePresencePusher();

    return () => {
      if (presencePusherInstance) {
        presencePusherInstance.disconnect();
      }
    };
  }, []);

  return presencePusher;
}

export { PusherProvider, usePresencePusher, usePusher };
