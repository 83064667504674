//Author June Leow
//Date June 5th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime, formatNumber, generateSID} from '../../util/util';
import React, {useReducer, useEffect, useRef} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReportFilter  from '../report/report-filter';
import {Row, Col} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import './appraisal.css';

//initialize the state
const initialState = {
  appraisals:[],
  limit: 25,
  offset: 0,
  hasMoreAppraisals: true,
  loading: false,
  sort: 'datetime_submitted',
  order: 'ASC',
  status: 'Submitted',
  client: '',
  borrower:'',
  state:'',
  county:'',
  accountManager:'',
  city:'',
  street:'',
  loan_num:'',
  file_num:'',
  entity_name:'',
  appraiser_name:'',
  pipelineStatusCount:[],
  priority:'',
  rush:'',
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();


const Appraisals  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPipelineStatusCount();
    getAllClientProfiles();

    return ()=> {
      console.log('Aborting request');controller.abort();console.log('Abort called');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.appraisals.length<=0 && state.hasMoreAppraisals){
      loadMore();
      getPipelineStatusCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //non API call but simpyl manage state
  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    let SID = generateSID();
    newsid.current = SID; 
    setState({
      appraisals:[],
      totalCount:0,
      hasMoreAppraisals:true,
      offset:0,
    });
    getPipelineStatusCount();
  }

  const setStatus = (status) => {
    if(status==='Completed'){
      setState({sort:'datetime_submitted', order:'DESC'});
    }
    setState({status:status,sort:''});
    refreshList();
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  const renderAppraisals = ()=>{
    let extraColumn;

    if(state.role==='appraiser_master')
      extraColumn = <th width="10%" ><i className="fa fa-user"></i>&nbsp;Appraiser</th>


    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reference_num')}</th>
              {extraColumn}
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('loan_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" onClick={()=>columnClickHandler('')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('datetime_submitted')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisals.map(
              (appraisal)=>{
                let extraColumnData;

                if(state.role==='appraiser_master')
                  extraColumnData = <td>{appraisal.appraiser}</td>

                  let icon;
                  let iconChecks = [];

                  if(appraisal.rush==='Rush')
                    iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge2">RUSH</span> </div>);
                  if(appraisal.loan_type==='FHA')
                    iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge2">FHA</span> </div>);

                    icon = <div className="display-inline">
                      {iconChecks.map(
                        (jsx, index)=>{ return <div className="display-inline" key={index}>{jsx}&nbsp;</div>;}
                      )}
                    </div>;
                return (
                  <tr key={appraisal.ID} onClick={()=>window.open("appraisal/"+appraisal.ID)}>
                    <td><NavLink className="link-color" onClick={(e)=>{e.preventDefault();}} to={"/appraisal/"+appraisal.ID}><b>{appraisal.reference_num}<br/>{icon}</b></NavLink></td>
                    {extraColumnData}
                    <td>{appraisal.loan_num}</td>
                    <td>{appraisal.borrower_f_name+' '+appraisal.borrower_l_name}</td>
                    <td>{appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}</td>
                    <td>
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {appraisal.company}
                      <br/>
                      <i className="fa fa-tags icon-color"></i>&nbsp;
                      {appraisal.appraisal_type}
                      <br/>
                      <i className="fa fa-home icon-color"></i>&nbsp;
                      {appraisal.property_type}
                      <br/>
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {appraisal.loan_purpose}
                    </td>
                    <td>{formatDateTime(appraisal.datetime_submitted)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more appraisals or it's loading data
    if(state.hasMoreAppraisals&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});
      let sID = newsid.current;

      let borrower = encodeURIComponent(state.borrower.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let loan_num = encodeURIComponent(state.loan_num.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let street = encodeURIComponent(state.street.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let url = 'appraisal/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&client='+state.client+'&borrower='+borrower+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client;
      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        ///console.log(response);
        //console.log('sID :'+sID+' vs current sID: '+state.sID);
        if(sID===newsid.current){
          if(code!=='00'){
            setState({hasMoreAppraisals:false});
          }
          else{
            let newAppraisals = response.data.data;
            let hasMoreAppraisals = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newAppraisals.length<=0){
              hasMoreAppraisals = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.appraisals.length>0){
              let temp = [...state.appraisals,...newAppraisals];

              setState({appraisals:temp});
            }
            else
              setState({appraisals:newAppraisals});

            setState({hasMoreAppraisals:hasMoreAppraisals, offset:newOffset, totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisals', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisals. Please try again later.' + sID+' .', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get pipeline status count
  const getPipelineStatusCount = () => {
    let borrower = encodeURIComponent(state.borrower.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let loan_num = encodeURIComponent(state.loan_num.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let street = encodeURIComponent(state.street.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

    let url = 'appraisal/pipeline/count/status='+state.status+'&client='+state.client+'&borrower='+borrower+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client;
    let callBack = apiCallBack([{state:'pipelineStatusCount', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the information of your pipeline. Please try again later.', callBack);
  }

  const getAllClientProfiles = () => {
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
	  httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  //render
  let pipelineStatusCounts;

  if(state.pipelineStatusCount.length>0){
    pipelineStatusCounts = state.pipelineStatusCount.map(
      (pipelineStatus,index)=>{
        let redTab;
        let redBadge;

        if(pipelineStatus.status==='Hold'){
          if(state.status===pipelineStatus.status)
            redTab = {
              background:'#ff6666',
              color:'white'
            };
          else
            redTab = {
              background:'#ffcccc',
            };
          redBadge = {
            background:'#ff1a1a'
          };
        }
        return(
          <div key={index} style={redTab} className={"pipeline-stage"+(pipelineStatus.status===state.status?" active":"")} onClick={()=>setStatus(pipelineStatus.status)}>
            <span className="my-badge3" style={redBadge}>{pipelineStatus.count}</span> {pipelineStatus.status}
          </div>
        )
      }
    );
  }

  let reportFiltersConfig = [
    {id:'orderStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'3', labelClassName:"no-margin-bottom orange-color"},
    {id:'custom',value:state.loan_num, updateFunc:(v)=>setState({loan_num:v}), width:'3', label:'Loan # / Reference #'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3', label:'Lender'},
    {id:'custom',value:state.borrower, updateFunc:(v)=>setState({borrower:v}), width:'3', label:'Borrower'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'custom',value:state.street, updateFunc:(v)=>setState({street:v}), width:'3', label:'Street'},
    {id:'button',value:'Submit', updateFunc:refreshList, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <InfiniteScroll
      className="my-well"
      dataLength={state.appraisals.length} //This is important field to render the next data
      next={loadMore}
      hasMore={state.hasMoreAppraisals}
      loader={<center>Loading more appraisals...</center>}
      scrollableTarget="contentContainer"
    >
      <div className="page-title">
        <Row>
          <Col sm="8">
            <i className="fa fa-reorder"></i>&nbsp;Appraisals
          </Col>
          <Col sm="4" className="align-right">
      
          </Col>
        </Row>
      </div>
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      <div className="my-divider"></div>
      <Row className="low-line-height">
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} orders in queue<br/><br/></div>
        </Col>
        <Col sm="6">
          
        </Col>
      </Row>
      <div>
        {pipelineStatusCounts}
      </div>
      {renderAppraisals()}
    </InfiniteScroll>
  </div>;
}


export default Appraisals;
