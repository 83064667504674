//Author June Leow
//Date Jul 15th, 2024
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showMessage } from '../../util/util';
//initialize the state

const AccountActivation  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  //wrapper function

  //run only once when component is loaded
  useEffect(()=>{
    showMessage('success', 'Your account activation process is now completed. Please login again.');
    props.logout();
    history('/');

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //render
  return <div>
    Your account activation process is now completed. Please login again.
  </div>;
}


export default AccountActivation;
