//Author June Leow
//Date Nov 15th, 2023
import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { login } from '../../actions/action-login';
import { getReducer, getSetStateFunction, showMessage } from '../../util/util';
import './login.css';
//initialize the state
const initialState = {
  email:'',
  password:''
};

//reducer function that perform state update
const reducer = getReducer();


const Login  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const onSubmit = (e)=>{
    e.preventDefault();
    console.log(state.password)
    if(state.password===''){
      showMessage('error', 'Please provide your password.');
    }
    else if(state.email===''){
      showMessage('error', 'Please provide your email.');
    }
    else{
      let credential = {
        email: state.email,
        password: state.password
      }
      let control = {
        showLoading: props.showLoading,
        hideLoading: props.hideLoading,
        logout: props.logout,
        noToken:true
      };
      props.login(credential,control);
    }
  }

  //render 
  return(
    <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
      <form name="loginForm" onSubmit={onSubmit}>
        <div className="login-container well padding">
          <br/>
          <center>
            <img alt="company logo" src="/img/logo_small.png" className="logo-login"/><br/>
            Client portal
          </center>
          <br/>
          <div className="align-right">
            <NavLink to="/forgot-password">Forgot password</NavLink>
          </div>
          <div className="form-group">
            <label className="font-white">Email:</label>
            <input type="email"  name="username" autoComplete="on" onChange={e => setState({email: e.target.value})} className="form-control" id="email"/>
          </div>
          <br/>
          <label className="font-white">Password:</label>
          <div className="form-group input-group">
            <input type="password" name="password" autoComplete="on" onChange={e => setState({password: e.target.value})} className="form-control" id="password"/>
            <span className="input-group-addon" style={{height:'38px'}}>
              <NavLink to="/forgot-password"><i className="fa fa-question hover-pointer"></i></NavLink>
            </span>
          </div>
          <br/>
          <center>
          <Button color="warning" className="form-control">Sign In</Button>
          </center>
          <br/>
        </div>
      </form>
    </div>
  );
}

//REDUX COMPONENT
function mapStateToProps(state){
  return {
    jwtToken: state.session.jwtToken,
    isLoggedIn: state.session.isLoggedIn,
    userRole: state.session.userRole,
    userFirstName: state.session.userFirstName,
    userLastName: state.session.userLastName
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    login: login
  },dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(Login);
