//Author Sooyoung Kim
//Date Nov 15, 2023
import React, { useEffect, useReducer, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledTooltip } from 'reactstrap';
import { callBackGenerator, formatDateTime, getAPICallGenerator, getReducer, getSetStateFunction, hideAuditMessage, postAPICallGenerator, showAuditMessage, showMessage } from '../../util/util';
import { usePusher } from '../pusher/pusher-context';
import './notification.css';

//initialize the state
let userID = localStorage.getItem('userID');

const initialState = {
  unreadNotifications:[],
  notifications:[],
  dropdownOpen: false,
  audits:[],
  userID:userID,
  auditsMap:{},
  userHasInteracted:false
};

//reducer function that perform state update
const reducer = getReducer();


const NotificationContainer  = (props)=>{
  const controller = new AbortController();
  const userHasInteractedRef = useRef(false);
  const history = useNavigate();
  const pusher = usePusher();


  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    const handleScroll = () => {
      if(!userHasInteractedRef.current){
        userHasInteractedRef.current = true;
      }
    };

    const handleClick = () => {
      if(!userHasInteractedRef.current){
        userHasInteractedRef.current = true;
      }
    };
    
    getNotifications();

    // Add event listener
    const myDiv = document.getElementById('contentContainer');
    if (myDiv) {
      myDiv.addEventListener('scroll', handleScroll);
    }
    

    // Add event listener
    window.addEventListener('click', handleClick);

    return ()=> {
      controller.abort();
      if(myDiv)
        myDiv.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //run only once when component is loaded
  useEffect(() => {
    // Subscribe to a channel and bind to events using the pusher instance
    if(pusher){
      let my_channel = pusher.subscribe('private-general-channel');
      my_channel.bind('new-message', (data) => {
        try{
          let parsedData = JSON.parse(data.data.data);
  
          if(data.data.user_fk===state.userID){
            let notifications = state.notifications.slice();
            let unreadNotifications = state.unreadNotifications.slice();
            let notificationID = data.data.ID;
  
            unreadNotifications.unshift(notificationID);
            notifications.unshift(data.data);
  
            setState({notifications:notifications, unreadNotifications:unreadNotifications});
  
            showMessage('notification',parsedData.message,parsedData.from,parsedData.link);
          }
          //for all
          else if(data.data.user_fk===-1){
            switch(data.data.type){
              case 'new-order':
                if(userHasInteractedRef.current){
                  let audio = new Audio('/notify.wav');
                  audio.play();
                }
                break;
               default:
                 break;
            }
          }
        }
        catch(error){
  
        }
      });

      try{
        let notification = localStorage.getItem('notification');
        notification = JSON.parse(notification);
        for(let channel in notification){
  
          if(pusher){
            let my_channel = pusher.subscribe('private-'+channel);
  
            for(let i=0;i<notification[channel].length;i++){
              if(channel==='accounting-audit'){
                if(notification[channel][i]==='new-audit'){
                  my_channel.bind(notification[channel][i], (data) => {
                      if(data.author_user_fk!==state.userID){
                        let auditsMapToToastID = Object.assign({},state.auditsMap);
                        let id = showAuditMessage(data);
                        auditsMapToToastID[data.ID] = id;
                        setState({auditsMap:auditsMapToToastID});
                      }
                    }
                  );
                }
                else if(notification[channel][i]==='audit-approved'){
                  my_channel.bind(notification[channel][i], (data) => {hideAuditMessage(state.auditsMap[data.ID]);}
                  );
                }
              }
            }
          }
        }
      }
      catch(err){
        console.log(err);
      }
  
  
      // Clean up the subscription when the component is unmounted
      return () => {
        my_channel.unbind('new-message');
  
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusher]);

  //non API call but simpyl manage state
  const toggle = () => {
    setState({dropdownOpen: !state.dropdownOpen});
  }

  //API call
  //get all the notifications
  const getNotifications = () => {
    let callBack = apiCallBack([{state:'notifications', key:'data.notifications'}, {state:'unreadNotifications', key:'data.unread_notifications'}]);
    httpGet('notification/get/limit=50&offset=0', '', 'Oops, something went wrong and could not load your notifications. Please try again later.', callBack);
  }

  //mark notification as read
  const markAsRead = (id) => {
    let parameters = [
      {
        field:'ID',
        value:id
      }
    ];

    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let notifications = state.notifications.slice();
        for(let i=0;i<notifications.length;i++){
          if(notifications[i].ID===id){
            notifications[i].status='read';
            break;
          }
        }
        setState({notifications:notifications});
      }
    };
    callBack = callBack.bind(this);

    httpPost('notification/read', parameters, '', '', callBack);
  }

  //mark the last seen notification index
  const markLastSeenNotification = () => {

    if(state.unreadNotifications.length>0){
      //the first one always the newest notification with largest index
      let index = state.unreadNotifications[0];
      let parameters = [
        {
          field:'index',
          value:index
        }
      ];


      let callBack = (response)=>{
        let code = response.data.code;

        if(code==='00'){
          let unreadNotifications = state.unreadNotifications.slice();
          let cutOffIndex = unreadNotifications.indexOf(index);

          if(cutOffIndex!==-1){
            unreadNotifications.splice(cutOffIndex,(unreadNotifications.length-cutOffIndex));
            setState({unreadNotifications:unreadNotifications});
          }
        }
      };
      callBack = callBack.bind(this);

      httpPost('notification/seen', parameters, '', '', callBack);
    }
  }

  //render
  let notifications;
  if(state.notifications.length>0){
    notifications = state.notifications.map(
      (notification,index)=>{
        let icon = <i className="fa fa-info-circle topbar-icon"></i>;
        let link = '#';
        let data = notification.data;
        let readClass = '';

        if(data&&data!==''){
          try{
            data = JSON.parse(notification.data);
            if(data.type&&data.type!==''){
              switch(data.type){
                case 'order-updates':
                  icon = <i className="fa fa-tasks topbar-icon"></i>;
                  link = '/appraisal/'+data.ID;
                  break;
                case 'order-comments':
                  icon = <i className="fa fa-commenting topbar-icon"></i>;
                  link = '/appraisal/'+data.ID;
                  break;
                case 'notifications':
                  icon = <i className="fa fa-information topbar-icon"></i>;
                  link = data.link;
                  break;
                default:
                  break;
              }
            }
          }
          catch(err){
            console.log(err);
          }
        }

        let readControlIcon = <i className="fa fa-check green-color" onClick={(e)=>{e.preventDefault();e.stopPropagation();markAsRead(notification.ID)}}></i>;

        if(notification.status==='unread'){
          readClass = ' unread-notification';
          readControlIcon =
            <div>
              <i className="fa fa-ellipsis-h topbar-icon" id={"notification-control-icon"+notification.ID} onClick={(e)=>{e.preventDefault();e.stopPropagation();markAsRead(notification.ID)}}></i>
              <UncontrolledTooltip placement="right" target={"notification-control-icon"+notification.ID}>
                mark as read
              </UncontrolledTooltip>
            </div>;
        }

        return(
            <div key={index} className={"notification-bar-item"+readClass}>
              <NavLink to={link} onClick={(e)=>{markAsRead(notification.ID)}}>
                <div className="notification-bar-item-title">
                  {icon} {notification.title}
                </div>
                <div className="notification-bar-item-content">
                  {notification.message}
                </div>
                <Row className="no-padding no-margin">
                  <Col sm="7" className="no-padding no-margin">
                    <div className="notification-bar-item-date">
                      {formatDateTime(notification.datetime_created)}
                    </div>
                  </Col>
                  <Col sm="5" className="no-padding no-margin">
                    <div className="notification-control-icon align-right">
                      {readControlIcon}
                    </div>
                  </Col>
                </Row>
              </NavLink>
            </div>
        );
      }
    );
  }

  return <div>
    <ButtonDropdown className="topbar-action-panel-dropdown" isOpen={state.dropdownOpen} toggle={toggle}>
      <DropdownToggle className="topbar-action-panel-dropdown-button">
        <span className="my-badge" onClick={markLastSeenNotification}>+{state.unreadNotifications.length}</span>
      </DropdownToggle>
      <DropdownMenu className="topbar-action-panel-dropdown-menu" end>
        <DropdownItem className="topbar-action-panel-dropdown-item no-padding">
          <div className="large-scroll-container-no-min notification-bar">
            {notifications}
          </div>
        </DropdownItem>
        <DropdownItem className="topbar-action-panel-dropdown-item" onClick={()=>history("/notification")}><i className="fa fa-info-circle topbar-icon"></i>&nbsp;&nbsp;View all notifications</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  </div>;
}


export default NotificationContainer;
