//Author June Leow
//Date June 10th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Button,Input,Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap';
import './comment.css';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  comments: [],
  newComment:'',
  modal: false,
  commentTemplates:[],
  submitDisabled:false
};

//reducer function that perform state update
const reducer = getReducer();


const Comment  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalComments();
    getCommentTemplates();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const buttonHandler=()=>{
    if(state.submitDisabled)
      return;
    if(state.newComment==='')
      return;

    setState({submitDisabled:true},postComment());
  }


  const toggle=()=>{
    setState({
      modal: !state.modal
    });
  }

  const onChange=(e)=>{
    setState({newComment:e.target.value});
  }

  //on change function listen for change on the select drop down of comment templates
  //populate the textarea onchange
  const commentTemplatesOnChange=(e)=>{
    let id = e.target.value;

    let template;

    for(let i=0;i<state.commentTemplates.length;i++){
      if(state.commentTemplates[i].ID===id){
        template = state.commentTemplates[i];
        break;
      }
    }

    if(template){
      setState({newComment:template.template});
    }
  }


  //API call
  //get all the comments
  const getAppraisalComments=()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        response.data.data.reverse();
        setState({
          comments:response.data.data,
        });
      }
    };
    httpGet('comment/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load comments for this appraisal. Please try again later.', callBack);
  }

  //get all the comment templates
  const getCommentTemplates=()=>{
    const callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
    httpGet('template/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }


  
  const postComment=()=>{
    let parameters = [
      {
        field:'comment',
        value:state.newComment
      },
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      },
      {
        field:'to_send_entities',
        value:state.targetRecipients
      }
    ];

    let callBack = (response)=>{
      setState({submitDisabled:false});
      let code = response.data.code;
      if(code==='00'){
        console.log(response.data.data)
        //insert new comment into state
        let commentCreated = response.data.data;
        let existingComments = state.comments.slice();
        existingComments.unshift(commentCreated);
        setState({comments:existingComments, newComment:''});
      }
    };
    httpPost('comment/create', parameters, 'Comment posted successfully.', 'Oops, something went wrong and could not post this comment. Please try again later.', callBack);
    toggle();
  }

  //render
  let templates;

  if(state.commentTemplates){
    templates = state.commentTemplates.map(
      (template)=>{
        return(
          <option key={template.ID} value={template.ID}>{template.name}</option>
        );
      }
    );
  }

  let comments = state.comments.map(
    (comment)=>{
      let outterContainerClass = 'align-right';
      let commentContainerClass = 'nonadmin-comment-container';
      let alignClass = 'align-left';
      let alignClass2 = 'align-right';
      let dateClass = 'margin-top comment-date display-inline';
      let nameAddon = '';

      if(comment.internal==='yes'){
        outterContainerClass = '';
        commentContainerClass = 'admin-comment-container';
      }

      let namePortion = <div className={(commentContainerClass==='admin-comment-container'?alignClass:alignClass2)+" display-inline"}>
        <div className="display-inline">
        
        </div>
        &nbsp;
        <div className="display-inline"><b>{nameAddon+comment.author}</b></div>
      </div>;



      let datePortion = <Row>
        <Col sm="5" className="align-left">
        </Col>
        <Col sm="7">
          <div className={dateClass}><i className="fa fa-reply cursor-pointer"/> {formatDateTime(comment.datetime_created)}</div>
        </Col>
      </Row>;

      
      let avatar = <div className="align-right display-inline">
        <i className="fa fa-circle-user" style={{fontSize:'30px'}}/>
      </div>;

      if(comment.author_profile_photo && comment.author_profile_photo!==''){
        avatar = <div className="align-right display-inline">
        <img src={comment.author_profile_photo} width="30px"/>
      </div>;
      }

      let header = <div>
        {namePortion} {avatar} 
      </div>

      if(commentContainerClass==='admin-comment-container'){
        namePortion = <div className="display-inline">
        <div className="display-inline">{nameAddon+comment.author}</div>
      </div>;

        datePortion = <Row>
          <Col sm="7">
            <div className={dateClass+" "+(commentContainerClass==='admin-comment-container'?alignClass:alignClass2)}><i className="fa fa-reply cursor-pointer"/> {formatDateTime(comment.datetime_created)}</div>
          </Col>
          <Col sm="5" className="align-right">
          </Col>
        </Row>;

        header = <div>
          {avatar} {namePortion} 
          </div>
      }

      return(
        <div key={comment.ID}>
          <div className={(commentContainerClass==='admin-comment-container'?alignClass:alignClass2)} style={{marginBottom:'-5px'}}>
            {header}
          </div>
          <Row className={outterContainerClass+" "+(commentContainerClass==='admin-comment-container'?alignClass:alignClass2)}>
            <Col sm="12">
              <div className={commentContainerClass+(comment.ID===state.commentID?" highlightit":"")}>
                <div className="display-inline" dangerouslySetInnerHTML={{ __html: comment.comment }} />
                {datePortion}
              </div>
            </Col>
          </Row>
          <br/>
        </div>
        
      );
    }
  );
  
  return(
    <div>
      <div className="comments-panel">
        {comments}
      </div>
      <div className="my-diviver"></div>
      <div className="align-right">
        <br/>
        <Button color="warning" className="cursor-pointer" onClick={toggle}><i className="fa fa-plus"></i> Post a message to @Home</Button>
        <Modal className="my-modal" isOpen={state.modal} toggle={toggle} >
          <ModalHeader hidden={true} toggle={toggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-comments"></i> New Comment</h5>
            </center>
            <br/>
            <div className="form-group">
              <span><label>Comment:</label></span>
              <Input type="textarea" className="form-control comment-textarea" value={state.newComment} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="8" onChange={onChange}></Input>
            </div>

            <Row>
              <Col sm="6">
                <label>Template:</label>
              </Col>
              <Col sm="6" className="align-right">
                <NavLink to="/setting">Manage my template</NavLink>
              </Col>
            </Row>
            <br/>

            <select className="form-control" onChange={commentTemplatesOnChange}>
              <option value=""></option>
              {templates}
            </select>

            <br/>
            <center>
              <Button color="warning" disabled={state.submitDisabled!==false} onClick={buttonHandler} ><i className="fa fa-check"></i>&nbsp;Post</Button>{' '}
              <Button color="info" onClick={toggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}


export default Comment;
