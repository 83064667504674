//Author June Leow
//Date Jul 17th, 2024
import valid from "card-validator";
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';
import ReactDOM from 'react-dom';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { NavLink, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { callBackGenerator, formatNumber, generateTooltip, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator } from '../../util/util';
import MyDropzone from '../util/my-dropzone';
import MyFormStep from '../util/my-form-step';
import MyPlacesAutocomplete from '../util/my-places-autocomplete';
//initialize the state
const initialState = {
  self:{},
  paymentMethod:'Authorize.net',
  appraisalTypeFilters:{
    '2 Units':['1004','1073','1075','2055'],
    '3 Units':['1004','1073','1075','2055'],
    '4 Units':['1004','1073','1075','2055'],
    'Low Rise Condo':['1004','1025','2055'],
    'Mid-Rise Condo':['1004','1025','2055'],
    'High Rise Condo':['1004','1025','2055'],
    'Detached Condo':['1004','1025','2055'],
    'Manufactured Housing':['1073','1075'],
    'SFR - Detached':['1073','1025','1075'],
    'SFR - Attached':['1073','1025','1075'],
    'PUD - Attached':['1073','1075'],
    'PUD - Detached':['1073','1075'],
  },
  standAlone:[
    'Rent Survey (1007)',
    'Operating Income Statement (216)',
    'Appraisal Update / Re-Cert (1004D)',
    'Appraisal Final Inspection (1004D / HUD 92051)',
    'Appraisal Disaster Inspection In & Out (1004D)',
    'Drive By Conv Condo (1075)',
    'Drive By Conv SFR (2055)',
    'Exterior (2075)',
    'Enhanced Desk Review',
    'Field Review',
    'Other',
  ],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  apn:'',
  orderType:'',
  occupants:[{key:'Owner-Occupied', value:'Owner-Occupied'},{key:'Tenant-Occupied',value:'Tenant-Occupied'},{key:'Vacant',value:'Vacant'}],
  loanTypes:[],
  loanPurposes:[],
  availableAppraisalTypes:[],
  availableAppraisalTypesFiltered:[],
  propertyTypes:[],
  clients:[],
  associatedClient:[],
  clientBranches:[],
  openByClientBranches:[],
  clientEntities:[],
  appraisalType:'',
  subForms:[],
  subForm: false,
  expectedDate:'',
  contacts:[],

  propertyStreet:'',
  propertyCity:'',
  propertyState:'',
  propertyCounty:'',
  propertyZip:'',
  propertyType:'',
  address:'',
  occupant:'',

  specialInstructions:'',
  specialInstructionsForAMC:'',

  propertyAccessTitle:'',
  propertyAccessName:'',
  propertyAccessEmail:'',
  propertyAccessHomePhone:'',
  propertyAccessWorkPhone:'',
  propertyAccessMobilePhone:'',

  loanNumber:'',
  loanAmount:'',
  estimatedValue:'',
  loanType:'',
  loanPurpose:'',
  salePrice:'',
  fhaNumber:'',

  entities:['AE','Broker','Loan Officer','Loan Processor','Broker Company','Listing Agent','Borrower','Other','Email Notification Subscriber','Payment Contact'],
  selectedNewEntity:'',
  newEntityDropDownOpen:false,
  toUploadFiles:[],
  isRush:'Non-rush',

  steps:['Property Information','Appraisal & Loan Information','Contact Information','Additional Information','Payment','Review'],
  maxStep:0,
  currentStep:0,

  ccNumberError:'',
  ccNumberValid:true,
  ccTypeError:'',
  ccTypeValid:true,

  newcc_first_name:'',
  newcc_last_name:'',
  newcc_email:'',
  newcc_card_number:'',
  newcc_cvc:'',
  newcc_expiration_year:'',
  newcc_expiration_month:'',
  newcc_street:'',
  newcc_city:'',
  newcc_state:'',
  newcc_zip:'',
  newcc_card_type:'',
  coveredBy:'',

  errorMessage:'',
  submitDisabled: false,

  fileTypesToUpload:[],
  descriptionsToUpload:[],
  fileTypes:[],

  searchOrder:'',
  searchOrders:[],

  resizeFilePopUp:false,
  bigFiles:[],

  sendLink:false,
  paymentLinkEmailAddress:'',
  paymentLinkEmailName:'',

  borrowerFirstName:'',
  borrowerLastName:'',
  borrowerEmail:'',
  borrowerPhone:'',
  borrowerWorkPhone:'',
  borrowerMobilePhone:'',
  copyBorrowerInfo:false,

  pdrSellerID:'',
  pdrLenderID:'',
  pdrLPAKey:'',
};

//reducer function that perform state update
const reducer = getReducer();


const NewAppraisal  = (props)=>{
  const controller = new AbortController();

  const history = useNavigate();

  let role = localStorage.getItem('role');
  let userID = localStorage.getItem('userID');
  let client = 0;
  let clientBranch = 0;
  let email = localStorage.getItem('email');

  if(role==='client'||role==='client_branch_master'||role==='client_master'){
    client = localStorage.getItem('clientID');
    clientBranch = localStorage.getItem('clientBranchID');
  }

  let newInitialState = Object.assign({}, initialState, {
    userID:userID,
    email:email,
    role:role,
    openBy:client?client:0,
    openByBranch:clientBranch?clientBranch:0,
    client:client?client:0,
    clientBranch:clientBranch?clientBranch:0,
    defaultCloseBy:client?client:0,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.role!=='delegator'){
      getClient();
      getWholesaleAssociates();
    }
    else
      getDelegatorAssociate();
    getPropertyTypes();
    getLoanTypes();
    getFileTypes();
    getLoanPurposes();
    getAppraisalTypes();
    if(role==='client'||role==='client_branch_master'||role==='client_master'){
      getClientBranches(client);
      getOpenByClientBranches(client);
    }
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.submitDisabled){
        createNewAppraisal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.submitDisabled]);

  useEffect(()=>{ 
    validateCCNumber();
  },[state.newcc_card_number])

  useEffect(()=>{ 
    validateCCType();
  },[state.newcc_card_type])


  //non API call but simpyl manage state
  const getClient=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let client =response.data.data.default_close_by!=='0'?response.data.data.default_close_by:state.openBy;
        console.log({key:state.openBy,value:response.data.data.company})
        //setState({client:client, paymentMethod:response.data.data.payment_type});
        setState({defaultCloseBy:client,paymentMethod:response.data.data.payment_type,self:{key:state.openBy,value:response.data.data.company}});

        if(state.orderType!=='')
          orderTypeChanged(state.orderType)
      }
    };

    httpGet('client/'+state.openBy,'','Oops, something went wrong and could not load client information. Please try again later.', callBack);
  }

  const getWholesaleAssociates=()=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){

        let associatedClient = [];
        let self = {};
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          if(client.ID===state.openBy){
            self = {key:client.ID, value:client.company};
          }

          let tmp = {};
          tmp.key = client.associated_client_fk;
          tmp.value = client.company;

          associatedClient.push(tmp);
        }


        setState({associatedClient:associatedClient});
      }
    };

    httpGet('client/wholesale/associate/'+state.client,'','Oops, something went wrong and could not load client information. Please try again later.', callBack);
  }

  const orderTypeChanged=(orderType)=>{
    setState({orderType:orderType});
    getClientContacts(orderType, state.client);
    if(orderType==='Retail'&&state.client!==state.openBy){
      setState({client:state.openBy});
      getClientBranches(state.openBy);
    }
    else if(orderType==='Wholesale'){
      if(state.associatedClient.length===1){
        setState({client:state.associatedClient[0].key});
        getClientBranches(state.associatedClient[0].key);
        getClientContacts(orderType, state.associatedClient[0].key);
      }
      else {
        setState({client:state.defaultCloseBy});
        getClientBranches(state.defaultCloseBy);
      }
    }
  }

  const createNewAppraisal=()=>{
    let url = 'appraisal/create';
    let successMsg = 'Appraisal created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the appraisal. Please try again later.';

    //add new appraiser call back
    let errorCallBack = ()=>{
      setState({submitDisabled:false});
    };
    let callBack = (response)=>{
      setState({submitDisabled:false});

      let code = response.data.code;
      if(code!=='00'){
        setState({submitDisabled:false});
      }
      else{
        history('/appraisal');
      }
    };

    let clientEntities=[];
    for(let i=0;i<state.clientEntities.length;i++){
      let tmp = {
        label:state.clientEntities[i].label,
        name:state.clientEntities[i].name.label,
        email:state.clientEntities[i].email,
        phone:state.clientEntities[i].phone,
        mobilePhone:state.clientEntities[i].mobilePhone,
        workPhone:state.clientEntities[i].workPhone,
      };

      clientEntities.push(tmp);
    }


    if(state.toUploadFiles.length>0){
      let files = [];
      for(let i=0;i<state.toUploadFiles.length;i++){
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result?reader.result:reader.content;
          let base64 = btoa(fileAsBinaryString);

          let tmp = {};
          tmp.base64 = base64;
          tmp.name = state.toUploadFiles[i].name;

          files.push(tmp);
          if(files.length>=state.toUploadFiles.length){
            let parameters =[
              {
                field:'specialInstructions',
                value:state.specialInstructions
              },
              {
                field:'sendLink',
                value:state.sendLink
              },
              {
                field:'paymentLinkEmailAddress',
                value:state.paymentLinkEmailAddress
              },
              {
                field:'paymentLinkEmailName',
                value:state.paymentLinkEmailName
              },
              {
                field:'orderType',
                value:state.orderType
              },
              {
                field:'estimatedValue',
                value:state.estimatedValue
              },
              {
                field:'specialInstructionsForAMC',
                value:state.specialInstructionsForAMC
              },
              {
                field:'propertyAccessName',
                value:state.propertyAccessName
              },
              {
                field:'propertyAccessTitle',
                value:state.propertyAccessTitle
              },
              {
                field:'propertyAccessEmail',
                value:state.propertyAccessEmail
              },
              {
                field:'coveredBy',
                value:state.coveredBy
              },
              {
                field:'propertyType',
                value:state.propertyType
              },
              {
                field:'appraisalType',
                value:state.appraisalType
              },
              {
                field:'entities',
                value:clientEntities
              },
              {
                field:'propertyAccessWorkPhone',
                value:state.propertyAccessWorkPhone
              },
              {
                field:'propertyAccessHomePhone',
                value:state.propertyAccessHomePhone
              },
              {
                field:'propertyAccessMobilePhone',
                value:state.propertyAccessMobilePhone
              },
              {
                field:'salePrice',
                value:state.salePrice
              },
              {
                field:'propertyStreet',
                value:state.propertyStreet
              },
              {
                field:'propertyCity',
                value:state.propertyCity
              },
              {
                field:'propertyCounty',
                value:state.propertyCounty
              },
              {
                field:'propertyState',
                value:state.propertyState
              },
              {
                field:'propertyZip',
                value:state.propertyZip
              },
              {
                field:'pdrSellerID',
                value:state.pdrSellerID
              },
              {
                field:'pdrLenderID',
                value:state.pdrLenderID
              },
              {
                field:'pdrLPAKey',
                value:state.pdrLPAKey
              },
              {
                field:'loanNumber',
                value:state.loanNumber
              },
              {
                field:'loanAmount',
                value:state.loanAmount
              },
              {
                field:'fhaNumber',
                value:state.fhaNumber
              },
              {
                field:'loanPurpose',
                value:state.loanPurpose
              },
              {
                field:'loanType',
                value:state.loanType
              },
              {
                field:'occupant',
                value:state.occupant
              },
              {
                field:'apn',
                value:state.apn
              },
              {
                field:'client',
                value:state.client
              },
              {
                field:'clientBranchFk',
                value:state.clientBranch
              },
              {
                field:'openBy',
                value:state.openBy
              },
              {
                field:'openByClientBranch',
                value:state.openByBranch
              },
              {
                field:'isRush',
                value:state.isRush
              },
              {
                field:'borrowerFirstName',
                value:state.borrowerFirstName
              },
              {
                field:'borrowerLastName',
                value:state.borrowerLastName
              },
              {
                field:'borrowerEmail',
                value:state.borrowerEmail
              },
              {
                field:'borrowerPhone',
                value:state.borrowerPhone
              },
              {
                field:'borrowerWorkPhone',
                value:state.borrowerWorkPhone
              },
              {
                field:'borrowerMobilePhone',
                value:state.borrowerMobilePhone
              },
              {
                field:'lat',
                value:state.lat
              },
              {
                field:'lng',
                value:state.lng
              },
              {
                field:'billing_first_name',
                value:state.newcc_first_name
              },
              {
                field:'billing_last_name',
                value:state.newcc_last_name
              },
              {
                field:'email',
                value:state.newcc_email
              },
              {
                field:'card_number',
                value:state.newcc_card_number
              },
              {
                field:'card_type',
                value:state.newcc_card_type
              },
              {
                field:'cvc',
                value:state.newcc_cvc
              },
              {
                field:'expiration_month',
                value:state.newcc_expiration_month
              },
              {
                field:'expiration_year',
                value:state.newcc_expiration_year
              },
              {
                field:'billing_street',
                value:state.newcc_street
              },
              {
                field:'billing_city',
                value:state.newcc_city
              },
              {
                field:'billing_state',
                value:state.newcc_state
              },
              {
                field:'billing_zip',
                value:state.newcc_zip
              }
            ];

            if(state.expectedDate!==''){
              let tmp = {
                field:'expectedDate',
                value:state.expectedDate
              }

              parameters.push(tmp);
            }

            let tmp = {
              field:'files',
              value: files
            }

            parameters.push(tmp);

            tmp = {
              field:'fileTypes',
              value: state.fileTypesToUpload
            }

            parameters.push(tmp);

            tmp = {
              field:'descriptions',
              value: state.descriptionsToUpload
            }

            parameters.push(tmp);
            console.log(parameters);
            httpPost(url, parameters, successMsg, errorMsg, callBack, errorCallBack);
          }
        };

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          props.showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
    }
    else{
      let parameters =[
        {
          field:'specialInstructions',
          value:state.specialInstructions
        },
        {
          field:'sendLink',
          value:state.sendLink
        },
        {
          field:'paymentLinkEmailAddress',
          value:state.paymentLinkEmailAddress
        },
        {
          field:'paymentLinkEmailName',
          value:state.paymentLinkEmailName
        },
        {
          field:'orderType',
          value:state.orderType
        },
        {
          field:'estimatedValue',
          value:state.estimatedValue
        },
        {
          field:'specialInstructionsForAMC',
          value:state.specialInstructionsForAMC
        },
        {
          field:'propertyAccessName',
          value:state.propertyAccessName
        },
        {
          field:'propertyAccessTitle',
          value:state.propertyAccessTitle
        },
        {
          field:'propertyAccessEmail',
          value:state.propertyAccessEmail
        },
        {
          field:'coveredBy',
          value:state.coveredBy
        },
        {
          field:'propertyType',
          value:state.propertyType
        },
        {
          field:'appraisalType',
          value:state.appraisalType
        },
        {
          field:'entities',
          value:clientEntities
        },
        {
          field:'propertyAccessWorkPhone',
          value:state.propertyAccessWorkPhone
        },
        {
          field:'propertyAccessHomePhone',
          value:state.propertyAccessHomePhone
        },
        {
          field:'propertyAccessMobilePhone',
          value:state.propertyAccessMobilePhone
        },
        {
          field:'salePrice',
          value:state.salePrice
        },
        {
          field:'propertyStreet',
          value:state.propertyStreet
        },
        {
          field:'propertyCity',
          value:state.propertyCity
        },
        {
          field:'propertyCounty',
          value:state.propertyCounty
        },
        {
          field:'propertyState',
          value:state.propertyState
        },
        {
          field:'propertyZip',
          value:state.propertyZip
        },
        {
          field:'pdrSellerID',
          value:state.pdrSellerID
        },
        {
          field:'pdrLenderID',
          value:state.pdrLenderID
        },
        {
          field:'pdrLPAKey',
          value:state.pdrLPAKey
        },
        {
          field:'loanNumber',
          value:state.loanNumber
        },
        {
          field:'loanAmount',
          value:state.loanAmount
        },
        {
          field:'fhaNumber',
          value:state.fhaNumber
        },
        {
          field:'loanPurpose',
          value:state.loanPurpose
        },
        {
          field:'loanType',
          value:state.loanType
        },
        {
          field:'client',
          value:state.client
        },
        {
          field:'clientBranchFk',
          value:state.clientBranch
        },
        {
          field:'openBy',
          value:state.openBy
        },
        {
          field:'openByClientBranch',
          value:state.openByBranch
        },
        {
          field:'isRush',
          value:state.isRush
        },
        {
          field:'borrowerFirstName',
          value:state.borrowerFirstName
        },
        {
          field:'borrowerLastName',
          value:state.borrowerLastName
        },
        {
          field:'borrowerEmail',
          value:state.borrowerEmail
        },
        {
          field:'billing_first_name',
          value:state.newcc_first_name
        },
        {
          field:'billing_last_name',
          value:state.newcc_last_name
        },
        {
          field:'email',
          value:state.newcc_email
        },
        {
          field:'borrowerPhone',
          value:state.borrowerPhone
        },
        {
          field:'borrowerWorkPhone',
          value:state.borrowerWorkPhone
        },
        {
          field:'borrowerMobilePhone',
          value:state.borrowerMobilePhone
        },
        {
          field:'lat',
          value:state.lat
        },
        {
          field:'lng',
          value:state.lng
        },
        {
          field:'card_number',
          value:state.newcc_card_number
        },
        {
          field:'card_type',
          value:state.newcc_card_type
        },
        {
          field:'cvc',
          value:state.newcc_cvc
        },
        {
          field:'expiration_month',
          value:state.newcc_expiration_month
        },
        {
          field:'expiration_year',
          value:state.newcc_expiration_year
        },
        {
          field:'billing_street',
          value:state.newcc_street
        },
        {
          field:'billing_city',
          value:state.newcc_city
        },
        {
          field:'billing_state',
          value:state.newcc_state
        },
        {
          field:'billing_zip',
          value:state.newcc_zip
        }
      ];

      if(state.expectedDate!==''){
        let tmp = {
          field:'expectedDate',
          value:state.expectedDate
        }

        parameters.push(tmp);
      }
      console.log(parameters);
      httpPost(url, parameters, successMsg, errorMsg, callBack, errorCallBack);
    }
  }

  const updatePaymentLinkEmailInfo=(email)=>{
    let clientEntities = state.clientEntities.slice();
    let name = '';

    if(email===state.borrowerEmail){
      name = state.borrowerFirstName+' '+state.borrowerLastName;
    }
    else{
      for(let i=0;i<clientEntities.length;i++){
        if(clientEntities[i].email===email){
          name = clientEntities[i].name.value;
        }
      }
    }

    setState({paymentLinkEmailName:name, paymentLinkEmailAddress:email});
  }

  const ableSendLink=(sendLink)=>{
    setState({sendLink:sendLink})
  }

  const inputChange=(keyword, label, index)=>{
    if(keyword!==''){
      let options = state[label+'optionsOri']?state[label+'optionsOri'].slice():[];

      let notFound = true;
      for(let i=0;i<options.length;i++){
        if(options[i].label.indexOf(keyword)!==-1)
          notFound = false;
      }

      if(notFound){
        options.push({label:keyword, value:{name:keyword,email:'',phone:''}});
        setState({[label+'options']:options});
        updateEntityInfo(index,'name',{label:keyword, value:keyword});
      }
    }
  }

  const handleChange=(selectedOption, index)=>{

    let clientEntities = state.clientEntities.slice();
    clientEntities[index].name = {label:selectedOption.value.name, value:selectedOption.value.name};
    clientEntities[index].email = selectedOption.value.email;
    clientEntities[index].phone = selectedOption.value.phone;

    setState({clientEntities:clientEntities});
  }


  const clearEmptyContact=()=>{
    let clientEntities = state.clientEntities.slice();

    let tmp = [];
    let needAutoSubmit = false;
    for(let i=0;i<clientEntities.length;i++){
      console.log(clientEntities[i])
      if(clientEntities[i].name&&clientEntities[i].name.value===''&&clientEntities[i].email===''&&clientEntities[i].phone===''){
        needAutoSubmit = true;
      }
      else {
        tmp.push(clientEntities[i]);
      }
    }

    setState({clientEntities:tmp});
    if(needAutoSubmit){
      setState({maxStep:3});
      updateStep(3);
    }
  }

  const copyPropertyAccessContactInfo=()=>{
    if(!state.copyPropertyAccessContactInfo){
      let names = state.propertyAccessName.split(' ');
      let borrowerFirstName = '';
      let borrowerLastName = '';

      if(names.length>0){
        borrowerFirstName = names[0];
        borrowerLastName = state.propertyAccessName.replace(names[0]+' ','');
      }
      setState(
        {
          borrowerFirstName:borrowerFirstName,
          borrowerLastName:borrowerLastName,
          borrowerWorkPhone:state.propertyAccessWorkPhone,
          borrowerPhone:state.propertyAccessHomePhone,
          borrowerMobilePhone:state.propertyAccessMobilePhone,
          borrowerEmail:state.propertyAccessEmail,
          paymentLinkEmailName:borrowerFirstName+' '+borrowerLastName,
          paymentLinkEmailAddress:state.propertyAccessEmail
        }
      )
    }

    setState({copyPropertyAccessContactInfo:!state.copyPropertyAccessContactInfo})
  }

  const standAlone=(appraisalType)=>{
    let appraisalTypes = state.standAlone.slice();

    for(let i=0;i<appraisalTypes.length;i++){
      if(appraisalTypes[i].includes(appraisalType))
        return true;
    }

    return false;
  }

  const updateLoanType=(loanType)=>{
    let filteredAppraisalTypes = [];

    if(loanType==='FHA'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('FHA'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='USDA'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('USDA'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo <$1m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('<$1m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo $1m - $2m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo >$2m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.property_type)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('>$2m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Conventional'||loanType==='High Balanced'||loanType==='Private'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(!state.availableAppraisalTypes[i].appraisal_type.includes('FHA')&&!state.availableAppraisalTypes[i].appraisal_type.includes('USDA')&&!state.availableAppraisalTypes[i].appraisal_type.includes('<$1m')&&!state.availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')&&!state.availableAppraisalTypes[i].appraisal_type.includes('>$2m'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Other'){
      let excludes = [
        '1004',
        '1073',
        '1025',
        'FHA',
        'USDA',
        'Jumbo'
      ];

      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;

        let exclude = false;
        for(let j=0;j<excludes.length;j++){
          if(state.availableAppraisalTypes[i].appraisal_type.includes(excludes[j])){
            //futher check if it's 1004D
            if(state.availableAppraisalTypes[i].appraisal_type.includes('1004D')){
              if(state.availableAppraisalTypes[i].appraisal_type.includes('FHA')||state.availableAppraisalTypes[i].appraisal_type.includes('USDA')){
                exclude = true;
                break;
              }
            }
            else{
              exclude = true;
              break;
            }
          }
        }

        if(exclude===false)
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else{
      filteredAppraisalTypes = state.availableAppraisalTypes.slice();
    }

    if(loanType!=='')
      setState({availableAppraisalTypesFiltered:filteredAppraisalTypes});
    else
      updatePropertyType(state.propertyType);
  }

  const propertyTypeCheck=(appraisalType,propertyType)=>{
    let excludeTypes = state.appraisalTypeFilters[propertyType];

    for(let j=0;excludeTypes&&j<excludeTypes.length;j++){

      if(appraisalType.includes(excludeTypes[j])){
        if(appraisalType.includes('1004D'))
          return true;
        else
          return false;
      }
    }
    return true;
  }

  const updatePropertyType=(propertyType)=>{
    let filteredAppraisalTypes = [];

    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type,propertyType)===true)
        filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
    }

    setState({availableAppraisalTypesFiltered:filteredAppraisalTypes});
  }

  const buttonHandler=(e)=>{
    e.preventDefault();
    if(state.submitDisabled)
      return;
    setState({submitDisabled:true});
  }


  const updateEntityInfo=(index, field, value)=>{
    let clientEntities = state.clientEntities.slice();
    let target = Object.assign({},clientEntities[index]);
    target[field] = value;

    clientEntities.splice(index, 1, target);

    setState({clientEntities:clientEntities});
  }


  const handleNewAppraisalTypeChange=(appraisalType)=>{
    setState({appraisalType:appraisalType});
    setState({subForms:[]});
    setState({subForm:false});

    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(state.availableAppraisalTypes[i].appraisal_type===appraisalType&&state.availableAppraisalTypes[i].combo==='yes'){
        setState({subForm:true});
        break;
      }
    }
  }

  const formatFileSize = (size) => {
    const intSize = parseInt(size, 10);
    if (intSize >= 1000000) {
        return formatNumber(Math.round((intSize * 10) / 1000000) / 10) + ' MB';
    } else if (intSize >= 1000) {
        return formatNumber(Math.round((intSize * 10) / 1000) / 10) + ' KB';
    } else {
        return formatNumber(intSize) + ' B';
    }
  };

  const addNewClientEntity=()=>{
    let clientEntities = state.clientEntities.slice();
    let tmp = {};

    if(state.selectedNewEntity!=='Other'){
      tmp.label = state.selectedNewEntity;
      tmp.predefined = true;
    }
    else
      tmp.label = '';
    tmp.name = {label:'',value:''};
    tmp.phone = '';
    tmp.workPhone = '';
    tmp.mobilePhone = '';
    tmp.email = '';

    if(state.selectedNewEntity==='Co-Borrower'||state.selectedNewEntity==='Borrower')
      clientEntities.unshift(tmp);
    else
      clientEntities.push(tmp);

    setState({clientEntities:clientEntities});
    toggleNewEntityDropDownOpen();
  }

  const toggleNewEntityDropDownOpen=()=>{
    setState({newEntityDropDownOpen:!state.newEntityDropDownOpen});
  }

  const toggleResizeFile=()=>{
    if(state.resizeFilePopUp)
      setState({bigFiles:[]});
    setState({resizeFilePopUp: !state.resizeFilePopUp});
  }

  const removeClientEntity=(index)=>{
    let clientEntities = state.clientEntities.slice();
    clientEntities.splice(index,1);

    setState({clientEntities:clientEntities});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile=(preview)=>{
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  const onFileTypeChange = (name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //constructing a new file object
  const deepCopyFileObject = (file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;
    newFile.description = file.description;

    return newFile
  }

  const onFileDescriptionChange = (name, description)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];
        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);
          if(j===i)
            newFile.description = description;

          newToUploadFiles.push(newFile);
        }
        console.log(newToUploadFiles)
        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop=(acceptedFiles)=>{
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles;

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      setState({resizeFilePopUp:true, bigFiles:bigFiles});
    }
    setState({toUploadFiles: existingFiles});
  }

  //do checking on each step
  const submitForm=(e)=>{
    console.log('form submit');
    e.preventDefault();

    switch(state.currentStep){
      case 0:

        break;
      case 1:

        break;
      case 2:

        break;
      case 3:

        break;
      default:

        break;
    }

    if(state.currentStep===3){
      let pass = true;

      let fileTypes = [];
      let descriptions = [];
      let errorMessage = '';

      for(let i =0; i<state.toUploadFiles.length;i++){
        let value = state.toUploadFiles[i].fileType;
        let description = state.toUploadFiles[i].description;

        if(!value||value===''){
          pass = false;
          fileTypes.push('');
          errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
          setState({errorMessage:errorMessage});
        }
        else
          fileTypes.push(value);
        descriptions.push(description);
      }
      setState({fileTypesToUpload:fileTypes});
      setState({descriptionsToUpload:descriptions});

      if(pass===true){
        handleStepAdvance();
      }
    }
    else if(state.currentStep===4){
      if(state.newcc_card_number!==''){
        let pass = true;
        if(state.newcc_cvc===''){
          pass = false;
          setState({errorMessage:'Please provide the CVC number.'});
        }
        if(state.newcc_expiration_year===''){
          pass = false;
          setState({errorMessage:'Please provide the card expiration year.'});
        }
        if(state.newcc_expiration_month===''){
          pass = false;
          setState({errorMessage:'Please provide the card expiration month.'});
        }
        if(state.newcc_card_type===''){
          pass = false;
          setState({errorMessage:'Please provide the card type.'});
        }
        if(state.newcc_first_name===''){
          pass = false;
          setState({errorMessage:'Please provide the card holder first name.'});
        }
        if(state.newcc_last_name===''){
          pass = false;
          setState({errorMessage:'Please provide the card holder last name.'});
        }
        if(state.coveredBy===''){
          pass = false;
          setState({errorMessage:'Please specify the card holder.'});
        }
        if(state.newcc_street===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing street.'});
        }
        if(state.newcc_city===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing city.'});
        }
        if(state.newcc_state===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing state.'});
        }
        if(state.newcc_zip===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing zip.'});
        }

        if(pass===true)
          handleStepAdvance();
        else{
          if(ReactDOM.findDOMNode(this)&&ReactDOM.findDOMNode(this).parentElement)
            ReactDOM.findDOMNode(this).parentElement.scrollTop = 0;
        }
      }
      else
        handleStepAdvance();
    }
    else
      handleStepAdvance();
  }

  const handleStepAdvance=()=>{
    let step;

    setState({errorMessage:''});

    if(ReactDOM.findDOMNode(this)&&ReactDOM.findDOMNode(this).parentElement)
      ReactDOM.findDOMNode(this).parentElement.scrollTop = 0;

    if(state.currentStep===3){
      if(state.paymentMethod==='Authorize.net'){
        step = state.currentStep+1;
      }
      else{
        step = state.currentStep+2;
      }
    }
    else{
      step = state.currentStep+1;
    }

    //step 3 then finish, send create appraisal request
    //if(step===6)
      //createNewAppraisal();
    //else advance the step
    if(step!==6){
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
  }

  const updateStep=(step)=>{
    if(step<=state.maxStep)
      setState({currentStep:step});
  }


  const googlePlaceOnChange=(address)=>{
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        setState({lat:null});
        setState({lng:null});

        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let county = '';
              let state = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                  county = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  state = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              //get long lat
              getLatLng(targetResult)
                .then(
                  result=>{
                    if(result){
                      let lat = result.lat;
                      let lng = result.lng;


                      setState({lat:lat});
                      setState({lng:lng});
                    }
                  }
                )
                .catch(
                  error=>{
                    console.log(error);
                  }
                );

              if(street!==''){
                setState({propertyStreet:street});
                setState({address:street});
              }
              if(city!=='')
                setState({propertyCity:city});
              if(county!=='')
                setState({propertyCounty:county});
              if(state!=='')
                setState({propertyState:state});
              if(zip!=='')
                setState({propertyZip:zip});
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address});
        setState({propertyStreet:address});
      }
    }
  }

  // Validate the Credit Card Number
  const validateCCNumber=()=>{
    // console.log(state.newcc_card_number);
    let newCCNumber = state.newcc_card_number;

    let creditCard = valid.number(newCCNumber);
    let ccNumberError = "An unknown error occured. Please try again later";
    let ccNumberValid = false;

    if (newCCNumber === null || !newCCNumber.trim()) {
      ccNumberError = "Credit card number is not complete";
    } else if (creditCard.isValid) {
      ccNumberValid = true;
    } else {
      ccNumberError = "Credit card number is invalid";
    }

    setState({ccNumberValid: ccNumberValid, ccNumberError: ccNumberError});
    validateCCType();
  }

  // Validate the Credit Card Type
  const validateCCType=()=>{
    let newCCNumber = state.newcc_card_number;
    let newCCType = state.newcc_card_type;

    let creditCard = valid.number(newCCNumber);
    let ccTypeError = "An unknown error occured. Please try again later";
    let ccTypeValid = false;

    //Mapping
    if(newCCType === "amex"){
      newCCType = "american-express";
    }else if(newCCType === "dinersclub"){
      newCCType = "diners-club";
    }

    //Card Type Verification
    if (newCCType === null || !newCCType.trim() || creditCard.card === null) {
      if(creditCard.card === null){
        ccTypeError = "";
        ccTypeValid = true;
      }else{
        ccTypeError = "Credit card type is not complete";
      }
    } else if (
      creditCard.card.type &&
      creditCard.card.type.toUpperCase() === newCCType.toUpperCase()
    ) {

      ccTypeValid = true;
    } else {
      ccTypeError = "Credit card type is invalid";
    }

    setState({ccTypeValid: ccTypeValid, ccTypeError: ccTypeError});
  }

  //API call


  const getClientContacts=(type, client)=>{
    let url;
    if(type==='Retail'){
      if(state.role==='delegator')
        url = 'client/relationship/retail/keyword='+state.email+'&client_fk='+client;
      else
        url = 'client/relationship/retail/keyword='+state.email;
    }
    else{
      url = 'client/relationship/wholesale/keyword='+state.email+'&close_by_client_fk='+client;
    }
    let callBack = (response)=>{
      console.log(response);
      let code = response.data.code;

      if(code==='00'){

        let clientEntities = [];
        let contacts = response.data.data;

        if(contacts.length===1){
          for(let i=0;i<contacts.length;i++){
            for(let j=0;j<contacts[i].contacts.length;j++){
              let tmp = {};
              tmp.predefined = true;
              tmp.label = contacts[i].contacts[j].label;
              tmp.name =  {label:contacts[i].contacts[j].name,value:contacts[i].contacts[j].name};
              tmp.phone = contacts[i].contacts[j].phone;
              tmp.workPhone = '';
              tmp.mobilePhone = '';
              tmp.email = contacts[i].contacts[j].email;

              clientEntities.push(tmp);
            }
          }
        }
        else if(contacts.length===0){
          let tmp = {};
          tmp.predefined = true;
          tmp.label = 'Loan Officer';
          tmp.name = {label:'',value:''};
          tmp.phone = '';
          tmp.workPhone = '';
          tmp.mobilePhone = '';
          tmp.email = '';

          clientEntities.push(tmp);

          tmp = {};
          tmp.predefined = true;
          tmp.label = 'Loan Processor';
          tmp.name = {label:'',value:''};
          tmp.phone = '';
          tmp.workPhone = '';
          tmp.mobilePhone = '';
          tmp.email = '';

          clientEntities.push(tmp);

          if(type==='Wholesale'){
            tmp = {};
            tmp.predefined = true;
            tmp.label = 'Broker';
            tmp.name = {label:'',value:''};
            tmp.phone = '';
            tmp.workPhone = '';
            tmp.mobilePhone = '';
            tmp.email = '';

            clientEntities.push(tmp);
          }

        }
        else{
          let maxMap = {};
          let dictionary = {};
          for(let i=0;i<contacts.length;i++){
            let map = {};
            for(let j=0;j<contacts[i].contacts.length;j++){
              if(!map[contacts[i].contacts[j].label])
                map[contacts[i].contacts[j].label] = 0;
              map[contacts[i].contacts[j].label]++;

              if(!dictionary[contacts[i].contacts[j].label])
                dictionary[contacts[i].contacts[j].label] = [];
              dictionary[contacts[i].contacts[j].label].push({label:contacts[i].contacts[j].name, value:contacts[i].contacts[j]});
            }
            for(let key in map){
              if(!maxMap[key])
                maxMap[key] = 0;
              if(maxMap[key]<map[key])
                maxMap[key] = map[key];
            }

          }

          let loExist = false;
          for(let key in maxMap){
            for(let i=0;i<maxMap[key];i++){
              let tmp = {};
              tmp.predefined = true;
              tmp.label = key
              tmp.name = {label:'',value:''};
              tmp.phone = '';
              tmp.workPhone = '';
              tmp.mobilePhone = '';
              tmp.email = '';

              if(key !== 'Loan Officer'){
                clientEntities.push(tmp);
              }else if(!loExist){
                clientEntities.push(tmp);
                loExist = true;
              }
            }
          }

          for(let key in dictionary){
            setState({[key+'options']: dictionary[key],[key+'optionsOri']: dictionary[key]});
          }
        }
        //add in co-borrower by default
        let tmp = {};
        tmp.predefined = true;
        tmp.label = 'Co-Borrower';
        tmp.name = {label:'',value:''};
        tmp.phone = '';
        tmp.workPhone = '';
        tmp.mobilePhone = '';
        tmp.email = '';

        clientEntities.unshift(tmp);

        setState({clientEntities:clientEntities, contacts:contacts});
      }
    };
    httpGet(url, '','Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getDelegatorAssociate=()=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let associatedClient = [];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.client_fk;
          tmp.value = client.company;

          associatedClient.push(tmp);
        }
        setState({associatedClient:associatedClient});
      }
    };
    httpGet('client/delegator/associate/'+state.userID,'','Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getPropertyTypes=()=>{
    const callBack = apiCallBack([{state:'propertyTypes', key:'data'}]);
    httpGet('propertyType/get','','Oops, something went wrong and could not load property types. Please try again later.', callBack);
  }

  const getFileTypes=()=>{
    const callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
    httpGet('file/fileType/public/get','','Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }

  const getLoanPurposes=()=>{
    const callBack = apiCallBack([{state:'loanPurposes', key:'data'}]);
    httpGet('loanPurpose/get','','Oops, something went wrong and could not load property types. Please try again later.', callBack);
  }

  const getLoanTypes=()=>{
    const callBack = apiCallBack([{state:'loanTypes', key:'data'}]);
    httpGet('loanType/get','','Oops, something went wrong and could not load loan types. Please try again later.', callBack);
  }

  const getAppraisalTypes=()=>{
    const callBack = apiCallBack([{state:'availableAppraisalTypes', key:'data'},{state:'availableAppraisalTypesFiltered', key:'data'}]);
    httpGet('appraisalType/get/externalLabel','','Oops, something went wrong and could not load appraisal types. Please try again later.', callBack);
  }

  const copyOrder=(ID)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let copy = response.data.data;


        let entities = [];

        for(let i=0;i<copy.entities.length;i++){
          if(copy.entities[i].entity_label==='Broker Company'||copy.entities[i].entity_label==='Appraiser'||copy.entities[i].entity_label==='AE'||copy.entities[i].entity_label==='API Integration'||copy.entities[i].entity_label==='Payment Contact')
            continue;
          let tmp = {
            label: copy.entities[i].entity_label,
            name: {label:copy.entities[i].entity_name, value:copy.entities[i].entity_name},
            phone: copy.entities[i].entity_phone,
            work_phone: copy.entities[i].entity_work_phone,
            mobile_phone: copy.entities[i].entity_mobile_phone,
            email: copy.entities[i].entity_email
          };

          entities.push(tmp);
        }

        console.log(copy)
        

        setState({
          openBy:copy.open_by_client,
          quoteOrder:'no',
          propertyAccessName:copy.property_access_name,
          propertyAccessTitle:copy.property_access_title,
          propertyAccessEmail:copy.property_access_email,
          propertyType:copy.property_type,
          clientEntities:entities,
          propertyAccessHomePhone:copy.property_access_home_phone,
          propertyAccessWorkPhone:copy.property_access_work_phone,
          propertyAccessMobilePhone:copy.property_access_mobile_phone,
          salePrice:copy.selling_price,
          propertyStreet:copy.property_street,
          address:copy.property_street,
          propertyCity:copy.property_city,
          propertyCounty:copy.property_county,
          propertyState:copy.property_state,
          propertyZip:copy.property_zip,
          pdrSellerID:copy.pdr_seller_id,
          pdrLenderID:copy.pdr_lender_id,
          pdrLPAKey:copy.pdr_lpa_key,
          loanNumber:copy.loan_num,
          fhaNumber:copy.fha_case_number,
          loanType:copy.loan_type,
          loanAmount:copy.loan_amount,
          loanPurpose:copy.loan_purpose,
          occupant:copy.property_occupancy,
          apn:copy.apn,
          client:copy.client_fk,
          borrowerFirstName:copy.borrower_f_name,
          borrowerLastName:copy.borrower_l_name,
          paymentLinkEmailName:copy.borrower_f_name+' '+copy.borrower_l_name,
          paymentLinkEmailAddress:copy.borrower_email,
          borrowerEmail:copy.borrower_email,
          borrowerPhone:copy.borrower_phone,
          borrowerWorkPhone:copy.borrower_work_phone,
          borrowerMobilePhone:copy.borrower_mobile_phone,
          lat:copy.property_lat,
          lng:copy.property_lng,
          clientBranchFk:copy.client_branch_fk,
          openByClientBranch:copy.open_by_client_branch_fk,
          orderType:copy.order_type
        });

        updatePropertyType(copy.property_type);
        updateLoanType(copy.loan_type);
        getClientBranches(copy.client_fk);
        getOpenByClientBranches(copy.open_by_client);
        getClientContacts(copy.order_type, copy.client_fk);
      }
    };
  

    httpGet('appraisal/'+ID,'','Oops, something went wrong and could not load appraisal. Please try again later.', callBack);
  }

  const searchOrder=()=>{
    let parameters = [
      {
        field:'keyword',
        value:state.searchOrder
      }
    ];


    const callBack = apiCallBack([{state:'searchOrders', key:'data'}]);
    httpPost('appraisal/client/search', parameters, '', 'Oops, something went wrong and could not search the appraisal. Please try again later.', callBack);
  }

  const getClientBranches=(client)=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        setState({clientBranches:response.data.data});
        if(response.data.data.length>0)
          setState({clientBranch:response.data.data[0].ID});
        if(state.role==='delegator' && state.orderType==='Retail'){
          setState({openByBranch:response.data.data[0].ID, openByClientBranches:response.data.data});
        }
      }
    };
    httpGet('client/branch/get/'+client,'','Oops, something went wrong and could not retrieve client branch list. Please try again later.', callBack);
  }

  const getOpenByClientBranches=(client)=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        setState({openByClientBranches:response.data.data});
        if(response.data.data.length>0)
          setState({openByBranch:response.data.data[0].ID});
      }
    };
    httpGet('client/branch/get/'+client,'','Oops, something went wrong and could not retrieve client branch list. Please try again later.', callBack);
  }

  //render




  let fhaNumber, fhaNumber2, mainForm2, subForms2, loanTypes, occupants, loanPurposes, propertyTypes, anticipatedValue, availableAppraisalTypes, states, salePrice, salePrice2, toUploadFiles, toUploadFiles2, clientEntities, clientEntities2, entities;

  if(state.loanTypes.length>0)
    loanTypes = state.loanTypes.map(
      (loanType, index)=>{
        return <option value={loanType.loan_type} key={index}>{loanType.loan_type}</option>;
      }
    );

  if(state.occupants.length>0)
    occupants = state.occupants.map(
      (occupant, index)=>{
        return <option value={occupant.key} key={index}>{occupant.key}</option>;
      }
    );

  if(state.loanPurposes.length>0)
    loanPurposes = state.loanPurposes.map(
      (loanPurpose, index)=>{
        return <option value={loanPurpose.loan_purpose} key={index}>{loanPurpose.loan_purpose}</option>;
      }
    );

  if(state.propertyTypes.length>0)
    propertyTypes = state.propertyTypes.map(
      (propertyType, index)=>{
        return <option value={propertyType.property_type} key={index}>{propertyType.property_type}</option>;
      }
    );
  if(state.availableAppraisalTypesFiltered.length>0)
    availableAppraisalTypes = state.availableAppraisalTypesFiltered.map(
      (appraisalType, index)=>{
        if(state.appraisaltype===appraisalType)
          return <option selected value={appraisalType.appraisal_type} key={index}>{appraisalType.external_label}</option>;
        else
          return <option value={appraisalType.appraisal_type} key={index}>{appraisalType.external_label}</option>;
      }
    );

  if(state.states.length>0)
    states = state.states.map(
      (state, index)=>{
        return <option value={state.key} key={index}>{state.value}</option>;
      }
    );

  if(state.entities.length>0){
    entities = state.entities.map(
      (entity,index)=>{
        return(
          <option key={index} value={entity}>{entity}</option>
        );
      }
    );
  }

  if(state.appraisalType!==''){

    mainForm2 = <div>{state.appraisalType}</div>;

  }

  let step1, step2, step3, step4, step5, step6;

  if(state.loanPurpose==='Purchase'){
    salePrice =
    <Row>
      <Col sm="12">
        <label>Sale price</label>
        <Input type="text" required={true} value={state.salePrice} onChange={(e)=>setState({salePrice:e.target.value})}/>
      </Col>
    </Row>;
    salePrice2 =
    <Row>
      <Col sm="12">
        <label>Sale price</label><br/>
        {formatNumber(state.salePrice)}
      </Col>
    </Row>;
  }

  if(state.loanPurpose==='Refinance'||state.loanPurpose==='R/T Refinance'||state.loanPurpose==='C/O Refinance'){
    anticipatedValue = <Row>
      <Col sm="12">
        <label>Anticipated Value</label>
        <Input type="number" value={state.estimatedValue} onChange={(e)=>setState({estimatedValue:e.target.value})}/>
      </Col>
    </Row>;
  }

  if(state.loanType==='FHA'){
    fhaNumber =
    <Row>
      <Col sm="12">
        <label>FHA number</label>
        <Input type="text" value={state.fhaNumber} onChange={(e)=>setState({fhaNumber:e.target.value})}/>
      </Col>
    </Row>
    fhaNumber2 =
    <Row>
      <Col sm="12">
        <label>FHA number</label><br/>
        {state.fhaNumber}
      </Col>
    </Row>
  }

  let filesTypesOpt;

  if(state.fileTypes.length>0){
    filesTypesOpt = state.fileTypes.map(
      (fileType, index)=>{
        return(
          <option key={index} value={fileType.name}>{fileType.name}</option>
        );
      }
    );
  }

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return <tr key={index}>
            <td>{file.name}</td>
            <td>
              <Input type="text" value={file.description} onChange={(e)=>onFileDescriptionChange(file.name, e.target.value)}/>
            </td>
            <td>
              <select value={file.fileType} className="form-control" onChange={(e)=>{onFileTypeChange(file.name,e.target.value)}}>
                <option value=""></option>
                {filesTypesOpt}
              </select>
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
      }
    );

    toUploadFiles2 = state.toUploadFiles.map(
      (file,index)=>{
        return <div key={index}>{file.name}</div>
      }
    );
  }

  if(state.clientEntities.length>0){
    clientEntities2 = state.clientEntities.map(
      (clientEntity, index)=>{
        let homeKeyword;
        let workPhone;
        let mobilePhone;

        if(clientEntity.label==='Co-Borrower'||clientEntity.label==='Borrower'){
          workPhone = <Col sm="3">
            <label>Work Phone</label><br/>
            {clientEntity.work_phone}
          </Col>;
          mobilePhone = <Col sm="3">
            <label>Mobile Phone</label><br/>
            {clientEntity.mobile_phone}
          </Col>;

          homeKeyword='Home ';
        }
        return(
          <div key={index}>
            <Card>
            <CardHeader className="gray-color">
              {clientEntity.label}
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12">
                  <label>Name</label><br/>
                  {clientEntity.name.label}
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <label>{homeKeyword}Phone</label><br/>
                  {clientEntity.phone}
                </Col>
                {workPhone}
                {mobilePhone}
                <Col sm="3">
                  <label>Email</label><br/>
                  {clientEntity.email}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
        </div>
        );
      }
    );
    clientEntities = state.clientEntities.map(
      (clientEntity,index)=>{

        let labelInput = <Input type="text" value={clientEntity.label} required="true" onChange={(e)=>updateEntityInfo(index,'label',e.target.value)}/>;

        if(clientEntity.predefined===true)
          labelInput = <Input type="text" value={clientEntity.label} required="true" disabled/>;
        let homeKeyword;
        let workPhone;
        let mobilePhone;

        let textWidget =<Input value={clientEntity.name.label} required="true" type="text" onChange={(e)=>updateEntityInfo(index,'name',{label:e.target.value,value:e.target.value})}/>;

        if(state.contacts.length>1&&clientEntity.label!=='Borrower'&&clientEntity.label!=='Co-Borrower'&&clientEntity.label!=='Payment Contact'){

          textWidget = <Select
            value={clientEntity.name}
            onChange={(e)=>handleChange(e, index)}
            onInputChange={(e)=>{inputChange(e, clientEntity.label, index)}}
            defaultOptions
            options={state[clientEntity.label+"options"]}
          />
        }

        if(clientEntity.label==='Co-Borrower'||clientEntity.label==='Borrower'){
          workPhone = <Col sm="3">
            <label>Work Phone</label>
            <Input value={clientEntity.work_phone} type="text" onChange={(e)=>updateEntityInfo(index,'work_phone',e.target.value)}/>
          </Col>;
          mobilePhone = <Col sm="3">
            <label>Mobile Phone</label>
            <Input value={clientEntity.mobile_phone} type="text" onChange={(e)=>updateEntityInfo(index,'mobile_phone',e.target.value)}/>
          </Col>;
          homeKeyword='Home ';
        }
        return(
          <div key={index}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    {clientEntity.label} contact
                  </Col>
                  <Col sm="4" className="align-right">
                    <i className="fa fa-times red-color cursor-pointer" onClick={()=>removeClientEntity(index)}></i>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="4">
                    <label><font color="red">*</font>Title</label>
                    {labelInput}
                  </Col>
                  <Col sm="5">
                    <label><font color="red">*</font>Name</label>
                    {textWidget}
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label><font color="red">*</font>Email</label>
                    <Input value={clientEntity.email} required="true" type="email" onChange={(e)=>updateEntityInfo(index,'email',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>{homeKeyword}Phone</label>
                    <Input value={clientEntity.phone} type="text" onChange={(e)=>updateEntityInfo(index,'phone',e.target.value)}/>
                  </Col>
                  {workPhone}
                  {mobilePhone}
                </Row>
              </CardBody>
            </Card>
            <br/>
          </div>
        );
      }
    );
  }

  let expirationYear = [];

  let currentYear = new Date().getFullYear();

  for(let i=0;i<20;i++){
    expirationYear.push(currentYear);
    currentYear++;
  }


  let paymentPiece = <Card>
    <CardHeader className="header-color cursor-pointer" onClick={()=>ableSendLink(0)}>
      <Row>
        <Col sm="8">
          <i className="fa fa-edit"></i>&nbsp;Fill out Payment
        </Col>
        <Col sm="4" className="align-right">
          <i className={(state.sendLink)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row>
        <Col sm="8">
          <div style={{position:'absolute',bottom:'0'}}>
            <b>Your credit card and identity information are secure.</b>
          </div>
        </Col><Col sm="4">
          <div className="align-right">
            <NavLink target="_blank" to="https://sealserver.trustwave.com/cert.php?customerId=873823340ca511e0b34b005056b201e5&size=105x54&style=">
              <img id="trustwaveSealImage" src="https://sealserver.trustwave.com/seal_image.php?customerId=873823340ca511e0b34b005056b201e5&size=105x54&style=" border="0" style={{cursor:'pointer'}} oncontextmenu="javascript:alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'); return false;" alt="This site is protected by Trustwave's Trusted Commerce program" title="This site is protected by Trustwave's Trusted Commerce program" />
            </NavLink>
            <br/><br/>
          </div>
        </Col>
      </Row>
      <font color="red">{state.errorMessage}</font>
      <Card>
        <CardHeader className="gray-color">
          <i className="fa fa-user"></i> Name on card
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6">
              <label>First name</label>
              <Input type="text" value={state.newcc_first_name} onChange={(e)=>setState({newcc_first_name:e.target.value})}/>
            </Col>
            <Col sm="6">
              <label>Last name</label>
              <Input type="text" value={state.newcc_last_name} onChange={(e)=>setState({newcc_last_name:e.target.value})}/>
            </Col>
            <Col sm="6">
              <br/>
              <Input type="text" placeholder="Email (optional, for receipt)" value={state.newcc_email} onChange={(e)=>setState({newcc_email:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          <i className="fa fa-credit-card"></i> Credit card information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="3">
              <label>Card holder</label>
              <Input type="select" value={state.coveredBy} onChange={(e)=>setState({coveredBy:e.target.value})}>
                <option value=""></option>
                <option value="Broker">Broker</option>
                <option value="Borrower">Borrower</option>
                <option value="Lender">Lender</option>
              </Input>
            </Col>
            <Col sm="5">
              <label>Card number</label>
              <Input type="text" value={state.newcc_card_number} onChange={(e)=>setState({newcc_card_number:e.target.value})}/>
              <div>{!state.ccNumberValid&&<h6><font color="red">{'*** '+state.ccNumberError+' ***'}</font></h6>}</div>
            </Col>
            <Col sm="4">
              <label>Verification number</label>
              <Input type="text" value={state.newcc_cvc} onChange={(e)=>setState({newcc_cvc:e.target.value})}/>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="6">
              <Input type="select" value={state.newcc_card_type} onChange={(e)=>setState({newcc_card_type:e.target.value})}>
                <option value="">Select card type</option>
                <option value="visa">Visa</option>
                <option value="mastercard">Master Card</option>
                <option value="amex">American Express</option>
                <option value="dinersclub">Dinners Club</option>
                <option value="discover">Discover</option>
              </Input>
              <div>{!state.ccTypeValid&&<h6><font color="red">{'*** '+state.ccTypeError+' ***'}</font></h6>}</div>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="6">
              <Input type="select" value={state.newcc_expiration_month} onChange={(e)=>setState({newcc_expiration_month:e.target.value})}>
                <option value="">Select expiration month</option>
                <option value="01">January</option>
                <option value="02">Febuary</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </Input>
            </Col>
            <Col sm="6">
              <Input type="select" value={state.newcc_expiration_year} onChange={(e)=>setState({newcc_expiration_year:e.target.value})}>
                <option value="">Select expiration year</option>
                {
                  expirationYear.map(
                    (year,index)=>{
                      return <option key={index} value={year}>{year}</option>
                    }
                  )
                }
              </Input>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>

      <Card>
        <CardHeader className="gray-color">
          <i className="fa fa-bank"></i> Billing address
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="8">
              <label>Street</label>
              <Input type="text" value={state.newcc_street} onChange={(e)=>setState({newcc_street:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>City</label>
              <Input type="text" value={state.newcc_city} onChange={(e)=>setState({newcc_city:e.target.value})}/>
            </Col>
            <Col sm="5">
              <label>State</label>
              <Input type="select" value={state.newcc_state} onChange={(e)=>setState({newcc_state:e.target.value})}>
                <option value=""></option>
                {states}
              </Input>
            </Col>
            <Col sm="3">
              <label>Zip</label>
              <Input type="text" value={state.newcc_zip} onChange={(e)=>setState({newcc_zip:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </CardBody>
  </Card>;

  if(state.sendLink){
    console.log(state.clientEntities);
    paymentPiece = <div>
      <Card>
        <CardHeader className="header-color">
          Email Payment Link
        </CardHeader>
        <CardBody>
          <center>
            <b>*** An email that contains the payment link will be sent to the email address below. ***</b><br/><br/>
          </center>
          <Row>
            <Col sm="4">
              <label>Contact</label><br/>
              <Input type="select" onChange={(e)=>{updatePaymentLinkEmailInfo(e.target.value)}}>
                <option value={state.borrowerEmail}>Borrower - {state.borrowerFirstName+' '+state.borrowerLastName}</option>
                {
                  state.clientEntities.map(
                    (clientEntity)=>{
                      return <option value={clientEntity.email}>{clientEntity.label} - {clientEntity.name.value}</option>;
                    }
                  )
                }
              </Input>
            </Col>
            <Col sm="8">
              <label>Email</label><br/>
              <Input type="text" value={state.paymentLinkEmailAddress} onChange={(e)=>setState({paymentLinkEmailAddress:e.target.value})}/>
            </Col>
          </Row>
          <br/>


        </CardBody>
      </Card>
    </div>;
  }



  let subFormsToAdd = [];
  if(state.subForm){
    // get all sub form
    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(state.availableAppraisalTypes[i].sub_form==='yes')
        subFormsToAdd.push(state.availableAppraisalTypes[i]);
    }
  }

  let bigFileList = state.bigFiles.join(', ');

  /*
  STEP 1
  */
  step1 =
  <div>
    <br/>
    <form onSubmit={submitForm}>
      <i>**If you are submitting this order for an order that was submitted previously, you can look it up here and fill up the information automatically**</i>
      <Row>
        <Col sm="10">
          <Input type="text" value={state.searchOrder} onChange={(e)=>setState({searchOrder:e.target.value})}/>
        </Col>
        <Col sm="2" className="align-right">
          <Button color="warning" onClick={searchOrder}>Search</Button>
        </Col>
      </Row>
      <br/>
      <div className="medium-scroll-container-no-min">
      {
        state.searchOrders.map(
          (order, index)=>{
            return <div className="my-well cursor-pointer" key={index} onClick={()=>copyOrder(order.ID)}>
              <NavLink to="#">{order.reference_num+' - '+order.loan_num}</NavLink><br/>
              {order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}<br/>
              {order.borrower_f_name+' '+order.borrower_l_name}<br/>
              {order.appraisal_type}
            </div>
          }
        )
      }
      </div>
      <div className="my-divider">&nbsp;</div>
      <Row>
        <Col sm="7">
          <label><font color="red">*</font>Street</label>
          <div style={{position:'relative', width:'100%'}}>
            <div style={{position:'absolute', width:'100%'}}>
              <MyPlacesAutocomplete value={state.address} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
            </div>
          </div>
        </Col>
        <Col sm="5">
          <label><font color="red">*</font>City</label>
          <Input required="true" type="text" value={state.propertyCity} onChange={(e)=>setState({propertyCity:e.target.value})}/>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <label><font color="red">*</font>State</label>
          <Input required="true" type="select" value={state.propertyState} onChange={(e)=>setState({propertyState:e.target.value})}>
            <option key="" value=""></option>
            {states}
          </Input>
        </Col>
        <Col sm="3">
          <label>County</label>
          <Input type="text" disabled value={state.propertyCounty} onChange={(e)=>setState({propertyCounty:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label><font color="red">*</font>Zip</label>
          <Input required="true" type="text" value={state.propertyZip} onChange={(e)=>setState({propertyZip:e.target.value})}/>
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <label><font color="red">*</font>Loan</label>
          <Input type="select" required="true" value={state.orderType} onChange={(e)=>{
              orderTypeChanged(e.target.value);
            }
          }>
            <option value=""></option>
            <option value="Retail">Retail</option>
            <option value="Wholesale">Wholesale</option>
          </Input>
        </Col>
        <Col sm="3">
          <label><font color="red">*</font>Property type</label>
          <Input required="true" type="select" value={state.propertyType} onChange={(e)=>{setState({propertyType:e.target.value});updatePropertyType(e.target.value);}}>
            <option value="" key=""></option>
            {propertyTypes}
          </Input>
        </Col>
        <Col sm="3">
          <label><font color="red">*</font>Occupant</label>
          <Input required="true" type="select" value={state.occupant} onChange={(e)=>setState({occupant:e.target.value})}>
            <option value="" key=""></option>
            {occupants}
          </Input>
        </Col>
        <Col sm="3">
          <label>APN</label>
          <Input type="text" value={state.apn} onChange={(e)=>setState({apn:e.target.value})}/>
        </Col>
      </Row>
      <br/>
      <div className="align-right">
        <Button color="warning">Next</Button>
      </div>
    </form>
  </div>;

  let expectedDate;

  let date;
  if(state.expectedDate!=='')
    date = moment(state.expectedDate).toDate();

  if(state.isRush==='Rush'){
    expectedDate =
    <Row>
      <Col sm="12">
        <label>Expected date</label><br/>
        <DatePicker
          className="form-control"
          selected={date}
          onChange={(text)=>{(text)&&setState({expectedDate:text.toLocaleDateString('en-CA')})}}
        />
        <font color="red">*</font><br/>Additional fee will be charged.
      </Col>
    </Row>;
  }

  let openBy;
  let closeBy;
  let closeByBranch;

  openBy = <Row>
    <Col sm="12">
      <label>Open by</label>
      <Input  type="select" disabled={true} value={state.openBy} onChange={(e)=>{setState({openBy:e.target.value});getOpenByClientBranches(e.target.value)}}>
        <option value={state.self.key}>{state.self.value}</option>
        {
          state.associatedClient.map(
            (client, index)=>{
              return <option key={index} value={client.key}>{client.value}</option>
            }
          )
        }
      </Input>
    </Col>
  </Row>;

  closeBy = <Row>
    <Col sm="12">
      <label>Close by</label>
      <Input  type="select" disabled={state.orderType==='Retail'} value={state.client} onChange={(e)=>{setState({client:e.target.value});getClientBranches(e.target.value); getClientContacts(state.orderType, e.target.value)}}>
        <option value={state.self.key}>{state.self.value}</option>
        {
          state.associatedClient.map(
            (client, index)=>{
              return <option key={index} value={client.key}>{client.value}</option>
            }
          )
        }
      </Input>
    </Col>
  </Row>;


  closeByBranch = <Row>
    <Col sm="12">
      <label>Close by Branch</label>
      <Input type="select" value={state.clientBranch} onChange={(e)=>{
        setState({clientBranch:e.target.value});
        if(state.orderType==='Retail')
          setState({openByBranch:e.target.value});
      }}>
        {
          state.clientBranches.map(
            (branch, index)=>{
              return <option key={index} value={branch.ID}>{branch.branch_name}</option>
            }
          )
        }
      </Input>
    </Col>
  </Row>;

  if(state.role==='delegator'){
    closeBy = <Row>
      <Col sm="12">
        <label>Close by</label>
        <Input type="select" required={true} value={state.client} onChange={(e)=>{
          setState({client:e.target.value});
          getClientBranches(e.target.value);
          getClientContacts(state.orderType, e.target.value);
          if(state.orderType==='Retail')
            setState({openBy:e.target.value});
        }}>
          <option value={state.self.key}>{state.self.value}</option>
          {
            state.associatedClient.map(
              (client, index)=>{
                return <option key={index} value={client.key}>{client.value}</option>
              }
            )
          }
        </Input>
      </Col>
    </Row>;

    closeByBranch = <Row>
      <Col sm="12">
        <label>Close by Branch</label>
        <Input type="select" value={state.clientBranch} onChange={(e)=>{
          setState({clientBranch:e.target.value});
          if(state.orderType==='Retail')
            setState({openByBranch:e.target.value});
        }}>
          {
            state.clientBranches.map(
              (branch, index)=>{
                return <option key={index} value={branch.ID}>{branch.branch_name}</option>
              }
            )
          }
        </Input>
      </Col>
    </Row>


    openBy = <Row>
      <Col sm="12">
        <label>Open by</label>
        <Input disabled={state.orderType==='Retail'} required={true} type="select" value={state.openBy} onChange={(e)=>{setState({openBy:e.target.value});getOpenByClientBranches(e.target.value)}}>
          <option value={state.self.key}>{state.self.value}</option>
          {
            state.associatedClient.map(
              (client, index)=>{
                return <option key={index} value={client.key}>{client.value}</option>
              }
            )
          }
        </Input>
      </Col>
    </Row>;
  }

  let pdrInfoIDs;
  if(state.appraisalType==='ACE+ PDR'){
    pdrInfoIDs =
    <div><Row>
      <Col sm="12">
        <label>Seller ID {generateTooltip('pdrSellerID', 'Seller Service ID.')}</label>
        <Input type="text" value={state.pdrSellerID} onChange={(e)=>setState({pdrSellerID:e.target.value})}/>
      </Col>
    </Row>
    <Row>
      <Col sm="12">
        <label>LPA Key {generateTooltip('pdrLPAKey', 'Loan identifier produced by Freddie Mac\'s Loan Product Advisor application.')}</label>
        <Input type="text" value={state.pdrLPAKey} onChange={(e)=>setState({pdrLPAKey:e.target.value})}/>
      </Col>
    </Row></div>;
  }else if(state.appraisalType==='UPD'){
    pdrInfoIDs =
    <div><Row>
      <Col sm="12">
        <label>Lender ID {generateTooltip('pdrLenderID', "Seller Service Number")}</label>
        <Input type="text" value={state.pdrLenderID} onChange={(e)=>setState({pdrLenderID:e.target.value})}/>
      </Col>
    </Row>
    <Row>
      <Col sm="12">
        <label>LPA Key {generateTooltip('pdrLPAKey', 'Loan identifier produced by Freddie Mac\'s Loan Product Advisor application.')}</label>
        <Input type="text" value={state.pdrLPAKey} onChange={(e)=>setState({pdrLPAKey:e.target.value})}/>
      </Col>
    </Row></div>;
  }

  /*
  STEP 2
  */
  step2 =
  <div>
    <form onSubmit={submitForm}>
      <Card>
        <CardHeader className="gray-color">
          Report type & loan information
        </CardHeader>
        <CardBody>

          {closeBy}
          {closeByBranch}
          {openBy} 
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Loan number</label>
              <Input type="text" value={state.loanNumber} onChange={(e)=>setState({loanNumber:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Loan amount</label>
              <Input type="number" value={state.loanAmount} onChange={(e)=>setState({loanAmount:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Loan type</label>
              <Input required="true" type="select" value={state.loanType} onChange={(e)=>{setState({loanType:e.target.value});updateLoanType(e.target.value)}}>
                <option value="" key=""></option>
                {loanTypes}
              </Input>
            </Col>
          </Row>
          {fhaNumber}
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Loan purpose</label>
              <Input required="true" type="select" value={state.loanPurpose} onChange={(e)=>setState({loanPurpose:e.target.value})}>
                <option value="" key=""></option>
                {loanPurposes}
              </Input>
            </Col>
          </Row>
          {salePrice}

          {anticipatedValue}

          <Row>
            <Col sm="12">
              <label>Rush</label>
              <Input required="true" type="select" value={state.isRush} onChange={(e)=>setState({isRush:e.target.value})}>
                <option value="Non-rush" key="Non-rush">Non-rush</option>
                <option value="Rush" key="Rush">Rush</option>
              </Input>
              <font color="red">*</font>Additional fee will be charged.
            </Col>
          </Row>
          {expectedDate}

          <div className="my-divider"/><br/>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Report type</label>
              <Input type="select" required="true" value={state.appraisalType} onChange={(e)=>handleNewAppraisalTypeChange(e.target.value)}>
                <option value=""></option>
                {availableAppraisalTypes}
              </Input>
            </Col>
          </Row>
          {pdrInfoIDs}
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(0)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>;

  /*
  STEP 3
  */
  step3 =
  <div>
    <form onSubmit={submitForm}>
      <div className="align-right">
        <NavLink to="#" onClick={toggleNewEntityDropDownOpen}><i className="fa fa-plus green-color"></i> Add new additional contact</NavLink>
      </div>
      <Modal className="my-modal" isOpen={state.newEntityDropDownOpen} toggle={toggleNewEntityDropDownOpen} >
        <ModalHeader hidden={true} toggle={toggleNewEntityDropDownOpen}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-plus"></i> New contact</h5>
          </center>
          <br/>
          <Input type="select" value={state.selectedNewEntity} onChange={(e)=>setState({selectedNewEntity:e.target.value})}>
            {entities}
          </Input>
          <br/>
          <center>
            <Button color="warning" onClick={addNewClientEntity}>Add</Button>&nbsp;
            <Button color="info" onClick={toggleNewEntityDropDownOpen}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Card>
        <CardHeader className="gray-color">
            Contact for property access
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>Title</label>
              <Input required="true" type="text" value={state.propertyAccessTitle} onChange={(e)=>setState({propertyAccessTitle:e.target.value})}/>
            </Col>
            <Col sm="4">
              <label><font color="red">*</font>Name</label>
              <Input required="true" type="text" value={state.propertyAccessName} onChange={(e)=>setState({propertyAccessName:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label>Home Phone #</label>
              <Input type="text" value={state.propertyAccessHomePhone} onChange={(e)=>setState({propertyAccessHomePhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Work Phone #</label>
              <Input required="true" type="text" value={state.propertyAccessWorkPhone} onChange={(e)=>setState({propertyAccessWorkPhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Mobile Phone #</label>
              <Input type="text" value={state.propertyAccessMobilePhone} onChange={(e)=>setState({propertyAccessMobilePhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Email</label>
              <Input required="true"type="email" value={state.propertyAccessEmail} onChange={(e)=>setState({propertyAccessEmail:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          <Row>
            <Col sm="6">
              Borrower Information
            </Col>
            <Col sm="6" className="align-right">
              <Input type="checkbox" checked={state.copyPropertyAccessContactInfo} onClick={copyPropertyAccessContactInfo}/>&nbsp;Same as property access info
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>First name</label>
              <Input required="true" value={state.borrowerFirstName} type="text" onChange={(e)=>setState({borrowerFirstName:e.target.value, paymentLinkEmailName:e.target.value+' '+state.borrowerLastName})}/>
            </Col>
            <Col sm="4">
              <label><font color="red">*</font>Last name</label>
              <Input required="true" value={state.borrowerLastName} type="text" onChange={(e)=>setState({borrowerLastName:e.target.value, paymentLinkEmailName:state.borrowerFirstName+' '+e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label>Home Phone</label>
              <Input type="text" value={state.borrowerPhone} onChange={(e)=>setState({borrowerPhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Work Phone</label>
              <Input type="text" value={state.borrowerWorkPhone} onChange={(e)=>setState({borrowerWorkPhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Mobile Phone</label>
              <Input type="text" value={state.borrowerMobilePhone} onChange={(e)=>setState({borrowerMobilePhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Email</label>
              <Input required="true" value={state.borrowerEmail} type="email" onChange={(e)=>setState({borrowerEmail:e.target.value, paymentLinkEmailAddress:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <font color="red"><i>** Please note that most email notifications are sent to only Loan Officer/Loan Processor/Email Notification Subscriber **</i></font>
      {clientEntities}
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(1)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button type="submit" color="warning" onClick={clearEmptyContact}>Next</Button>
        </Col>
      </Row>
    </form>
  </div>;

  /*
  STEP 4
  */
  step4 =
  <div>
    <form onSubmit={submitForm}>
      <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
        <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
          </center>

          <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
          <div>{bigFileList}</div>

          <br/><br/>
          <center>
            <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
          </center>
        </ModalBody>
      </Modal>
      <Row>
        <Col sm="12">
          <label>Special instructions for inspection scheduling</label>
          <Input type="textarea" rows="6" style={{resize:'none'}} onChange={(e)=>setState({specialInstructions:e.target.value})} value={state.specialInstructions}/>
        </Col>
      </Row>
      <br/>

      <Row>
        <Col sm="12">
          <label>Special instructions for AMC</label>
          <Input type="textarea" rows="6" style={{resize:'none'}} onChange={(e)=>setState({specialInstructionsForAMC:e.target.value})} value={state.specialInstructionsForAMC}/>
        </Col>
      </Row>
      <br/>

      <Card>
        <CardHeader className="gray-color">
          Appraisal & loan related documents
        </CardHeader>
        <CardBody>
          <MyDropzone onDrop={onDrop}/>
          <br/>
          <div className="small-scroll-container red-color flashit" style={{minHeight:'30px',height:'30px ',maxHeight:'30px'}}>
              <b>{state.errorMessage}</b>
            </div>
          <div>
            <table className="table file-list-table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th width="25%">Name</th>
                  <th width="10%">Description</th>
                  <th width="30%">File Type</th>
                  <th width="15%">Size</th>
                  <th width="10%"><center>Status</center></th>
                  <th width="10%">Control</th>
                </tr>
              </thead>
              <tbody>
                {toUploadFiles}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(2)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>;

  /*
  STEP 5
  */
  step5 =
  <div>
    <form onSubmit={submitForm}>
      <div>
        <Input type="checkbox" checked={state.sendLink} onClick={()=>{ableSendLink(!state.sendLink)}}/>
        &nbsp;<b><font color="red">***I would like to send the payment link to the borrower/co-borrower/loan officer/etc.***</font></b>
      </div>
      <br/>

      {paymentPiece}
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(3)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>

  /*
  STEP 6
  */
  step6 =
  <div>
    <form onSubmit={submitForm}>
      <label><i>You're almost finished. Please review this page prior to hitting the "Submit" button below so that we have all the right information. Thank you for choosing @Home VMS.</i></label>
      <Card>
        <CardHeader className="gray-color">
          Property
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Address</label><br/>
              {state.propertyStreet+' '+state.propertyCity+', '+state.propertyState+' '+state.propertyZip}
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Property type</label><br/>
              {state.propertyType}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Appraisal & loan information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Report type</label><br/>
              {mainForm2}
              {subForms2}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <label>Rush</label><br/>
              {state.isRush}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <label>Loan type</label><br/>
              {state.loanType}
            </Col>
          </Row>
          {fhaNumber2}
          <Row>
            <Col sm="12">
              <label>Loan purpose</label><br/>
              {state.loanPurpose}
            </Col>
          </Row>
          {salePrice2}
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Contact information
        </CardHeader>
        <CardBody>
          <Card>
            <CardHeader className="gray-color">
              Property access
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12">
                  <label>Title</label><br/>
                  {state.propertyAccessTitle}
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <label>Name</label><br/>
                  {state.propertyAccessName}
                </Col>
              </Row>

              <Row>
                <Col sm="3">
                  <label>Home phone</label><br/>
                  {state.propertyAccessHomePhone}
                </Col>
                <Col sm="3">
                  <label>Work phone</label><br/>
                  {state.propertyAccessWorkPhone}
                </Col>
                <Col sm="3">
                  <label>Mobile phone</label><br/>
                  {state.propertyAccessMobilePhone}
                </Col>
                <Col sm="3">
                  <label>Email</label><br/>
                  {state.propertyAccessEmail}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <br/>
          <Card>
            <CardHeader className="gray-color">
              Borrower
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="3">
                  <label>First name</label><br/>
                  {state.borrowerFirstName}
                </Col>
                <Col sm="3">
                  <label>Last name</label><br/>
                  {state.borrowerLastName}
                </Col>
              </Row><Row>
                <Col sm="3">
                  <label>Home Phone</label><br/>
                  {state.borrowerPhone}
                </Col>
                <Col sm="3">
                  <label>Work Phone</label><br/>
                  {state.borrowerWorkPhone}
                </Col>
                <Col sm="3">
                  <label>Mobile Phone</label><br/>
                  {state.borrowerMobilePhone}
                </Col>
                <Col sm="3">
                  <label>Email</label><br/>
                  {state.borrowerEmail}
                </Col>
              </Row>
            </CardBody>
          </Card>

          {clientEntities2}
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Additional information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Special instructions for inspection scheduling</label><br/>
              <Input type="textarea" rows="6" style={{resize:'none'}} onChange={(e)=>setState({specialInstructions:e.target.value})} value={state.specialInstructions}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Special instructions for AMC</label><br/>
              <Input type="textarea" rows="6" style={{resize:'none'}} onChange={(e)=>setState({specialInstructionsForAMC:e.target.value})} value={state.specialInstructionsForAMC}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Document uploaded</label><br/>
              <div className="small-scroll-container">
                {toUploadFiles2}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(4)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning" disabled={state.submitDisabled!==false} onClick={buttonHandler}>Submit</Button>
        </Col>
      </Row>
    </form>
  </div>

  let activeStep = step1;
  if(state.currentStep===0)
    activeStep = step1;
  else if(state.currentStep===1)
    activeStep = step2;
  else if(state.currentStep===2)
    activeStep = step3;
  else if(state.currentStep===3)
    activeStep = step4;
  else if(state.currentStep===4)
    activeStep = step5;
  else if(state.currentStep===5)
    activeStep = step6;

  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;New appraisal
          </div>
          <NavLink to="/appraisal">Back to appraisal list</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
      <br/>
      {activeStep}
    </div>
  );
}


export default NewAppraisal;
