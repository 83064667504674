//Author June Leow
//Date June 10th, 2024
import FileSaver from 'file-saver';
import React, { useEffect, useReducer } from 'react';
import Avatar from 'react-avatar';
import { Button, Input, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { formatNumber, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, showMessage, stringToHexCode } from '../../util/util';
import MyDropzone from '../util/my-dropzone';
import './file.css';

//initialize the state
const initialState = {
  appraisalFiles:[],
  toUploadFiles:[],
  resizeFilePopUp:false,
  bigFiles:[],
  modal: false,
  errorMessage:'',
  fileTypes:[],
  uploadDisabled:false
};

//reducer function that perform state update
const reducer = getReducer();


const AppraisalFile  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalFiles();
    getFileTypes();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  useEffect(()=>{
    if(state.uploadDisabled){
        uploadAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.uploadDisabled]);


  //non API call but simpyl manage state
  const uploadButtonHandler=()=>{
    if(state.uploadDisabled)
      return;
    setState({uploadDisabled:true});
  }

  const removeToUploadFile = (name) => {
    const newFiles = state.toUploadFiles.filter(file => file.name !== name);
    setState({ toUploadFiles: newFiles });
  };

  const formatFileSize = (size) => {
    const intSize = parseInt(size, 10);
    if (intSize >= 1000000) {
        return formatNumber(Math.round((intSize * 10) / 1000000) / 10) + ' MB';
    } else if (intSize >= 1000) {
        return formatNumber(Math.round((intSize * 10) / 1000) / 10) + ' KB';
    } else {
        return formatNumber(intSize) + ' B';
    }
  };

  const toggleResizeFile=()=>{
    if(state.resizeFilePopUp)
      setState({bigFiles:[]});
    setState({resizeFilePopUp: !state.resizeFilePopUp});
  }

  const toggle=()=>{
    if(state.modal)
      setState({uploadDisabled:false});
    setState({
      modal: !state.modal
    });
  }


  const toggleWrongFile=()=>{
    setState({wrongFilePopUp:!state.wrongFilePopUp});
  }

  const onFileTypeChange = (name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  const onFileDescriptionChange = (name, description)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.description = description;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //constructing a new file object
  const deepCopyFileObject = (file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;
    newFile.description = file.description;

    return newFile
  }
    
  const onDrop = (acceptedFiles) => {
    console.log(state);
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles.slice();

    let newState = {};
    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          newState = Object.assign({}, newState,{errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'});
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      newState = Object.assign({}, newState,{resizeFilePopUp:true, bigFiles:bigFiles});
    }
    newState = Object.assign({}, newState,{toUploadFiles:existingFiles});
    setState(newState);
  };

  //API call
  const downloadFile = (name) => {
    const downloadFileCallBack = (response) => {
      console.log(response);
        const code = response.data.code;
        if (code === '00') {
            const byteCharacters = atob(response.data.data);
            const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            const data = new Blob([byteArray]);
            FileSaver.saveAs(data, name);
        }
    };

    const parameters = [
        { field: 'parentFk', value: props.appraisalFk },
        { field: 'name', value: name },
        { field: 'directory', value: 'Orders' }
    ];

    httpPost('file/download', parameters, '', `Oops, something went wrong and could not download the file "${name}". Please try again later.`, downloadFileCallBack);
};

const downloadAllInOneInvoice=()=>{
  let parameters = [
    {
      field:'appraisalFk',
      value:props.appraisalFk
    },
  ];
  let callBack = (response)=>{
    const code = response.data.code;
    if (code === '00') {
        const byteCharacters = atob(response.data.data);
        const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const data = new Blob([byteArray]);
        FileSaver.saveAs(data, 'invoice.pdf');
    }
  };
  
  httpPost('file/invoice/allInOne/download',parameters, '', 'Oops, something went wrong and could not load Invoice. Please try again later.', callBack);
}

const wait = (ms)=>{
  return new Promise(resolve => setTimeout(resolve, ms));
}

const uploadAll = async () => {
  let preCheck = true;
  let errorMessage = '';
  let hasAppraisal = false;
  let hasXmlReport = false;
  let hasPdfReport = false;
  let hasENVReport = false;
  let extractXml = false;

  let fileTypes = [];
  let standAloneKeywords = [
    'Final Inspection with Photos (1004D / HUD 92051)',
    'Appraisal Update / Re Cert',
    'Rent Survey (stand alone)',
    'Operating Income Statement (stand alone)',
    'Condo Drive By with Photos (1075)',
    'SFR Drive By with Photos (2055)',
    'Exterior Only Inspection (2075)',
    'Desk Review (Cost Enhanced with Photos)',
    'Field Review (Cost with Photos)',
    'Disaster Inspection & with Photos (1004D)'
  ];

  let xmlRequired = props.xmlRequired;

  for(let i =0; i<state.toUploadFiles.length;i++){


    let value = state.toUploadFiles[i].fileType;

    if(!value||value===''){
      fileTypes.push('');
      if(state.toUploadFiles[i].status==='Done')
        continue;

      preCheck = false;
      errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
    }
    else{

      fileTypes.push(value);

      if(state.toUploadFiles[i].status==='Done')
        continue;

      if(value==='Appraisal'||value==='Appraisal - Revised'||value==='Final Inspection with Photos (1004D / HUD 92051)'||(standAloneKeywords.indexOf(value)!==false&&standAloneKeywords.indexOf(value)!==-1)){
        hasAppraisal = true;

        let name = state.toUploadFiles[i].name;
        name = name.substr(name.length-3,3);
        if(name.toLowerCase()==='xml')
          hasXmlReport = true;
        else if(name.toLowerCase()==='pdf')
          hasPdfReport = true;
      }
      if(value==='Appraisal ENV'){
        hasENVReport = true;
      }

      //extract PDF for standalone XML form like 1004D
      if(standAloneKeywords.indexOf(value)!==-1){
        let name = state.toUploadFiles[i].name;
        name = name.substr(name.length-3,3);

        if(name.toLowerCase()==='xml')
          extractXml = true;
      }

      if(value==='Invoice - Appraiser' && state.toUploadFiles.length===1){
        xmlRequired = false;
      }
    }
  }


  if(xmlRequired){
    if(!hasXmlReport){
      preCheck = false;
      errorMessage = '*Please upload the XML as well.';
    }
  }

  if(!hasPdfReport&&hasXmlReport)
    extractXml = true;
    console.log(props.envRequired);
    console.log(hasENVReport);
  if(props.envRequired&&!hasENVReport){
    preCheck = false;
    errorMessage = '*Please upload the ENV as well. Make sure to mark the file type as “Appraisal ENV”';
  }


  if(state.toUploadFiles.length<=0){
    preCheck = false;
    errorMessage = 'Please upload at least one file.';
  }

  if (preCheck) {
    setState({errorMessage:'Uploading...Please do not close the window.'});
    let email = localStorage.getItem('email');
    console.log(email);
    let userID = localStorage.getItem('userID');
    let name = localStorage.getItem('firstName')+' '+localStorage.getItem('lastName');

      setState({ errorMessage: 'Uploading...Please do not close the window.' });
      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        // Create a promise that resolves when FileReader finishes reading the file
        const readFile = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject('File reading failed');
            reader.readAsBinaryString(file);
          });
        };

        try {
          // Wait for the file to be read
          const fileAsBinaryString = await readFile(state.toUploadFiles[i]);
          let base64 = btoa(fileAsBinaryString);

          let callBack = (response)=>{console.log(response);
            let code = response.data.code;

            if(code==='00'){
              let toUploadFiles = state.toUploadFiles.slice();
              toUploadFiles[i].status='Done';
              
              setState({toUploadFiles:toUploadFiles});
              //update the list of uploaded file
              setState({appraisalFiles:response.data.data}, "CONCAT_SET_STATES");
            }
            if(response.data.wrong_address){
              setState({wrongFileMessage:response.data.wrong_address});
              toggleWrongFile();
            }

            fileCompleted++;
            if(fileCompleted>=state.toUploadFiles.length){
              setState({uploadDisabled:false,errorMessage:'', modal:false});

              if(hasAppraisal)
                props.updateStatus('Review');
            }
          };
          
          let errorCallBack = ()=>{
            fileCompleted++;

            if(fileCompleted>=state.toUploadFiles.length){
              setState({uploadDisabled:false,errorMessage:'', modal:false});
            }
          };

          let parameters = [
            {
              field:'appraisal_fk',
              value:props.appraisalFk
            },
            {
              field:'file_type',
              value:fileTypes[i]
            },
            {
              field:'upload_type',
              value:props.uploadType
            },
            {
              field:'upload_userfk',
              value:userID
            },
            {
              field:'upload_useremail',
              value:email
            },
            {
              field:'upload_username',
              value:name
            },
            {
              field:'name',
              value:state.toUploadFiles[i].name
            }
            ,{
              field:'file_encoded',
              value:base64
            }
          ];

          let fileName = state.toUploadFiles[i].name;
          fileName = fileName.substr(fileName.length-3,3);

          if((fileTypes[i]==='Appraisal'||fileTypes[i]==='Appraisal - Revised'||standAloneKeywords.indexOf(fileTypes[i])!==false)&&fileName.toLowerCase()==='xml'&&extractXml){
            let tmp = {
              field:'extract_xml',
              value:'yes'
            };

            parameters.push(tmp);
          }

          await httpPost('file/upload', parameters, `File "${state.toUploadFiles[i].name}" uploaded successfully.`, `Oops, something went wrong and could not upload the file "${state.toUploadFiles[i].name}". Please try again later.`, callBack, errorCallBack);
          await wait(500);
  
        } catch (error) {
          showMessage('error', 'File upload failed, please try again later.');
        } finally {
          
        }
      }
    }
    else{
      setState({errorMessage:errorMessage, uploadDisabled:false});
    }
  };

  const getAppraisalFiles = () => {
    const callBack = (response) => {
      
        const code = response.data.code;
        if (code === '00') {
            setState({ appraisalFiles: response.data.data });
        }
    };
    httpGet('file/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  };

  const getFileTypes = () => {
    const callBack = (response) => {
        const code = response.data.code;
        if (code === '00') {
            setState({ fileTypes: response.data.data });
        }
    };

    httpGet('file/fileType/get', '', 'Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  };

  //render
  let appraisalFiles;
  let toUploadFiles;

  let idCounter = 0;
  if(state.appraisalFiles.length>0){
    appraisalFiles = state.appraisalFiles.map(
      (appraisalFile, outterIndex)=>{
        let recipients = appraisalFile.recipients.map(
          (entity,index)=>{
            idCounter++;
            if(entity.associated_name===""){
              return(
                <div key={index} className="display-inline file-entity">
                  <a id={"fileAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} name={"?"}/></a>&nbsp;
                  <UncontrolledTooltip placement="top" target={"fileAvatar"+idCounter}>
                    {entity.associated_email}
                  </UncontrolledTooltip>
                </div>
              );
            }
            else{
              return(
                <div key={index} className="display-inline file-recipient">
                  <a id={"fileAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} color={"#"+stringToHexCode(entity.associated_email)} name={entity.associated_name}/></a>&nbsp;
                  <UncontrolledTooltip placement="top" target={"fileAvatar"+idCounter}>
                    {entity.associated_name+" - "+entity.associated_email}
                  </UncontrolledTooltip>
                </div>
              );
            }
          }
        );

        return(
          <div key={outterIndex}>
            <div className="file-container">
                <div className="font-bold">
                <b>&nbsp;{appraisalFile.file_type} <i>{appraisalFile.description}</i></b>
                <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadFile(appraisalFile.name)}><b>{appraisalFile.name}</b></div>
              </div>
              <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{appraisalFile.datetime_created} - {appraisalFile.uploader_name}</div></font>

              <div className="display-inline" >
                {recipients}
              </div>
            </div>
          </div>
        );
      }
    );
  }

  let filesTypesOpt;

  if(state.fileTypes.length>0){
    filesTypesOpt = state.fileTypes.map(
      (fileType, index)=>{
        return(
          <option key={index} value={fileType.name}>{fileType.name}</option>
        );
      }
    );
  }

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <Input type="text" value={file.description} onChange={(e)=>onFileDescriptionChange(file.name, e.target.value)}/>
            </td>
            <td>
              <select value={file.fileType} className="form-control" onChange={(e)=>{onFileTypeChange(file.name,e.target.value)}}>
                <option value=""></option>
                {filesTypesOpt}
              </select>
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let bigFileList = state.bigFiles.join(', ');

  return (
    <div>
      <div className="file-panel">
        <div>
          <div className="file-container">
              <div className="font-bold">
              <div className="display-inline cursor-pointer" ></div><b>Invoice - Home VMS</b>
              <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAllInOneInvoice()}><b>Invoice - Latest</b></div>
            </div>
          </div>
        </div>
        {appraisalFiles}
      </div>
      <div className="my-diviver"></div>
      <div className="align-right">
        <br/>
        <Button color="warning" className="cursor-pointer" onClick={toggle}><i className="fa fa-plus"></i> Upload File</Button>
        <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
          <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
            </center>

            <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
            <div>{bigFileList}</div>

            <br/><br/>
            <center>
              <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
            </center>
          </ModalBody>
        </Modal>
        <Modal className="my-modal-wide" isOpen={state.modal} toggle={toggle} >
          <ModalHeader hidden={true} toggle={toggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-file"></i> New Document</h5>
            </center>
            <br/>

            <div>

              <MyDropzone onDrop={onDrop}/>
              <br/>
              <div className="my-divider"></div>
              <div className="small-scroll-container red-color flashit" style={{minHeight:'30px',height:'30px ',maxHeight:'30px'}}>
                <b>{state.errorMessage}</b>
              </div>
              <div className="medium-scroll-container">
                <table className="table file-list-table" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th width="25%">Name</th>
                      <th width="10%">Description</th>
                      <th width="30%">File Type</th>
                      <th width="15%">Size</th>
                      <th width="10%"><center>Status</center></th>
                      <th width="10%">Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {toUploadFiles}
                  </tbody>
                </table>
              </div>
              <br/>

              <center>
                <Button color="warning" disabled={state.uploadDisabled!==false} onClick={uploadButtonHandler}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
                <Button color="info" onClick={toggle}>Close</Button>
              </center>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}


export default AppraisalFile;
