//Author June Leow
//Date 04/18/2023
import React, { useEffect, useReducer } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { callBackGenerator, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator, showMessage } from '../../util/util';
import MySelect from '../util/my-select';

import { useNavigate } from 'react-router-dom';

//initialize the state
const initialState = {
  email:localStorage.getItem('email'),
  categories:[],
  criticalLevels: [{label:'Minor - Issue is non-essential but should be fixed',value:'Minor'},{label:'Major - Essential and will need to be fixed',value:'Major'},{label:'Critical - System failure and requires immediate attention',value:'Critical'}],
  issue:'',
  description:'',
  criticalLevel:'',
  category:'',
  toUploadFiles:[],
  disabled:false
};

//reducer function that perform state update
const reducer = getReducer();


const NewTicket  = (props)=>{
  const controller = new AbortController();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{

    getTicketCategory();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.disabled){
      createTicket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const buttonHandler = ()=>{
    if(state.disabled)
      return;

    setState({disabled:true});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview)=>{
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles)=>{
    let existingFiles = state.toUploadFiles.slice();

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate)
        existingFiles.push(file);
    }
    setState({toUploadFiles: existingFiles});
  }

  //API call
  const getTicketCategory = ()=>{
    let url = 'ticket/category';
    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'categories',key:'data'}]);
    //let callBack = apiCallBack([{state:'stateName',value:'some value'}]);
    httpGet(url, '','Oops, something went wrong and could not load ticket category. Please try again later.', callBack);
  }

  const createTicket = ()=>{
    setState({disabled:false});
    let url = 'ticket/create';
    let parameters = [
      {
        field:'issue',
        value:state.issue
      },
      {
        field:'description',
        value:state.description
      },
      {
        field:'criticalLevel',
        value:state.criticalLevel
      },
      {
        field:'category',
        value:state.category
      },
      {
        field:'email',
        value:state.email
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00')
        navigate('/support/ticket');
    };

    if(state.toUploadFiles.length>0){
      let files = [];
      for(let i=0;i<state.toUploadFiles.length;i++){
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result;
          let base64 = btoa(fileAsBinaryString);

          let tmp = {};
          tmp.base64 = base64;
          tmp.name = state.toUploadFiles[i].name;

          files.push(tmp);
          if(files.length>=state.toUploadFiles.length){

            let tmp = {
              field:'files',
              value: files
            }

            parameters.push(tmp);


            httpPost(url, parameters, 'Ticket submitted successfully.','Oops, something went wrong and could not submit the support ticket. Please try again later.', callBack);
          }
        };

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
    }
    else{
      httpPost(url, parameters, 'Ticket submitted successfully.','Oops, something went wrong and could not submit the support ticket. Please try again later.', callBack);
    }
  }

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return <div key={index} className="display-inline"><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i> {file.name}&nbsp;&nbsp;</div>
      }
    );
  }

  //render
  return <div className="my-well">
    <div className="page-title">
      <i className="fa fa-question-circle"></i>&nbsp;Help & Support - New Ticket
    </div>
    <div className="my-divider">&nbsp;</div>

    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-info"></i> Ticket
      </CardHeader>
      <CardBody>
        <form onSubmit={createTicket}>
          <Row className="margin-bottom">
            <Col sm="4">
              <label><font color="red">*</font>Critical Level</label>
            </Col>
            <Col sm="8">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.criticalLevel}
                onChange={(v)=>{setState({criticalLevel:v})}}
                options={state.criticalLevels.map((level)=>{
                  return {label:level.label, value:level.value};
                })}
              />
            </Col>
          </Row>
          <Row className="margin-bottom">
            <Col sm="4">
              <label><font color="red">*</font>Category</label>
            </Col>
            <Col sm="8">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.category}
                onChange={(v)=>{setState({category:v})}}
                options={state.categories.map((category)=>{
                  return {label:category.category, value:category.category};
                })}
              />
            </Col>
          </Row>
          <Row className="margin-bottom">
            <Col sm="4">
              <label><font color="red">*</font>Issue</label>
            </Col>
            <Col sm="8">
              <Input type="text" required="true" value={state.issue} onChange={(e)=>setState({issue:e.target.value})}/>
            </Col>
          </Row>
          <Row className="margin-bottom">
            <Col sm="4">
              <label><font color="red">*</font>Description</label>
            </Col>
            <Col sm="8">
              <Input type="textarea" required="true" rows="4" style={{resize:'none'}} value={state.description} onChange={(e)=>setState({description:e.target.value})}/>
            </Col>
          </Row>
          <br/>
          <label>Attachment(s)</label>
          <div className="small-scroll-container">
            {toUploadFiles}
          </div>
          <Dropzone onDrop={onDrop} className="my-dropzone">
          {
            ({getRootProps, getInputProps}) => (
              <div className="container">
                <div
                  {...getRootProps({
                    className: 'my-dropzone',
                    onDrop: event => {event.stopPropagation();onDrop();}
                  })}
                >
                  <input {...getInputProps()} />
                  <center>
                    <font style={{fontSize:'30px'}}>
                      <i className="fa fa-cloud-upload link-color"></i>
                    </font>
                    <div>
                      <i><b>OR drag and drop file here.</b></i>
                    </div>
                  </center>
                </div>
              </div>
            )
          }
          </Dropzone>


          <br/>
          <div className="align-right">
            <Button color="warning" disabled={state.disabled!==false} onClick={buttonHandler}>Submit</Button>
          </div>
        </form>
      </CardBody>
    </Card>
  </div>;
}

export default NewTicket;
