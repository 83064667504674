//Author June Leow
//Date June 12th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import { callBackGenerator, confirmation, deleteAPICallGenerator, getAPICallGenerator, getReducer, getSetStateFunction, putAPICallGenerator } from '../../util/util';
//initialize the state
const initialState = {
  templateTags:[],
  template:{},

  templateName:'',
  templateText:''
};

//reducer function that perform state update
const reducer = getReducer();


const Template  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id,
  });
  
  const history = useNavigate();
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTemplate();
    getTemplateTags();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const addTag=(e,tag)=>{
    e.preventDefault();
    setState({templateText:state.templateText+' [:'+tag+']'});
  }

  //API call
  const deleteTemplate=()=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        history("/setting");
      }
    };
    httpDelete('template/'+state.id, 'Template deleted successfully.','Oops, something went wrong and could not delete the template. Please try again later.', callBack);
  }

  const getTemplateTags=()=>{
    const callBack = apiCallBack([{state:'templateTags', key:'data'}]);
    httpGet('template/tag/get', '', 'Oops, something went wrong and could not load tags for template. Please try again later.', callBack);
  }

  const updateTemplate=()=>{
    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'name',
        value:state.templateName
      },
      {
        field:'template',
        value:state.templateText
      }
    ];
    httpPut('template/update', parameters, 'Template update successfully.', 'Oops, something went wrong and could not update the template. Please try again later.', ()=>{});
  }

  const getTemplate=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({templateName:response.data.data.name, templateText:response.data.data.template});
      }
    };
    httpGet('template/'+state.id, '', 'Oops, something went wrong and could not load tags for template. Please try again later.', callBack);
  }

  //render
  let templateTags;

  if(state.templateTags.length>0){
    templateTags = state.templateTags.map(
      (tag, index)=>{
        return(
          <div key={index} className="display-inline template-tag" onClick={(e)=>{addTag(e,tag.tag);return false;}}>
            {tag.tag}
          </div>
        );
      }
    );
  }

  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Settings
          </div>
          <NavLink to="/setting">Back to settings</NavLink>
        </Col>
        <Col sm="6">
          <div className="align-right">
            <Button color="danger" onClick={
              ()=>{
                confirmation(
                  ()=>{deleteTemplate()},
                  ()=>{},
                  'Delete template?',
                  'Are you sure you want to delete this template?');
              }
            }><i className="fa fa-trash"></i>&nbsp;&nbsp;Delete</Button>&nbsp;&nbsp;
            <Button color="warning" onClick={updateTemplate}><i className="fa fa-edit"></i>&nbsp;&nbsp;Update</Button>
          </div>
        </Col>
      </Row>
      <div className="my-divider"></div>
      <label><font color="red">*</font>Name</label>
      <Input required={true} type="text" value={state.templateName} onChange={(e)=>setState({templateName:e.target.value})}/>

      <label><font color="red">*</font>Template</label>
      <Input required={true} type="textarea" rows="10" style={{resize:'none'}} value={state.templateText} onChange={(e)=>setState({templateText:e.target.value})}/>
      <br/>
      <b>Tags:</b> {templateTags}
    </div>
  );
}


export default Template;
