//Author June Leow
//Date Jul 15th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import { getReducer, getSetStateFunction, postAPICallGenerator, showMessage } from '../../util/util';
//initialize the state
const initialState = {
  session: {},
  errorMessage:'',
  email:'',
  role:'',
  fname:'',
  lname:'',
  phone:'',
  newUserPassword1:'',
  newUserPassword2:'',
  formValid:false,
  warningMessage:'',
  showPassword:false,
  isFocused:false,
};

//reducer function that perform state update
const reducer = getReducer();


const SignUp  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  let clientFk = '';
  let branchFk = '';
  try {
    let params = useParams().params;
    params = atob(params);

    console.log(params);

    if(params&&params!==''){
      params = params.split('&');
      if(params){
        for(let i=0;i<params.length;i++){
          let token = params[i].split('=');
          token[1] = token[1].replace('%ForwardSlash', '/');
          token[1] = token[1].replace('%Ampersand', '&');
          token[1] = decodeURIComponent(token[1]);

          switch(token[0]){
            case 'clientFk':
              clientFk = token[1];
              break;
            case 'branchFk':
              branchFk = token[1];
              break;
          }
        }
      }
    }
  }
  catch(error) {
    history('/');
  }

  let newInitialState = Object.assign({}, initialState, {
    clientFk:clientFk,
    branchFk:branchFk
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    if(props.isLoggedIn){
      history('/appraisal');
    }
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handlePasswordFocus = () => {
    setState({ isFocused: true });
  };

  const handlePasswordBlur = () => {
    setState({ isFocused: false });
  };

  const handleToggle = () => {
    setState({ showPassword: !state.showPassword });
  }

  const isPasswordValid = (password, type='') => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCharacters = /[!@#$%^&*]/g;
    const isLowerCaseValid = password.match(lowerCaseLetters);
    const isUpperCaseValid = password.match(upperCaseLetters);
    const isNumberValid = password.match(numbers);
    const isSpecialCharacterValid = password.match(specialCharacters);
    const isLengthValid = password.length >= 12;

    if(type === 'lowercase'){
      return(isLowerCaseValid);
    }else if(type === 'uppercase'){
      return(isUpperCaseValid);
    }else if(type === 'number'){
      return(isNumberValid);
    }else if(type === 'special'){
      return(isSpecialCharacterValid);
    }else if(type === 'length'){
      return(isLengthValid);
    }else{
      return (
        isLowerCaseValid &&
        isUpperCaseValid &&
        isNumberValid &&
        isSpecialCharacterValid &&
        isLengthValid
      );
    }
  };

  const checkPassword = (password1, password2)=>{
    if(!isPasswordValid(password1)){
      setState({warningMessage:'Please match the required format.', formValid:false});
    }
    else if(password1!==password2){
      setState({warningMessage:'Your password does not match.', formValid:false});
    }
    else{
      setState({warningMessage:'', formValid:true});
    }
  }

  //API call
  //prevent the onSubmit and call login function
  const onSubmit=(e)=>{
    e.preventDefault();
    if(state.formValid){
      let callBack = (response)=>{
        let code = response.data.code;

        if(code!=='00'){
          setState({errorMessage:response.data.message});
        }
        else{
          history('/');

          showMessage('success','Account created successfully. Please login.');
          setState({errorMessage:response.data.message});
        }
      };
      let parameters = [
        {
          field:'client_fk',
          value:state.clientFk
        },
        {
          field:'branch_fk',
          value:state.branchFk
        },
        {
          field:'first_name',
          value:state.fname
        },
        {
          field:'last_name',
          value:state.lname
        },
        {
          field:'role',
          value:state.role
        },
        {
          field:'email',
          value:state.email
        },
        {
          field:'phone',
          value:state.phone
        },
        {
          field:'author',
          value:'New Branch User'
        },
        {
          field:'password',
          value:state.newUserPassword1
        }
      ];
      httpPost('client/branch/user/create', parameters, '', '', callBack);
    }
  }

  //render
  const isLowerCaseValid = isPasswordValid(state.newUserPassword1, 'lowercase');
  const isUpperCaseValid = isPasswordValid(state.newUserPassword1, 'uppercase');
  const isSpecialCharacterValid = isPasswordValid(state.newUserPassword1, 'special');
  const isNumberValid = isPasswordValid(state.newUserPassword1, 'number');
  const isLengthValid = isPasswordValid(state.newUserPassword1, 'length');

  let valid = <i className="fa fa-check-circle"></i>;
  let invalid = <i className="fa fa-times-circle"></i>;

  let warningMessage = <div>&nbsp;</div>;

  if(state.warningMessage!=='')
    warningMessage = <div className="display-inline"><font color="red">*</font>{state.warningMessage}</div>;


  return(
    <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
      <div style={{background:'#000', padding:'10px', fontWeight:'bold', fontSize:'20px', opacity:'0.8'}}>
        &nbsp;&nbsp;&nbsp;<NavLink style={{color:'white'}} to="/appraisal/request-turnaround"><i className="fa fa-question-circle"/> Request Turnaround Time</NavLink>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <NavLink style={{color:'white'}} to="/"><i className="fa fa-user"/> Login</NavLink>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <a href="https://www.homevms.com/#about-us" style={{color:'white'}}><i className="fa fa-home"/> About Us</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <a href="https://www.homevms.com/#contact" style={{color:'white'}}><i className="fa fa-envelope"/> Contact Us</a>
      </div>
      <div className="padding">
        <div className="login-container well padding">
          <form name="loginForm" onSubmit={onSubmit}>
            <br/>
            <center>
              <img alt="company logo" src="../img/logo_small.png" className="logo-login"/><br/>
              Client portal - Sign Up
            </center>
            <div className="non-empty-container">
              <p className="no-padding no-margin">{state.errorMessage}</p>
            </div>
            <div className="align-right">
              <br/>
              <NavLink to="/">Back to login</NavLink>
            </div>
            <div className="form-group">
              <Row>
                <Col sm='6'>
                  <label className="font-blue">First Name:</label>
                  <input required={true} type="text" name="firstname" autoComplete="on" onChange={e => setState({fname: e.target.value})} className="form-control"/>
                </Col>
                <Col sm='6'>
                  <label className="font-blue">Last Name:</label>
                  <input required={true} type="text" name="lastname" autoComplete="on" onChange={e => setState({lname: e.target.value})} className="form-control"/>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <label className="font-blue">Email:</label>
                  <input required={true} type="text"  name="username" autoComplete="on" onChange={e => setState({email: e.target.value})} className="form-control" id="email"/>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <label className="font-blue">Role:</label>
                  <Input required={true} type="select" value={state.role} onChange={(e)=>setState({role:e.target.value})}>
                    <option value=""></option>
                    <option value="Loan Officer">Loan Officer</option>
                    <option value="Loan Processor">Loan Processor</option>
                    <option value="Broker">Broker</option>
                    <option value="Other">Other</option>
                  </Input>
                </Col>
                <Col sm='6'>
                  <label className="font-blue">Phone:</label>
                  <input required={true} type="text"  name="phone" autoComplete="on" onChange={e => setState({phone: e.target.value})} className="form-control"/>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <label className="font-blue">Password:</label>
                  <div className="input-container">
                    <Input type={state.showPassword ? 'text' : 'password'} value={state.newUserPassword1} onChange={(e)=>{setState({newUserPassword1:e.target.value});checkPassword(e.target.value, state.newUserPassword2)}}
                      onFocus={handlePasswordFocus}
                      onBlur={handlePasswordBlur}
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number, one special character, one uppercase and lowercase letter, and at least 12 or more characters"
                      required
                    />
                    <span className="toggle-button" style={{color:'#666677'}} onClick={handleToggle}>
                      {state.showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </span>
                  </div>
                </Col>
                <Col sm='6'>
                  <label className="font-blue">Re-type Password:</label>
                  <Input required={true} type="password" value={state.newUserPassword2} onChange={(e)=>{setState({newUserPassword2:e.target.value});checkPassword(state.newUserPassword1, e.target.value)}}/>
                  <div style={{fontSize:'13px'}}>{warningMessage}</div>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  {state.isFocused && (
                    <div id="message">
                      <label>Password must contain the following:</label>
                      <p id="letter" style={{lineHeight:'3px'}} className={(isLowerCaseValid&&isUpperCaseValid)? 'green-color' : 'red-color'}>
                        {(isLowerCaseValid&&isUpperCaseValid)? valid:invalid} Combination of uppercase letters & lowercase letters
                      </p>
                      <p id="number" style={{lineHeight:'3px'}} className={isNumberValid? 'green-color' : 'red-color'}>
                        {isNumberValid? valid:invalid} At least 1 number (0-9)
                      </p>
                      <p id="special-char" style={{lineHeight:'3px'}} className={isSpecialCharacterValid? 'green-color' : 'red-color'}>
                        {isSpecialCharacterValid? valid:invalid} At least 1 special Chracter (!@#$%^&*)
                      </p>
                      <p id="length" style={{lineHeight:'3px'}} className={isLengthValid? 'green-color' : 'red-color'}>
                        {isLengthValid? valid:invalid} Minimum <b>12 characters</b>
                      </p>
                    </div>
                  )}  
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <br/>
                  <input type="submit" className="btn btn-blue-noshadow btn-block font-white" value="Create Account"></input>
                  <br/>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      /</div>
    </div>
  );
}


export default SignUp;
