//Author June Leow
//Date Jul 17th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { callBackGenerator, formatDateTime, formatNumber, getAPICallGenerator, getReducer, getSetStateFunction, postAPICallGenerator } from '../../util/util';
//initialize the state
const initialState = {
  appraisal:{
    reference_num:'',
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
    loan_num:'',
    loan_purpose:'',
    loan_type:'',
    datetime_submitted:'0000-00-00',
    datetime_expected:'0000-00-00',
    status:'',
    borrower_f_name:'',
    borrower_l_name:'',
  },
  fee: '',
  comment: '',
  status: '',
  valid: false,
  fhaCaseNumber:''
};

//reducer function that perform state update
const reducer = getReducer();


const FeeEscalation  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let appraisalFk = useParams().appraisalFk;
  let newInitialState = Object.assign({}, initialState, {
    id:id,
    appraisalFk:appraisalFk,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    getFeeEscalation();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getAppraisal = ()=>{
    const callBack = apiCallBack([{state:'appraisal',key:'data'}, {state:'valid', value:true}]);
    httpGet('appraisal/public/'+state.appraisalFk, '', 'Oops, something went wrong and could not load the appraisal information. Please try again later.', callBack);
  }

  const getFeeEscalation = ()=>{
    const callBack = apiCallBack([{state:'fee',key:'data.fee'},{state:'comment',key:'data.comment'},{state:'status',key:'data.status'}]);
    httpGet('billing/public/fee/escalation/'+state.id, '', 'Oops, something went wrong and could not load the appraisal information. Please try again later.', callBack);
  }

  const updateFeeEscalationAcceptance=(status)=>{
    let keyword = 'approv';
    if(status==='reject')
      keyword = 'reject'

    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'status',
        value:status
      }
    ];

    const callBack = apiCallBack([{state:'valid', value:false}]);
    httpPost('billing/fee/escalation/acceptance', parameters, 'Fee '+keyword+'ed successfully.','Oops, something went wrong and could not '+keyword+' the fee. Please try again later.', callBack);
  }

  //render
  let action;

  if(state.status==='')
    action = <center>Loading fee escalation information.</center>;
  else if(state.status!=='Pending')
    action = <center>Link expired due to fee escalation approved/rejected.</center>;
  else if(state.valid)
    action = <center>
      <b>Please approve/reject the fee escalation.</b><br/><br/>

      <label>New Fee</label>
      <br/>
      ${formatNumber(state.fee)}
      <br/>
      <i>{state.comment}</i>
      <br/><br/>
      <Button color="warning" onClick={()=>updateFeeEscalationAcceptance('accept')}>Approve</Button>&nbsp;&nbsp;<Button color="danger" onClick={(e)=>updateFeeEscalationAcceptance('reject')}>Reject</Button>
    </center>;
  else if(!state.valid&&state.appraisal.reference_num!=='')
    action = <center>
      Order information updated.
    </center>

  let topBar;

  if(!props.isLoggedIn){
    topBar = <div className="topbar">
    <div className="topbar-logo-container">
      <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
    </div>
  </div>;
  }


  return (
    <div>
      {topBar}
      <div className="padding">
        <Row>
          <Col sm="1">

          </Col>
          <Col sm="10">
            <Card style={{background:'#f5f5f5'}}>
              <CardHeader className="header-color">
                <center>
                    <i className="fa fa-dollars"></i> Fee Escalation
                </center>
              </CardHeader>
              <CardBody>
                <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
                  <label>Reference #:&nbsp;&nbsp;</label>
                  {state.appraisal.reference_num}

                  <br/>
                  <label>Status:&nbsp;&nbsp;</label>
                  {state.appraisal.status}

                  <br/>
                  <label>Property address:&nbsp;&nbsp;</label>
                  {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}

                  <br/>
                  <label>Borrower:&nbsp;&nbsp;</label>
                  {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}

                  <br/>
                  <label>Loan purpose:&nbsp;&nbsp;</label>
                  {state.appraisal.loan_purpose}

                  <br/>
                  <label>Loan type:&nbsp;&nbsp;</label>
                  {state.appraisal.loan_type}

                  <br/>
                  <label>Loan #:&nbsp;&nbsp;</label>
                  {state.appraisal.loan_num}

                  <br/>
                  <label>Date submitted:&nbsp;&nbsp;</label>
                  {formatDateTime(state.appraisal.datetime_submitted)}

                  <br/>
                  <label>Date Expected:&nbsp;&nbsp;</label>
                  {formatDateTime(state.appraisal.datetime_expected)}

                  <br/>
                </div>
                <div className="my-divider"></div>
                <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
                  <br/>
                  {action}
                  <br/>
                </div>
                <br/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}


export default FeeEscalation;
