//Author June Leow
//Date Jul 17th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col, Input, Button} from 'reactstrap';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  appraisal:{
    reference_num:'',
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
    loan_num:'',
    loan_purpose:'',
    loan_type:'',
    datetime_submitted:'0000-00-00',
    status:'',
    borrower_f_name:'',
    borrower_l_name:'',
  },
  valid: false,
  fhaCaseNumber:''
};

//reducer function that perform state update
const reducer = getReducer();


const UpdateFHACaseNumber  = (props)=>{
  const controller = new AbortController();
  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getAppraisal = ()=>{
    const callBack = apiCallBack([{state:'appraisal',key:'data'}, {state:'valid', value:true}]);
    httpGet('appraisal/public/'+state.id, '', 'Oops, something went wrong and could not load the appraisal information. Please try again later.', callBack);
  }

  const updateFHACaseNumber=()=>{
    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'fhaCaseNumber',
        value:state.fhaCaseNumber
      }
    ];

    const callBack = apiCallBack([{state:'valid', value:true}]);
    httpPut('appraisal/fhaCaseNumber/update', parameters, 'FHA case number updated successfully.', 'Oops, something went wrong and could not update the FHA case number. Please try again later.', callBack);
  }

  //render
  let action;

  if(state.valid)
    action = <center>
      <b>Please update the FHA case number. Please use specify in this format: "XXX-XXXXXXX"</b><br/><br/>
      <Row>
        <Col sm="3">
          <label>FHA case number</label>
        </Col>
        <Col sm="6">
          <Input type="text" className="form-control" placeholder="231-2939490" value={state.fhaCaseNumber} onChange={(e)=>setState({fhaCaseNumber:e.target.value})}/>
        </Col>
        <Col sm="3" className="align-right">
          <Button style={{width:'170px'}} color="warning" onClick={updateFHACaseNumber}><i className="fa fa-check"></i> Update</Button>&nbsp;&nbsp;&nbsp;&nbsp;
        </Col>
      </Row>

    </center>;
  else if(!state.valid&&state.appraisal.reference_num!=='')
    action = <center>
      Order information updated.
    </center>
  
  let topBar;

  if(!props.isLoggedIn){
    topBar = <div className="topbar">
      <div className="topbar-logo-container">
        <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
      </div>
    </div>;
  }


  return (
    <div>
      {topBar}
      <div className="padding">
        <Row>
          <Col sm="1">

          </Col>
          <Col sm="10">
            <Card style={{background:'#f5f5f5'}}>
              <CardHeader className="header-color">
                <center>
                    <i className="fa fa-edit"></i> FHA case number
                </center>
              </CardHeader>
              <CardBody>
                <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
                  <label>Reference #:&nbsp;&nbsp;</label>
                  {state.appraisal.reference_num}

                  <br/>
                  <label>Status:&nbsp;&nbsp;</label>
                  {state.appraisal.status}

                  <br/>
                  <label>Property address:&nbsp;&nbsp;</label>
                  {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}

                  <br/>
                  <label>Borrower:&nbsp;&nbsp;</label>
                  {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}

                  <br/>
                  <label>Loan purpose:&nbsp;&nbsp;</label>
                  {state.appraisal.loan_purpose}

                  <br/>
                  <label>Loan type:&nbsp;&nbsp;</label>
                  {state.appraisal.loan_type}

                  <br/>
                  <label>Loan #:&nbsp;&nbsp;</label>
                  {state.appraisal.loan_num}

                  <br/>
                  <label>Date submitted:&nbsp;&nbsp;</label>
                  {formatDateTime(state.appraisal.datetime_submitted)}

                  <br/>
                </div>
                <div className="my-divider"></div>
                <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
                  <br/>
                  {action}
                  <br/>
                </div>
                <br/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}


export default UpdateFHACaseNumber;
