//Author June Leow
//Date June 6th, 2024
import Avatar from "react-avatar-edit";
import React, { useEffect, useReducer } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Toggle from 'react-toggle';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { callBackGenerator, confirmation, deleteAPICallGenerator, formatDate, getAPICallGenerator, getReducer, getSession, getSetStateFunction, postAPICallGenerator, sliceFromArray, showMessage } from '../../util/util';
import './setting.css';
//initialize the state
let role = localStorage.getItem('role');
let clientID = localStorage.getItem('clientID');
let showUpload = (!localStorage.getItem('profilePhoto') || localStorage.getItem('profilePhoto')==='');
let profileUrl = localStorage.getItem('profilePhoto')?localStorage.getItem('profilePhoto'):'';
console.log(clientID)
const initialState = {
  profileUrl:profileUrl,
  showUpload:showUpload,
  templateTags:[],
  templates:[],
  newTemplateName:'',
  newTemplateText:'',
  appraiser:{},
  addNewTemplateDropDownOpen:false,
  appraiserAboutMePopUp:false,

  failedMessage:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  emailSettings:[],
  branches:[],
  role:role,
  clientID:clientID,

  oldPassword:'',
  newPassword:'',
  showPassword:false,
  isFocused:false,
  confirmNewPassword:''
};

//reducer function that perform state update
const reducer = getReducer();

const PersonalSetting  = (props)=>{
  const controller = new AbortController();

  let param = useParams().param;
  let scrollToBottom = false;
  if(param && param==='password'){
    scrollToBottom = true;
  }

  let newInitialState = Object.assign({}, initialState, {
    scrollToBottom:scrollToBottom
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTemplates();
    if(role==='client_master')
      getBranches();
    getTemplateTags();
    getEmailNotificationSettings();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handlePasswordChange = (e) => {
    setState({ newPassword: e.target.value });
  };

  const handlePasswordFocus = () => {
    setState({ isFocused: true });
  };

  const handlePasswordBlur = () => {
    setState({ isFocused: false });
  };

  const handleToggle = () => {
    setState({ showPassword: !state.showPassword });
  }

  const emailNotificationToggle=(subject)=>{
    for(let i=0;i<state.emailSettings.length;i++){
      if(state.emailSettings[i].subject===subject){
        if(state.emailSettings[i].blocked==='yes')
          unblockEmail(subject);
        else
          blockEmail(subject);
      }
    }
  }

  const toggleAddNewTemplateDropDown=()=>{
    setState({addNewTemplateDropDownOpen:!state.addNewTemplateDropDownOpen});
  }

  const addTag=(e,tag)=>{
    e.preventDefault();
    setState({newTemplateText:state.newTemplateText+' [:'+tag+']'});
  }

  const onClose = () => {
    setState({ photoPreview: null });
  };

  const onCrop = (preview) => {
    setState({ photoPreview: preview });
  };

  const onBeforeFileLoad = (elem) => {
    console.log(elem.target.files[0].size);
    if (elem.target.files[0].size > 15000000) {
      showMessage('error', 'Photo is too big.');
      elem.target.value = '';
    }
  };


  const isPasswordValid = (password, type='') => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCharacters = /[!@#$%^&*]/g;
    const isLowerCaseValid = password.match(lowerCaseLetters);
    const isUpperCaseValid = password.match(upperCaseLetters);
    const isNumberValid = password.match(numbers);
    const isSpecialCharacterValid = password.match(specialCharacters);
    const isLengthValid = password.length >= 12;

    if(type === 'lowercase'){
      return(isLowerCaseValid);
    }else if(type === 'uppercase'){
      return(isUpperCaseValid);
    }else if(type === 'number'){
      return(isNumberValid);
    }else if(type === 'special'){
      return(isSpecialCharacterValid);
    }else if(type === 'length'){
      return(isLengthValid);
    }else{
      return (
        isLowerCaseValid &&
        isUpperCaseValid &&
        isNumberValid &&
        isSpecialCharacterValid &&
        isLengthValid
      );
    }
  };

  //API call
  const blockEmail=(subject)=>{
    let parameters = [
      {
        field:'subject',
        value:subject
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let emailSettings = state.emailSettings.slice();
        for(let i=0;i<emailSettings.length;i++){
          if(emailSettings[i].subject===subject){
            emailSettings[i].blocked='yes';
            break;
          }
        }

        setState({emailSettings:emailSettings});
      }
    };
    httpPost('email/block', parameters, 'Email notification disabled.', 'Oops, something went wrong and could not disable the email notification. Please try again later.', callBack);
  }

  const unblockEmail=(subject)=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let emailSettings = state.emailSettings.slice();
        for(let i=0;i<emailSettings.length;i++){
          if(emailSettings[i].subject===subject){
            emailSettings[i].blocked='no';
            break;
          }
        }

        setState({emailSettings:emailSettings});
      }
    };
    httpDelete('email/'+subject, 'Email notification enabled succesfully.', 'Oops, something went wrong and could not enable the email notification. Please try again later.', callBack);
  }

  const getEmailNotificationSettings=()=>{
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        setState({emailSettings:response.data.data});
      }
    };
    httpGet('email/get','','Oops, something went wrong and could not load email notification settings. Please try again later.', callBack);
  }

  const updatePassword=()=>{
    if(state.oldPassword===''){
      setState({changePasswordMessage:'Please provide your current password.'});
    }
    else if(!isPasswordValid(state.newPassword)){
      setState({changePasswordMessage:'Please match the required format.'});
    }
    else if(state.newPassword!==state.confirmNewPassword){
      setState({changePasswordMessage:'Your new password does not match.'});
    }
    else{
     
      let parameters = [
        {
          field:'oldPassword',
          value:state.oldPassword
        },
        {
          field:'newPassword',
          value:state.newPassword
        }
      ];

      let callBack = (response)=>{
        let code= response.data.code;

        if(code==='00'){
          setState({newPassword:'', confirmNewPassword:'', oldPassword:'', changePasswordMessage:''});
        }
      };
      httpPost('session/updatePassword', parameters, 'Password updated.', 'Oops, something went wrong and could not update your password. Please try again later.', callBack);
    }
  }

  const uploadProfilePhoto = () => {
    const parameters = [{ field: 'photo', value: state.photoPreview }];

    const callBack = (response) => {
        const code = response.data.code;
        if (code === '00') {
            const data = response.data.data;
            localStorage.setItem('profilePhoto', data);
            setState({ profileUrl: data });
            window.location.reload();
        }
    };

    httpPost('user/photo/upload', parameters, 'Profile photo uploaded.', 'Oops, something went wrong and could not upload your profile photo. Please try again later.', callBack);
};

  const addNewTemplate=(e)=>{
    e.preventDefault();
    let parameters = [
      {
        field:'name',
        value:state.newTemplateName
      },
      {
        field:'template',
        value:state.newTemplateText
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let templates = state.templates.slice();
        templates.push(response.data.data);

        setState({templates:templates});
        toggleAddNewTemplateDropDown();
      }
    };
    httpPost('template/create', parameters, 'Template created successfully.','Oops, something went wrong and could not create the template. Please try again later.', callBack);
  }

  const getTemplates=()=>{
    let callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet('template/get/-1','','Oops, something went wrong and could not load your templates. Please try again later.', callBack);
  }

  const getTemplateTags=()=>{
    let callBack = apiCallBack([{state:'templateTags', key:'data'}]);
    httpGet('template/tag/get','','Oops, something went wrong and could not load tags for template. Please try again later.', callBack);
  }

  const deleteTemplate=(id)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let templates = sliceFromArray(state.templates, 'ID', id);
        setState({templates:templates});
      }
    };

    httpDelete('template/'+id, 'Template deleted successfully.', 'Oops, something went wrong and could not delete the template. Please try again later.', callBack);
  }

  const getBranches=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({branches:response.data.data});
      }
    };
    
    httpGet('client/branch/get/'+state.clientID,'','Oops, something went wrong and could not load list of available states. Please try again later.', callBack);
  }

  //render
  let profileContent;

  if(state.showUpload){
    profileContent = <div>
      <Avatar
        width={300}
        height={250}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        src={state.photoSrc}
        label="Click to upload photo"
      />
      <br/>
      <Button color="info" onClick={(e)=>setState({showUpload:false})}><i className="fa fa-times"/> Cancel</Button>&nbsp;&nbsp;<Button color="warning" onClick={uploadProfilePhoto}><i className="fa fa-upload"/> Upload</Button>
    </div>
  }
  else{
    if(state.profileUrl && state.profileUrl!==''){
      profileContent = <div>
        <div className="align-left">
          <NavLink to="#" onClick={(e)=>setState({showUpload:true})}><i className="green-color fa fa-plus"/> Upload Profile Photo</NavLink>
          <br/><br/>
        </div>
        <img src={state.profileUrl} width="200" style={{marginTop:'13px'}}/>
        <br/><br/><br/>
      </div>
    }
  }

  const isLowerCaseValid = isPasswordValid(state.newPassword, 'lowercase');
  const isUpperCaseValid = isPasswordValid(state.newPassword, 'uppercase');
  const isSpecialCharacterValid = isPasswordValid(state.newPassword, 'special');
  const isNumberValid = isPasswordValid(state.newPassword, 'number');
  const isLengthValid = isPasswordValid(state.newPassword, 'length');

  let valid = <i className="fa fa-check-circle"></i>;
  let invalid = <i className="fa fa-times-circle"></i>;

  let branches;
  if(state.role==='client_master')
    branches =
    <div>
      <br/>
      <Card>
        <CardHeader className="header-color">
          Manager User Accounts
        </CardHeader>
        <CardBody>
          <Table className="table table-striped">
            <tbody>
              {
                state.branches.map(
                  (branch,index)=>{
                    return(
                      <tr key={index}>
                        <td>
                          <NavLink to={"/branch/"+branch.ID}>{branch.branch_name}</NavLink><br/>
                          {branch.street+' '+branch.city+', '+branch.state+' '+branch.zip}
                        </td>
                      </tr>
                    )
                  }
                )
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <br/>
    </div>


  let templateTags;

  if(state.templateTags.length>0){
    templateTags = state.templateTags.map(
      (tag, index)=>{
        return(
          <div key={index} className="display-inline template-tag" onClick={(e)=>{addTag(e,tag.tag);return false;}}>
            {tag.tag}
          </div>
        );
      }
    );
  }
  let templates;

  if(state.templates.length>0){
    templates = state.templates.map(
      (template, index)=>{
        return(
          <tr key={index}>
            <td width="80%"><i className="fa fa-trash red-color cursor-pointer" onClick={
              ()=>{
                confirmation(
                  ()=>{deleteTemplate(template.ID)},
                  ()=>{},
                  'Delete template?',
                  'Are you sure you want to delete this template?');
              }
            }></i>&nbsp;&nbsp;<NavLink to={"/setting/template/"+template.ID}>{template.name}</NavLink></td><td><div className="align-right">{formatDate(template.datetime_created)}</div></td>
          </tr>
        )
      }
    );
  }


  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Settings
          </div>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <br/>
      <Row>
        <Col sm="8">
          <div className="card">
            <div className="card-header header-color">
              <Row>
                <Col sm="8">
                  <i className="fa fa-edit"></i>&nbsp;Templates
                </Col>
                <Col sm="4">
                  <div className="align-right">
                    <div className="display-inline cursor-pointer" onClick={toggleAddNewTemplateDropDown}>
                      <i className="fa fa-plus green-color"></i> Add new template
                    </div>
                  </div>
                  <Modal className="my-modal" isOpen={state.addNewTemplateDropDownOpen} toggle={toggleAddNewTemplateDropDown} >
                    <ModalHeader hidden={true} toggle={toggleAddNewTemplateDropDown}></ModalHeader>
                    <ModalBody>
                      <center>
                        <h5><i className="fa fa-plus"></i> Add new template</h5>
                      </center>
                      <br/>
                      <form onSubmit={addNewTemplate}>
                        <label><font color="red">*</font>Name</label>
                        <Input required={true} type="text" value={state.newTemplateName} onChange={(e)=>setState({newTemplateName:e.target.value})}/>

                        <label><font color="red">*</font>Template</label>
                        <Input required={true} type="textarea" rows="10" style={{resize:'none'}} value={state.newTemplateText} onChange={(e)=>setState({newTemplateText:e.target.value})}/>
                        <br/>
                        <b>Tags:</b> {templateTags}
                        <br/>
                        <center>
                          <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
                          <Button color="info" onClick={toggleAddNewTemplateDropDown}>Close</Button>
                        </center>
                      </form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div className="medium-scroll-container">
                <Table className="table table-striped">
                  <tbody>
                    {templates}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="4">
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-image"/> Profile Photo
            </CardHeader>
            <CardBody>
              <center>
                {profileContent}
              </center>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <br/>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-tasks"></i>&nbsp;Email notification
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <table className="table table-striped">
              <tbody>
              {
                state.emailSettings.map(
                  (emailSetting,index)=>{
                    return(
                      <tr key={index}>
                        <td>
                            <Row>
                              <Col sm="6">
                                {emailSetting.subject}
                              </Col>
                              <Col sm="6" className="align-right">
                                <Toggle
                                  checked={emailSetting.blocked==='no'}
                                  icons={false}
                                  onChange={()=>emailNotificationToggle(emailSetting.subject)} />
                              </Col>
                            </Row>
                        </td>
                      </tr>
                    )
                  }
                )
              }
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      <br/>

      {branches}

      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-tasks"></i>&nbsp;Update password
        </CardHeader>
        <CardBody>
          <div style={{height:'30px'}}>
            <label><font color="red">{state.changePasswordMessage}</font></label>
          </div>
          <label>Your email</label><br/>
          {getSession().email}
          <br/><br/>
          <label>Your current password</label>
          <input className="form-control" type="password" value={state.oldPassword} onChange={(e)=>setState({oldPassword:e.target.value})}/>

          <label>New password</label>
          <div className="input-container">
            <input className="form-control" type={state.showPassword ? 'text' : 'password'} value={state.newPassword} onChange={handlePasswordChange}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number, one special character, one uppercase and lowercase letter, and at least 12 or more characters"
              required
            />
            <span className="toggle-button" onClick={handleToggle}>
              {state.showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
            </span>
          </div>

          <label>Confirm password</label>
          <input className="form-control" type="password" value={state.confirmNewPassword} onChange={(e)=>setState({confirmNewPassword:e.target.value})}/>
          <br/>
          {state.isFocused && (
            <div id="message">
              <label>Password must contain the following:</label>
              <p id="letter" style={{lineHeight:'3px'}} className={(isLowerCaseValid&&isUpperCaseValid)? 'green-color' : 'red-color'}>
                {(isLowerCaseValid&&isUpperCaseValid)? valid:invalid} Combination of uppercase letters & lowercase letters
              </p>
              <p id="number" style={{lineHeight:'3px'}} className={isNumberValid? 'green-color' : 'red-color'}>
                {isNumberValid? valid:invalid} At least 1 number (0-9)
              </p>
              <p id="special-char" style={{lineHeight:'3px'}} className={isSpecialCharacterValid? 'green-color' : 'red-color'}>
                {isSpecialCharacterValid? valid:invalid} At least 1 special Chracter (!@#$%^&*)
              </p>
              <p id="length" style={{lineHeight:'3px'}} className={isLengthValid? 'green-color' : 'red-color'}>
                {isLengthValid? valid:invalid} Minimum <b>12 characters</b>
              </p>
            </div>
          )}   
          <br/>

          <div className="align-right">
            <Button color="warning" onClick={updatePassword}>Update password</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}


export default PersonalSetting;
