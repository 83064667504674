//Author June Leow
//Date June 5th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getReducer, getSetStateFunction, postAPICallGenerator, showMessage } from '../../util/util';
import './login.css';

//initialize the state
const initialState = {
  email:''
};

//reducer function that perform state update
const reducer = getReducer();


const ForgotPassword  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  //prevent the onSubmit and call login function
  const onSubmit=(e)=>{
    e.preventDefault();
    let parameters = [
      {
        field:'email',
        value:state.email
      }
    ];
    const callBack = (response)=>{
      let code = response.data.code;

      if(code==='00')
        showMessage('success', response.data.message);
      else
        showMessage('error', response.data.message);
    }
    httpPost('session/forgotPassword', parameters,'','', callBack);
  }

  //render
  return(
    <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
      <form name="loginForm" onSubmit={onSubmit}>
        <div className="login-container well padding">
          <br/>
          <center>
            <img alt="company logo" src="../img/logo_small.png" className="logo-login"/><br/>
            Client portal - Forgot password
          </center>
          <br/><br/><br/>
          <div className="align-right">
            <NavLink to="/">Back to login</NavLink>
          </div>
          <div className="form-group" style={{height:'38px'}}>
            <label className="font-white">Email:</label>
            <input type="email"  name="username" autoComplete="on" onChange={e => setState({email: e.target.value})} className="form-control" id="email"/>
          </div>
          <br/>
          <label className="font-white">&nbsp;</label>
          <br/>
          <Button color="warning" className="form-control">Recover Password</Button>
          <br/>
        </div>
      </form>
    </div>
  );
}


export default ForgotPassword;
