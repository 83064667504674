//Author June Leow
//Date Jul 2nd, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import { callBackGenerator, formatNumber, getAPICallGenerator, getReducer, getSession, getSetStateFunction, postAPICallGenerator } from '../../util/util';
import MyDropzone from '../util/my-dropzone';
let session = getSession();
let name = session.userFirstName+' '+session.userLastName;
let email = session.email;

//initialize the state
const initialState = {
  isInternal:'',
  requesterName:name,
  requesterEmail:email,
  subjectFacts:'',
  other:'',
  conditionTypes:[],

  error:'',
  keyword:'',
  conditions:[
    {
      type:'Other', 
      subject_facts:''
    }
  ],

  toUploadFiles:[],
  errorMessage:'',
  fileTypes:[],
};

//reducer function that perform state update
const reducer = getReducer();


const NewCondition  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getConditionTypes();
    getFileTypes();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateSubjectFacts=(type, value)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        conditions[i].subject_facts = value;
        break;
      }
    }
    setState({conditions:conditions});
  }

  const getSubjectFacts=(type)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        return conditions[i].subject_facts;
      }
    }
    return '';
  }


  const getOther=(type)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        return conditions[i].other;
      }
    }
    return '';
  }

  const updateOther=(type, value)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        conditions[i].other = value;
        break;
      }
    }
    setState({conditions:conditions});
  }

  const verifyConditionInfo=()=>{
    let conditions = state.conditions.slice();

    if(conditions.length<=0){
      setState({error:'Please select the condition type.'});
    }
    else{
      let ok = true;
      for(let i=0;i<conditions.length;i++){
        if(!conditions[i].subject_facts||conditions[i].subject_facts===''){
          ok = false;
          conditions[i].error = 'Please state the subject facts.';
        }
      }

      if(!ok)
        setState({conditions:conditions});
        else
          createNewCondition();
    }
  }

  
  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop=(acceptedFiles)=>{
    let existingFiles = state.toUploadFiles.slice();

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate)
        existingFiles.push(file);
    }
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile=(index)=>{

    let newFiles = state.toUploadFiles.slice();
    newFiles.splice(index,1);
    setState({toUploadFiles:newFiles});
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size)=>{
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  
  //constructing a new file object
  const deepCopyFileObject=(file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }


  //API call
  const getConditionTypes=()=>{
    let callBack = apiCallBack([{state:'conditionTypes', key:'data'}]);
    httpGet('condition/type/get', '' , 'Oops, something went wrong and could not load condition types. Please try again later.', callBack);
  }

  const createNewCondition=()=>{
    let preCheck = true;
    let errorMessage = '';

    let fileTypes = [];

    for(let i =0; i<state.toUploadFiles.length;i++){
      let value = state.toUploadFiles[i].fileType;
      if(!value||value===''){
        preCheck = false;
        fileTypes.push('');
        errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
      }
      else
        fileTypes.push(value);
    }

    if(preCheck){
      if(state.toUploadFiles.length>0){
        let files = [];
        for(let i=0;i<state.toUploadFiles.length;i++){
          const reader = new FileReader();
          reader.onload = () => {
            const fileAsBinaryString = reader.result?reader.result:reader.content;
            let base64 = btoa(fileAsBinaryString);

            let tmp = {};
            tmp.base64 = base64;
            tmp.name = state.toUploadFiles[i].name;
            tmp.type = fileTypes[i];

            files.push(tmp);
            console.log(files);

            if(files.length>=state.toUploadFiles.length){
              let parameters = [
                {
                  field:'appraisalFk',
                  value:state.id
                },
                {
                  field:'requesterName',
                  value:state.requesterName
                },
                {
                  field:'requesterEmail',
                  value:state.requesterEmail
                },
                {
                  field:'conditions',
                  value:state.conditions
                },
                {
                  field:'files',
                  value:files
                }
              ];
              console.log(parameters);

              let callBack = (response)=>{
                let code= response.data.code;
                if(code==='00'){
                  history('/condition/'+state.id);
                }
              };

              httpPost('condition/batch/create', parameters, 'Condition created successfully.', 'Oops, something went wrong and could not create the condition. Please try again later.', callBack);
            }
          };

          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => {
            props.showMessage('error','File upload failed, please try again later.');
          };

          reader.readAsBinaryString(state.toUploadFiles[i]);
        }
      }
      else{



        let parameters = [
          {
            field:'appraisalFk',
            value:state.id
          },
          {
            field:'requesterName',
            value:state.requesterName
          },
          {
            field:'requesterEmail',
            value:state.requesterEmail
          },
          {
            field:'conditions',
            value:state.conditions
          },
          {
            field:'files',
            value:state.toUploadFiles
          }
        ];
        console.log(parameters);

        //control is the object that holds control function from parent
        
        let callBack = (response)=>{
          let code= response.data.code;

          if(code==='00'){
            history('/condition/'+state.id);
          }
        };
        httpPost('condition/batch/create', parameters, 'Condition created successfully.', 'Oops, something went wrong and could not create the condition. Please try again later.', callBack);
      }
    }
    else{
      setState({errorMessage:errorMessage});
    }
  }

  const getFileTypes=()=>{
    let callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
    httpGet('file/fileType/public/get', '' , 'Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }

  //on change function when user change the file type drop down in upload file pop up
  const onFileTypeChange=(name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //render
  let filesTypesOpt;

  if(state.fileTypes.length>0){
    filesTypesOpt = state.fileTypes.map(
      (fileType, index)=>{
        return(
          <option key={index} value={fileType.name}>{fileType.name}</option>
        );
      }
    );
  }

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
            <select value={file.fileType} className="form-control" onChange={(e)=>{onFileTypeChange(file.name,e.target.value)}}>
                <option value=""></option>
                {filesTypesOpt}
              </select>
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(index)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let subjectFacts;

  subjectFacts = state.conditions.map(
    (condition,index)=>{
      return(
        <div key={index}>
          <div><font color="red">{condition.error}</font></div>
          <b>Please state subject facts for condition request</b><br/>
          <Input type="textarea" value={getSubjectFacts(condition.type)} onChange={(e)=>updateSubjectFacts(condition.type,e.target.value)} rows="16" style={{resize:'none'}}></Input>
          <br/>
        </div>
      );
    }
  );


  return(
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;New condition
          </div>
          <NavLink to={"/condition/"+state.id}>Back to condition</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <div className="well padding">
        <br/>
        {subjectFacts}

        <br/>
        <label>Upload File (optional)</label><br/>
        <MyDropzone onDrop={onDrop}/>
        <br/>
        <div className="small-scroll-container red-color">
          <b>{state.errorMessage}</b>
        </div>
        <div>
          <table className="table file-list-table" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th width="35%">File Type</th>
                <th width="15%">Size</th>
                <th width="10%"><center>Status</center></th>
                <th width="10%">Control</th>
              </tr>
            </thead>
            <tbody>
              {toUploadFiles}
            </tbody>
          </table>
        </div>
        <br/>

        <br/><br/>
        <div className="align-right">
          <Button color="warning" onClick={verifyConditionInfo} className="btn btn-blue-noshadow btn-block font-white" style={{display:'inline-block', width:'150px'}}>Submit</Button>
        </div>
      </div>
    </div>
  );
}


export default NewCondition;
