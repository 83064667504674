//Author June Leow
//Date Jul 16th, 2024
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { callBackGenerator, formatDateTime, formatNumber, getAPICallGenerator, getReducer, getSetStateFunction, putAPICallGenerator } from '../../util/util';
import MyReactTable from '../util/my-react-table';
import ReportFilter from './report-filter';

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-01';
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  from:from,
  to:to,
  openBy:'',
  reports:[],
  subscribed:false
};

//reducer function that perform state update
const reducer = getReducer();


const ReceivableReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();
    getReportSubscription();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReportDetails=()=>{
    const callBack = apiCallBack([{state:'reports', key:'data'}]);
    httpGet('report/receivable/get/status=&start='+state.from+'&end='+state.to, '', ';Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const subscribeReport=()=>{
    let parameters = [
      {
        field:'action',
        value:state.subscribed?"delete":"add"
      }
    ];
    const callBack = apiCallBack([{state:'subscribed', value:!state.subscribed}]);
    httpPut('report/receivable/daily/update', parameters, 'Daily report email preferrence updated.','Oops, something went wrong and could not update your prefference on the daily email report. Please try again later.', callBack);
  }

  const getReportSubscription=()=>{
    const callBack = apiCallBack([{state:'subscribed', key:'data'}]);
    httpGet('report/receivable/daily/get', '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from).toDate();
  if(state.to!=='')
    to = moment(state.to).toDate();
  /*
  React Table
  */

  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig1 = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'8', className:"align-right", color:"warning"},
  ];

  console.log(state.reports)

  return(
    <div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Receivable Report
        </CardHeader>
        <CardBody>
          <ReportFilter {...props} configs={reportFiltersConfig1}/>

          <div className="my-divider">&nbsp;</div>
          <div className="align-right">
            <NavLink to="#" onClick={subscribeReport}>
              {state.subscribed?"Unsubscribe ":"Subscribe to "} daily email <i className={"fa fa-envelope-open-o cursor-pointer"+(state.subscribed?" link-color":"")}/>
            </NavLink>
          </div>
          <br/>
          <Card>
            <CardHeader>

            </CardHeader>
            <CardBody>
              <div className="large-scroll-container">
                <MyReactTable columns={columns} data={state.reports} className="table table-striped"/>
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  );
}


export default ReceivableReport;
