//Author June Leow
//Date Jul 16th, 2024
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { callBackGenerator, formatDateTime, getAPICallGenerator, getReducer, getSetStateFunction } from '../../util/util';
import MyReactTable from '../util/my-react-table';
import ReportFilter from './report-filter';

let clientID = localStorage.getItem('clientID');
let date = new Date(), y = date.getFullYear(), m = date.getMonth();
let firstDay = new Date(y, m, 1);
let lastDay = new Date(y, m + 1, 0);

let fromMonth = firstDay.getMonth()+1;
if(fromMonth<10)
  fromMonth = '0'+fromMonth;
let toMonth = lastDay.getMonth()+1;
if(toMonth<10)
  toMonth = '0'+toMonth;

let from = firstDay.getFullYear()+'-'+fromMonth+'-01';
let to = lastDay.getFullYear()+'-'+toMonth+'-'+lastDay.getDate();
  
//initialize the state
const initialState = {
  client:clientID,
  clientBranches:[],
  clientBranch:0,
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  appraisalTypes:[],
  from:from,
  to:to,

  report:[],

  clients:[],
};

//reducer function that perform state update
const reducer = getReducer();


const PipelineReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalTypes();
    getClientBranch();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state



  const removeAppraisalType=(appraisalType)=>{
    let appraisalTypes = state.filterAppraisalTypes.slice();

    let index = appraisalTypes.indexOf(appraisalType);

    if(index!==-1)
      appraisalTypes.splice(index,1);

    setState({filterAppraisalTypes:appraisalTypes});
  }

  //API call
  const downloadReport=(e)=>{
    e.preventDefault();
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
    appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');

    let callBack = (response)=>{
      console.log(response.data.data);
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'client-pipeline.xlsx');
    };
    httpGet('report/client/pipeline/download/clientBranch='+state.clientBranch+'&client='+state.client+'&loanPurpose='+state.loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType, '', 'Oops, something went wrong and could not download the pipeline report. Please try again later.', callBack);
  }

  const getReport=()=>{
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
    appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');
    let url = 'report/client/pipeline/clientBranch='+state.clientBranch+'&client='+state.client+'&loanPurpose='+state.loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    const callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //get appraisal type
  const getAppraisalTypes=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let appraisalTypes = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].appraisal_type,
            value: response.data.data[i].external_label
          }
          appraisalTypes.push(tmp);
        }

        setState({appraisalTypes:appraisalTypes});
      }
    };
    httpGet('appraisalType/get/externalLabel','','Oops, something went wrong and could not load list of available report types. Please try again later.', callBack);
  }

  const getClientBranch=()=>{
    let callBack = (response)=>{

      let code = response.data.code;
      if(code==='00'){
        let clientBranches = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].ID,
            value: response.data.data[i].branch_name
          }
          clientBranches.push(tmp);
        }
        setState({clientBranches:clientBranches});
      }
    };
    httpGet('client/branch/get/'+state.client,'','Oops, something went wrong and could not load your company branch. Please try again later.', callBack);
  }

  //render
  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Date Submitted',
      accessor: d => d.datetime_submitted,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanOfficer',
      Header: 'Loan Officer',
      accessor: d => d.loan_officer,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanOfficerBranch',
      Header: 'Branch',
      accessor: d => d.branch,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header: 'Fee',
      accessor: d => d.fee,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateCharged',
      Header: 'Date Charged',
      accessor: d => d.datetime_charged,
      Cell: props => props.row.original.datetime_charged!==''?formatDateTime(props.row.original.datetime_charged):'-',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'thirdParty',
      Header: 'LOS Integration',
      accessor: d => d.third_party,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let from, to;
  if(state.from!=='')
    from = moment(state.from).toDate();
  if(state.to!=='')
    to = moment(state.to).toDate();

  let filterAppraisalTypes;

  if(state.filterAppraisalTypes.length>0)
    filterAppraisalTypes = state.filterAppraisalTypes.map(
      (appraisalType, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeAppraisalType(appraisalType)}>
              <i className="fa fa-minus red-color"></i> {appraisalType}
            </div>
            &nbsp;
          </div>
        );
      }
    );


  let appraisalType='';

  if(state.filterAppraisalTypes.length>0){
     appraisalType = state.filterAppraisalTypes.join('|');
     appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
     appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');
  }

  

  let reportFiltersConfig1 = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'customSelect',value:state.clientBranch,label:"Branch",options:state.clientBranches.map((branch)=>{
      return {label:branch.name, value:branch.ID}
    }), updateFunc:(v)=>setState({clientBranch:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'6', className:"align-right", color:"warning"},
  ];

  return(
    <div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Pipeline Report
        </CardHeader>
        <CardBody>
          <ReportFilter {...props} configs={reportFiltersConfig1}/>
        </CardBody>
      </Card>
      <br/>

      <div className="align-right">
        <NavLink to="#" onClick={downloadReport}>Download Report</NavLink>
      </div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Pipeline Report
        </CardHeader>
        <CardBody>
        <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
        </CardBody>
      </Card>
    </div>
  );
}


export default PipelineReport;
