//Author June Leow
//Date Jul 15th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, sliceFromArray, formatDate} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col, Button, Table, Input, Modal, ModalHeader, ModalBody} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';

let clientID = localStorage.getItem('clientID');

//initialize the state
const initialState = {
  addNewBranchUserDropDownOpen:false,
  branchUsers:[],
  warningMessage:'',
  hasMoreUsers:true,
  limit: 25,
  offset: 0,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  name:'',
  email:'',

  formValid:false,
  newUserPassword1:'',
  newUserPassword2:'',
  newUserFirstName:'',
  newUserLastName:'',
  newUserEmail:'',
  newUserPhone:'',
  newUserRole:'',
  showPassword:false,
  isFocused:false,
  brokerRelationshipFk:'',
  clientID:clientID
};

//reducer function that perform state update
const reducer = getReducer();


const Branch  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getRelationshipID(state.clientID)
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //run only once when component is loaded
  useEffect(()=>{
    if(state.branchUsers.length<=0 && state.hasMoreUsers){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //toggle function for pop up
  const addNewBranchUserDropDownToggle=()=>{
    setState({addNewBranchUserDropDownOpen:!state.addNewBranchUserDropDownOpen});
  }

  const refreshList=()=>{
    setState({branchUsers:[]});
    setState({hasMoreUsers:true});
    setState({offset:0});
  }

  const handlePasswordFocus = () => {
    setState({ isFocused: true });
  };

  const handlePasswordBlur = () => {
    setState({ isFocused: false });
  };

  const handleToggle = () => {
    setState({ showPassword: !state.showPassword });
  }

  const isPasswordValid = (password, type='') => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCharacters = /[!@#$%^&*]/g;
    const isLowerCaseValid = password.match(lowerCaseLetters);
    const isUpperCaseValid = password.match(upperCaseLetters);
    const isNumberValid = password.match(numbers);
    const isSpecialCharacterValid = password.match(specialCharacters);
    const isLengthValid = password.length >= 12;

    if(type === 'lowercase'){
      return(isLowerCaseValid);
    }else if(type === 'uppercase'){
      return(isUpperCaseValid);
    }else if(type === 'number'){
      return(isNumberValid);
    }else if(type === 'special'){
      return(isSpecialCharacterValid);
    }else if(type === 'length'){
      return(isLengthValid);
    }else{
      return (
        isLowerCaseValid &&
        isUpperCaseValid &&
        isNumberValid &&
        isSpecialCharacterValid &&
        isLengthValid
      );
    }
  };

  //check if the second password matched the first one and set the state accordingly
  const checkPassword=(password1, password2)=>{
    if(!isPasswordValid(password1)){
      setState({warningMessage:'Please match the required format.', formValid:false});
    }
    else if(password1!==password2){
      setState({warningMessage:'Your password does not match.', formValid:false});
    }
    else{
      setState({warningMessage:'', formValid:true});
    }
  }

  //API call
  const submitNewUser=(e)=>{
    e.preventDefault();
    if(state.formValid){
      let parameters = [
        {
          field:'branch_fk',
          value:state.id
        },
        {
          field:'first_name',
          value:state.newUserFirstName
        },
        {
          field:'last_name',
          value:state.newUserLastName
        },
        {
          field:'email',
          value:state.newUserEmail
        },
        {
          field:'phone',
          value:state.newUserPhone
        },
        {
          field:'author',
          value:props.userFirstName+" "+props.userLastName
        },
        {
          field:'role',
          value:state.newUserRole
        },
        {
          field:'password',
          value:state.newUserPassword1
        }
      ];

      let callBack = (response)=>{
        let code = response.data.code;

        if(code==='00'){
          let branchUsers = state.branchUsers.slice();

          branchUsers.push(response.data.data);

          setState({branchUsers:branchUsers});
          createNewClientRelationship(state.newUserEmail, state.newUserRole, state.newUserPhone, state.id, state.newUserFirstName, state.newUserLastName, state.brokerCompanyProfileFk);
          addNewBranchUserDropDownToggle();
        }
      };

      //do not show success message and do another post to register user in client relationship
      httpPost('client/branch/user/create', parameters, 'User created successfully.', 'Oops, something went wrong and could not create this user. Please try again later.', callBack);
    }
  }

  const createNewClientRelationship=(email, role, phone, branchFk, firstName, lastName, brokerCompanyProfileFk)=>{
    let url = 'brokerRelationship/processor';

    if(role==='Loan Officer'){
      url = 'brokerRelationship/loanOfficer/create';
    }
    else if(role==='Loan Processor'){
      url = 'brokerRelationship/processor/create';
    }
    else if(role==='Broker'){
      url = 'brokerRelationship/broker/create';
    }
    let parameters = [
      {
        field:'branchFk',
        value:branchFk
      },
      {
        field:'firstName',
        value:firstName
      },
      {
        field:'note',
        value:''
      },
      {
        field:'lastName',
        value:lastName
      },
      {
        field:'email',
        value:email
      },
      {
        field:'phone',
        value:phone
      },
      {
        field:'brokerCompanyProfileFk',
        value:brokerCompanyProfileFk
      }
    ];

    console.log(parameters)
    //do not show success message and do another post to register user in client relationship
    httpPost(url, parameters, '', 'Oops, something went wrong and could not create this user. Please try again later.', ()=>{});
  }

  //remove a branch user
  const removeBranchUser=(id)=>{

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let branchUsers = sliceFromArray(state.branchUsers, 'ID', id);
        setState({branchUsers:branchUsers});
      }
    };
    
    httpDelete('client/branch/user/'+id,'User deleted successfully.','Oops, something went wrong and could not delete this user. Please try again later.', callBack);
  }

  const loadMore=()=>{
    console.log('test')
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreUsers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});
      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data.code;

        if(code!=='00'){
          setState({hasMoreUsers:false});
        }
        else{
          let newUsers = response.data.data;
          let hasMoreUsers = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list
          if(newUsers.length<=0){
            hasMoreUsers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.branchUsers.length>0){
            let temp = [...state.branchUsers,...newUsers];

            setState({branchUsers:temp});
          }
          else
            setState({branchUsers:newUsers});

          setState({hasMoreUsers:hasMoreUsers,offset:newOffset});
        }
      };
     
      //error handler when the http request return with error
      let errorCallBack = ()=>{
        //no more fetching data when error occur
        setState({hasMoreUsers:false});
      };
     

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet('client/branch/user/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status=&name='+state.name+'&email='+state.email+'&branch_fk='+state.id, '', 'Oops, something went wrong and could not load users. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const getRelationshipID=(id)=>{
    let callBack = apiCallBack([{state:'brokerCompanyProfileFk', key:'data.ID'}]);
    httpGet('brokerRelationship/'+id, '', 'Oops, something went wrong and could not your company profile. Please try again later.', callBack);
  }

  //render
  const isLowerCaseValid = isPasswordValid(state.newUserPassword1, 'lowercase');
    const isUpperCaseValid = isPasswordValid(state.newUserPassword1, 'uppercase');
    const isSpecialCharacterValid = isPasswordValid(state.newUserPassword1, 'special');
    const isNumberValid = isPasswordValid(state.newUserPassword1, 'number');
    const isLengthValid = isPasswordValid(state.newUserPassword1, 'length');

    let valid = <i className="fa fa-check-circle-o"></i>;
    let invalid = <i className="fa fa-times-circle-o"></i>;

    let warningMessage = <div>&nbsp;</div>;

    if(state.warningMessage!=='')
      warningMessage = <div className="display-inline"><font color="red">*</font>{state.warningMessage}</div>;

    let branchUsers;

    if(state.branchUsers.length>0){
      branchUsers = state.branchUsers.map(
        (branchUser,index)=>{
          return(
            <tr key={index}>
              <td><i className="fa fa-minus cursor-pointer red-color" onClick={
                ()=>{
                  confirmation(
                    ()=>{removeBranchUser(branchUser.ID)},
                    ()=>{},
                    'Delete user?',
                    'Are you sure you want to delete this user?');
                }
              }></i></td>
              <td>{branchUser.first_name+' '+branchUser.last_name}</td>
              <td>{branchUser.email}</td>
              <td>{branchUser.phone}</td>
              <td>{formatDate(branchUser.datetime_created)}</td>
            </tr>
          );
        }
      );
    }

    return(
      <div className="my-well">
        <Modal className="my-modal" isOpen={state.addNewBranchUserDropDownOpen} toggle={addNewBranchUserDropDownToggle} >
          <ModalHeader hidden={true} toggle= {addNewBranchUserDropDownToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-bank"></i> Add New User</h5>
            </center>
            <br/>
            <form onSubmit={submitNewUser}>
              <div className="flashit red-color"><b>{warningMessage}</b></div>
              <Row>
                <Col sm="6">
                  <label>First Name:</label>
                  <Input required={true} type="text" value={state.newUserFirstName} onChange={(e)=>setState({newUserFirstName:e.target.value})}/>
                </Col>
                <Col sm="6">
                  <label>Last Name:</label>
                  <Input required={true} type="text" value={state.newUserLastName} onChange={(e)=>setState({newUserLastName:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <label>Role</label>
                  <Input type="select" required={true} value={state.newUserRole} onChange={(e)=>setState({newUserRole:e.target.value})}>
                    <option value=""></option>
                    <option value="Loan Officer">Loan Officer</option>
                    <option value="Loan Processor">Loan Processor</option>
                    <option value="Broker">Broker</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <label>Email:</label>
                  <Input required={true} type="text" value={state.newUserEmail} onChange={(e)=>setState({newUserEmail:e.target.value})}/>
                </Col>
                <Col sm="12">
                  <label>Phone:</label>
                  <Input type="text" value={state.newUserPhone} onChange={(e)=>setState({newUserPhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <label>Password:</label>
                  <div className="input-container">
                    <Input type={state.showPassword ? 'text' : 'password'} value={state.newUserPassword1} onChange={(e)=>{setState({newUserPassword1:e.target.value});checkPassword(e.target.value, state.newUserPassword2)}}
                      onFocus={handlePasswordFocus}
                      onBlur={handlePasswordBlur}
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number, one special character, one uppercase and lowercase letter, and at least 8 or more characters"
                      required
                    />
                    <span className="toggle-button" onClick={handleToggle}>
                      {state.showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </span>
                  </div>
                </Col>
                <Col sm="6">
                  <label>Re-type Password:</label>
                  <Input required="true" type="password" value={state.newUserPassword2} onChange={(e)=>{setState({newUserPassword2:e.target.value});checkPassword(state.newUserPassword1, e.target.value)}}/>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  {state.isFocused && (
                    <div id="message">
                      <label>Password must contain the following:</label>
                      <p id="letter" style={{lineHeight:'3px'}} className={(isLowerCaseValid&&isUpperCaseValid)? 'green-color' : 'red-color'}>
                        {(isLowerCaseValid&&isUpperCaseValid)? valid:invalid} Combination of uppercase letters & lowercase letters
                      </p>
                      <p id="number" style={{lineHeight:'3px'}} className={isNumberValid? 'green-color' : 'red-color'}>
                        {isNumberValid? valid:invalid} At least 1 number (0-9)
                      </p>
                      <p id="special-char" style={{lineHeight:'3px'}} className={isSpecialCharacterValid? 'green-color' : 'red-color'}>
                        {isSpecialCharacterValid? valid:invalid} At least 1 special Chracter (!@#$%^&*)
                      </p>
                      <p id="length" style={{lineHeight:'3px'}} className={isLengthValid? 'green-color' : 'red-color'}>
                        {isLengthValid? valid:invalid} Minimum <b>12 characters</b>
                      </p>
                    </div>
                  )}  
                </Col>
              </Row>
              <br/>
              <center>
                <Button color="warning"><i className="fa fa-check"></i>&nbsp;Add</Button>{' '}
                <Button color="info" onClick={addNewBranchUserDropDownToggle}>Close</Button>
              </center>
            </form>
          </ModalBody>
        </Modal>
        <Row>
          <Col sm="6">
            <div className="page-title">
              <i className="fa fa-reorder"></i>&nbsp;Branch - User management
            </div>
          </Col>
          <Col sm="6" className="align-right">
            <NavLink to="/setting">Back to settings</NavLink>
          </Col>
        </Row>
        <div className="my-divider"></div>
        <br/>
        <Card>
          <CardHeader className="header-color">Registed users</CardHeader>
          <CardBody>
            <Row>
              <Col sm="4">
                <label>Name:</label>
                <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
              </Col>
              <Col sm="4">
                <label>Email:</label>
                <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
              </Col>
              <Col sm="4" className="align-right">
                <div className="ex-margin-bottom">&nbsp;</div>
                <Button color="warning" onClick={refreshList}>Submit</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="my-divider"></div>
        <div className="align-right">
          <NavLink to="#" onClick={addNewBranchUserDropDownToggle}>Add new user</NavLink>
        </div>
        <InfiniteScroll
          className="my-well"
          dataLength={state.branchUsers.length} //This is important field to render the next data
          next={loadMore}
          hasMore={state.hasMoreUsers}
          loader={<center>Loading more users...</center>}
          scrollableTarget="contentContainer"
        >
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="15%">Action</th>
                <th width="25%">Name</th>
                <th width="25%">Email</th>
                <th width="15%">Phone</th>
                <th width="20%">Date Created</th>
              </tr>
            </thead>
            <tbody>
              {branchUsers}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>
    );
}


export default Branch;
