//Author June Leow
//Date Nov 14th, 2023
import Pusher from 'pusher-js';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import LoadingBar, { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import { logout } from '../actions/action-login';
import config from '../config';
import Announcements from './announcement/announcements';
import './App.css';
import Appraisal from './appraisal/appraisal';
import Appraisals from './appraisal/appraisals';
import FeeEscalation from './appraisal/fee-escalation';
import NewAppraisal from './appraisal/new-appraisal';
import NewQuoteAppraisal from './appraisal/new-quote-appraisal';
import UpdateFHACaseNumber from './appraisal/update-fha-case-number';
import Branch from './branch/branch';
import NewClientUser from './client/new-user';
import Condition from './condition/condition';
import Conditions from './condition/conditions';
import NewCondition from './condition/new-condition';
import NewConditionSearch from './condition/new-condition-search';
import Dashboard from './dashboard/dashboard';
import FileUpload from './file/file-upload';
import QuickFileUpload from './file/quick-file-upload';
import AccountActivation from './login/account-activation';
import ForgotPassword from './login/forgot-password';
import Login from './login/login';
import ResetPassword from './login/reset-password';
import SignUp from './login/sign-up';
import Notifications from './notification/notifications';
import CreditCard from './payment/credit-card';
import { PusherProvider } from './pusher/pusher-context';
import NewRebuttal from './rebuttal/new-rebuttal';
import NewRebuttalSearch from './rebuttal/new-rebuttal-search';
import Rebuttal from './rebuttal/rebuttal';
import Rebuttals from './rebuttal/rebuttals';
import Relationship from './relationship/relationship';
import Reports from './report/reports';
import PersonalSetting from './setting/personal-setting';
import Template from './setting/template';
import SideBar from './structure/side-bar/side-bar';
import TopBar from './structure/top-bar/top-bar';
import AccountingTicket from './support/accounting-ticket';
import AccountingTickets from './support/accounting-tickets';
import NewAccountingTicket from './support/new-accounting-ticket';
import NewTicket from './support/new-ticket';
import Support from './support/support';
import Ticket from './support/ticket';
import Tickets from './support/tickets';


const App  = (props)=>{
  let pusher = new Pusher(config.pusherAppKey,{
    authEndpoint: config.baseAPIUrl + 'session/notification_auth',
    auth:{
      headers: {
        'Authorization': "Authorization: Bearer "+localStorage.getItem('token')
      }
    },
    cluster: config.pusherCluster
  });

  const container = useRef();

  if(props.isLoggedIn){
    return (
      <div>
        <PusherProvider pusher={pusher}>
          <Router>
            <div style={{height:'100%'}}>
              <ToastContainer theme="colored" className="my-toast-container always-on-top"/>
              <LoadingBar style={{zIndex:'99'}}/>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 col-md-12 no-padding">
                    <TopBar {...props} userFirstName={props.userFirstName} userLastName={props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
                  </div>
                </div>

                <div className="row page-content">
                  <div className="col-sm-2 col-md-2 no-padding">
                    <SideBar logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc} {...props} isLoggedIn={props.isLoggedIn}/>
                  </div>

                  <div  id="contentContainer" className="col-sm-10 col-md-10 padding content-container" ref={container}>
                    <Routes>
                      <Route
                        exact path="/"
                        element={<Dashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/report"
                        element={<Reports {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal/fee-escalation/:appraisalFk/:id"
                        element={<FeeEscalation {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/announcement"
                        element={<Announcements {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/branch/:id"
                        element={<Branch {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/notification"
                        element={<Notifications {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/setting"
                        element={<PersonalSetting {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/setting/template/:id"
                        element={<Template {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support/ticket"
                        element={<Tickets {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support/ticket/:id"
                        element={<Ticket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support/new"
                        element={<NewTicket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support/accounting/new"
                        element={<NewAccountingTicket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support/accounting/ticket"
                        element={<AccountingTickets {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support/accounting/ticket/:id"
                        element={<AccountingTicket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/support"
                        element={<Support {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                     <Route
                        exact path="/dashboard"
                        element={<Dashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal/request-turnaround"
                        element={<NewQuoteAppraisal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal/new-appraisal"
                        element={<NewAppraisal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal/:id"
                        element={<Appraisal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal"
                        element={<Appraisals {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal/update-fha-case-number/:id"
                        element={<UpdateFHACaseNumber {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/condition/new-condition"
                        element={<NewConditionSearch {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/condition/new-condition/:id"
                        element={<NewCondition {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/condition/:id"
                        element={<Condition {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/condition"
                        element={<Conditions {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/rebuttal/new-rebuttal"
                        element={<NewRebuttalSearch {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/rebuttal/new-rebuttal/:id"
                        element={<NewRebuttal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/rebuttal/:id"
                        element={<Rebuttal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/rebuttal"
                        element={<Rebuttals {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/credit-card/:id"
                        element={<CreditCard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/account/activation"
                        element={<AccountActivation {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/appraisal/file-upload/:appraisalFk"
                        element={<FileUpload {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/quick-file-upload"
                        element={<QuickFileUpload {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/setting/relationship"
                        element={<Relationship {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </Router>
        </PusherProvider>
     </div>
   );
  }
  else{
    return (
      <div>
        <ToastContainer theme="colored" className="my-toast-container always-on-top"/>
        <LoadingBar style={{zIndex:'99'}}/>
        <Router>
          <div id="contentContainer" className="content-container" ref={container}>
            <Routes>
              <Route
                exact path="/forgot-password"
                element={<ForgotPassword {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/appraisal/fee-escalation/:appraisalFk/:id"
                element={<FeeEscalation {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/reset-password/:email/:token"
                element={<ResetPassword {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/appraisal/update-fha-case-number/:id"
                element={<UpdateFHACaseNumber {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/client/user/new"
                element={<NewClientUser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/client/user/new/:targetWholesale"
                element={<NewClientUser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/sign-up/:params"
                element={<SignUp {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/appraisal/file-upload/:appraisalFk"
                element={<FileUpload {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                path="/"
                element={<Login {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route path="*" element={<Login {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}/>
            </Routes>
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    jwtToken: state.session.jwtToken,
    isLoggedIn: state.session.isLoggedIn,
    userRole: state.session.userRole,
    userFirstName: state.session.userFirstName,
    userLastName: state.session.userLastName
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    logout: logout,
    dispatchShowLoadingFunc: ()=>{return showLoading()},
    dispatchHideLoadingFunc: ()=>{return hideLoading()},
  },dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(App);
