//Author Sooyoung Kim
//Date May 26, 2023
import React, { useEffect, useReducer } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { callBackGenerator, getAPICallGenerator, getReducer, getSetStateFunction } from '../../../util/util';
import './side-bar.css';

//initialize the state
const initialState = {
  currentUrl: window.location.href?window.location.href:"",
  policyDropdownOpen: window.location.href.includes("client")?true:false,
  pipelineDropdownOpen: true,
  dashboardDropdownOpen: true,
  contactDropdownOpen: true,
  clientDropdownOpen: window.location.href.includes("client")?true:false,
  appraiserDropdownOpen: (window.location.href.includes("appraiser")||window.location.href.includes("amc"))?true:false,
  newAnnouncementCount:0,
  hasReport:false,
};

//reducer function that perform state update
const reducer = getReducer();


const SideBar  = (props)=>{
  const history = useNavigate();
  const controller = new AbortController();
  let pipelinePreference = localStorage.getItem('pipelinePreference');

  let globalPipeline = false;
  if(pipelinePreference&&pipelinePreference!=='')
    if(pipelinePreference==='global')
      globalPipeline = true;

  let newInitialState = Object.assign({}, initialState, {globalPipeline:globalPipeline});

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    hasNewAnnouncement();
    getReport();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    setState({currentUrl:window.location.href});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history]);

  //non API call but simpyl manage state

  const togglePipeline = ()=>{
    setState({
      pipelineDropdownOpen: !state.pipelineDropdownOpen
    });
  }


  //API call
  const hasNewAnnouncement = ()=>{
    let callBack = apiCallBack([{state:'newAnnouncementCount', key:'data.new_announcement_count'}]);
    httpGet('announcement/hasNewAnnouncement', '', '', callBack);
  }

  const getReport=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        if(response.data.data.length>0)
          setState({hasReport:true});
      }
    };
    httpGet('report/client/get','','', callBack);
  }

  //render
  let count = <div style={{width:'10px'}} className="display-inline"></div>;
  if(state.newAnnouncementCount>0){
    count = <span className="my-badge" style={{fontSize:'11px'}}>{state.newAnnouncementCount}</span>
  }

  let report;
  if(state.hasReport)
    report = <li>
      <NavLink className="padding" activeClassName="sidebar-active-link" to="/report"><i className="fa fa-line-chart sidebar-icon"></i> Report</NavLink>
    </li>

  if(props.isLoggedIn){
    return (
      <div className="sidebar">
        <ul>
          <li>
            <NavLink className={state.currentUrl.includes("/announcement")?"padding sidebar-active-link":"padding"} to="/announcement"><i className="fa fa-volume-up sidebar-icon"></i> Announcement {count}</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/appraisal/request-turnaround")?"padding sidebar-active-link":"padding"} to="/appraisal/request-turnaround"><i className="fa fa-question sidebar-icon"></i> Request Turnaround Time</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/quick-file-upload")?"padding sidebar-active-link":"padding"} to="/quick-file-upload"><i className="fa fa-plus sidebar-icon"></i> Upload File</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/appraisal/new-appraisal")?"padding sidebar-active-link":"padding"} to="/appraisal/new-appraisal"><i className="fa fa-plus sidebar-icon"></i> New appraisal</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/condition/new-condition")?"padding sidebar-active-link":"padding"} to="/condition/new-condition"><i className="fa fa-plus sidebar-icon"></i> New condition</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/rebuttal/new-rebuttal")?"padding sidebar-active-link":"padding"} to="/rebuttal/new-rebuttal"><i className="fa fa-plus sidebar-icon"></i> New rebuttal</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/dashboard")?"padding sidebar-active-link":"padding"} to="/dashboard"><i className="fa fa-dashboard sidebar-icon"></i> Dashboard</NavLink>
          </li>
          {report}
          <li className="collapse-nav">
            <NavLink to="#" className="padding" onClick={togglePipeline}><i className="fa fa-bar-chart-o sidebar-icon"></i> Pipeline <i className="fa fa-angle-double-down"></i></NavLink>
            <Collapse isOpen={state.pipelineDropdownOpen}>
              <NavLink className={state.currentUrl.includes("/appraisal")?"padding sidebar-active-link":"padding"} to="/appraisal">Appraisals</NavLink>
              <NavLink className={state.currentUrl.includes("/condition")?"padding sidebar-active-link":"padding"} to="/condition">Conditions</NavLink>
              <NavLink className={state.currentUrl.includes("/rebuttal")?"padding sidebar-active-link":"padding"} to="/rebuttal">Rebuttal</NavLink>
            </Collapse>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/setting")?"padding sidebar-active-link":"padding"} to="/setting"><i className="fa fa-gear sidebar-icon"></i> Settings</NavLink>
          </li>
        </ul>
      </div>
    );
  }
  else {
    return(null);
  }
}

export default SideBar;
