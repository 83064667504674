//Author June Leow
//Date Jul 16th, 2024
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink as NavLinkL, Row, TabContent, TabPane } from 'reactstrap';
import { callBackGenerator, formatNumber, getAPICallGenerator, getReducer, getSetStateFunction, getStateCode } from '../../util/util';
import MyHeatMapDrillDown from '../util/my-heat-map-drilldown';
import MyReactTable from '../util/my-react-table';
import ReportFilter from './report-filter';

let clientID = localStorage.getItem('clientID');
let date = new Date(), y = date.getFullYear(), m = date.getMonth();
let firstDay = new Date(y, m, 1);
let lastDay = new Date(y, m + 1, 0);

let fromMonth = firstDay.getMonth()+1;
if(fromMonth<10)
  fromMonth = '0'+fromMonth;
let toMonth = lastDay.getMonth()+1;
if(toMonth<10)
  toMonth = '0'+toMonth;

let from = firstDay.getFullYear()+'-'+fromMonth+'-01';
let to = lastDay.getFullYear()+'-'+toMonth+'-'+lastDay.getDate();

//initialize the state
const initialState = {
  activeTab:'3',
  client:clientID,
  clientBranches:[],
  clientBranch:'',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'All',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  states:[
    {key:'All',value:'All'},{key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  appraisalTypes:[],
  from:from,
  to:to,
  turntime:'1',
  turntimeSubmitted:'1',

  counties:[],
  tempCounties:[],
  statesStats:[],
  usa:[],

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  clients:[],
  openByClient:0,
  selectedStates:['All'],
  showStatesStats:true,

  heatMapSelectedState:'',
  heatMapSelectedStateData:[],
  heatMapXCoordinate:'',
  heatMapYCoordinate:'',

  heatMapDrillDownState:'',
};

//reducer function that perform state update
const reducer = getReducer();


const TurntimeReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalTypes();
    getClientBranch();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const heatMapOnClick = (stateCounty, level='state') =>{
    console.log(stateCounty);
    console.log(level);
    setState({heatMapDrillDownLevel:level, heatMapDrillDownStateCounty:stateCounty});
    if(level==='state'){
      heatMapDrillDown(stateCounty);
    }
  }

  const heatMapDrillDown = (stateName) =>{
    console.log(stateName);
    if(stateName===''){
      setState({counties:state.tempCounties.slice(), tempCounties:state.counties.slice()});
    }
    else{
      let counties = state.counties.slice();
      let newCounties = [];

      for(let i=0;i<counties.length;i++){
        if(counties[i].state===stateName)
          newCounties.push(counties[i]);
      }

      setState({counties:newCounties, tempCounties:counties});
    }
  }

  const updateSelectedState = (states) => {
    setState({selectedStates:states});
  }

  const activeTabToggle=(tab)=>{
    setState({activeTab:tab});
  }

  const removeState=(state)=>{
    let states = state.selectedStates.slice();

    let index = states.indexOf(state);

    if(index!==-1)
      states.splice(index, 1);
    setState({selectedStates:states});
  }


  const reportDetailsToggle=()=>{
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }


  //API call
  const getReport=()=>{
    setState({turntimeSubmitted:state.turntime,statesStats:[],counties:[]});
    let states = state.selectedStates.join(',');
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
    appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');
    let url = 'report/turnTime/clientBranch='+state.clientBranch+'&turntime='+state.turntime+'&client='+state.client+'&loanPurpose='+state.loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+states+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      if(code==='00'){
        setState({counties:response.data.data,statesStats:response.data.data2,usa:response.data.data3});
      }
    };

    httpGet(url,'','Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails=(classIndex, stateVal, county, complex = state.isComplex)=>{
    reportDetailsToggle();
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
    appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');
    let url = 'report/turnTime/details/order/turntime='+state.turntime+'&client='+state.client+'&loanPurpose='+state.loanPurpose+'&county='+county+'&class='+classIndex+'&loanType='+state.loanType+'&complex='+complex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+stateVal+'&city='+state.city+'&appraisalType='+appraisalType;
    const callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //get appraisal type
  const getAppraisalTypes=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let appraisalTypes = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].appraisal_type,
            value: response.data.data[i].external_label
          }
          appraisalTypes.push(tmp);
        }

        setState({appraisalTypes:appraisalTypes});
      }
    };
    httpGet('appraisalType/get/externalLabel','','Oops, something went wrong and could not load list of available report types. Please try again later.', callBack);
  }

  const getClientBranch=()=>{
    let callBack = (response)=>{

      let code = response.data.code;
      if(code==='00'){
        let clientBranches = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].ID,
            value: response.data.data[i].branch_name
          }
          clientBranches.push(tmp);
        }
        setState({clientBranches:clientBranches});
      }
    };
    httpGet('client/branch/get/'+state.client,'','Oops, something went wrong and could not load your company branch. Please try again later.', callBack);
  }

  //render
  let data = [];
  let data2 = [];
  let map;
  // eslint-disable-next-line no-template-curly-in-string
  let pointFormat = '<span>{point.code}: {point.value} bd<br/>{point.overall_total} orders<br/>{point.complex_percentage}% complex<br/>{point.total_appraiser} appraisers<br/>${point.spread} spread</span>';
  let joinBy =['postal-code','code'];

  //state data
  for(let i=0;i<state.statesStats.length;i++){
    data.push({
      state:state.statesStats[i].state,
      state_short:getStateCode(state.statesStats[i].state),
      code:getStateCode(state.statesStats[i].state),
      value:state.statesStats[i].overall_avg_turntime,
      overall_total: state.statesStats[i].overall_total,
      spread:state.statesStats[i].spread,
      total_lender_fee:state.statesStats[i].total_lender_fee,
      total_appraiser:state.statesStats[i].appraiser_total,
      total_appraiser_fee:state.statesStats[i].total_appraiser_fee,
      total_complex:state.statesStats[i].total_complex,
      complex_percentage:state.statesStats[i].complex_percentage,
    });
  }

  //county data
  for(let i=0;i<state.counties.length;i++){
    let tmp = {
      state:state.counties[i].state,
      state_short:getStateCode(state.counties[i].state),
      county:state.counties[i].county.trim(),
      overall_total: state.counties[i].overall_total,
      spread:state.counties[i].spread,
      total_lender_fee:state.counties[i].total_lender_fee,
      total_appraiser_fee:state.counties[i].total_appraiser_fee,
      total_appraiser:state.counties[i].appraiser_total,
      total_complex:state.counties[i].total_complex,
      complex_percentage:state.counties[i].complex_percentage,
      value:state.counties[i].overall_avg_turntime
    };
    data2.push(tmp);
  }

  console.log(data);
  console.log(data2);

  const columns = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.county,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallTotal',
      Header: 'Total Volume',
      accessor: d => d.overall_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserTotal',
      Header: 'Available Appraiser',
      accessor: d => d.appraiser_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall avg TAT',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails('',props.row.original.state, props.row.original.county)}>{props.row.original.overall_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(0,props.row.original.state, props.row.original.county)}>{props.row.original.unknown_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(1,props.row.original.state, props.row.original.county)}>{props.row.original.urban_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(2,props.row.original.state, props.row.original.county)}>{props.row.original.suburban_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(3,props.row.original.state, props.row.original.county)}>{props.row.original.rural_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'complex',
      Header: 'Complex',
      accessor: d => d.total_complex,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails('',props.row.original.state, props.row.original.county, 'Complex')}>{props.row.original.total_complex}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallTotal',
      Header: 'Total Volume',
      accessor: d => d.overall_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserTotal',
      Header: 'Available Appraiser',
      accessor: d => d.appraiser_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall avg TAT',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails('',props.row.original.state, '')}>{props.row.original.overall_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(0,props.row.original.state, '')}>{props.row.original.unknown_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(1,props.row.original.state, '')}>{props.row.original.urban_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(2,props.row.original.state, '')}>{props.row.original.suburban_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(3,props.row.original.state, '')}>{props.row.original.rural_avg_turntime} business day</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'complex',
      Header: 'Complex',
      accessor: d => d.total_complex,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails('',props.row.original.state, '', 'Complex')}>{props.row.original.total_complex}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let label;

  switch(state.turntimeSubmitted){
    case '1':
      label = 'Assignment Received - Completed';
      break;
    case '2':
      label = 'Assignment Received - Appraiser Assigned';
      break;
    case '3':
      label = 'Inspection Completed - Completed';
      break;
  }

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_type}</td>
            <td>{formatNumber(order.turntime)} business day</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Report type</th>
          <th>Loan type</th>
          <th>Turntime</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }


  let appraisalType='';

  if(state.filterAppraisalTypes.length>0){
      appraisalType = state.filterAppraisalTypes.join('|');
      appraisalType = appraisalType.replace(new RegExp('/','g'),'fslash');
      appraisalType = appraisalType.replace(new RegExp('[\+]','g'),'plus');
  }
  

  let table1 = <MyReactTable columns={columns} data={state.counties} className="table table-striped"/>;

  let table2 = <MyReactTable columns={columns2} data={state.statesStats} className="table table-striped"/>;
  if(data.length>0)
    map = <MyHeatMapDrillDown data={data} data2={data2} legendTitle="day" joinBy={joinBy} pointFormat={pointFormat} title="Turnaround Time (days)" onClick={heatMapOnClick}/>;

  let reportFiltersConfig1 = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>updateSelectedState(v), width:'2', group:true},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'customSelect',value:state.clientBranch,label:"Branch",options:state.clientBranches.map((branch)=>{
      return {label:branch.name, value:branch.ID}
    }), updateFunc:(v)=>setState({clientBranch:v}), width:'2'},
    {id:'customSelect',value:state.turntime,label:"Turntime",options:[
      {label:'Assignment Received - Completed', value:'1'},
      {label:'Assignment Received - Appraiser Assigned', value:'2'},
      {label:'Inspection Completed - Completed', value:'3'},
    ], updateFunc:(v)=>setState({turntime:v}), width:'2', clearable:true},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'4', className:"align-right", color:"warning"},
  ];

  return(
    <div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Turntime Report
        </CardHeader>
        <CardBody>
          <ReportFilter {...props} configs={reportFiltersConfig1}/>
          <br/>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;{label} Turntime Report
        </CardHeader>
        <CardBody>
          <center>
            <label>{label}</label>
          </center>
          <br/>
          <Card>
            <CardHeader className="header-color cursor-pointer" onClick={()=>setState({showStatesStats:!state.showStatesStats})}>
              <Row>
                <Col sm="8">
                  <i className="fa fa-edit"></i>&nbsp;States Stats
                </Col>
                <Col sm="4" className="align-right">
                  <i className={(state.showStatesStats===false)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={state.showStatesStats}>
                {map}
              </Collapse>
            </CardBody>
          </Card>

          <br/><div className="my-divider">&nbsp;</div>
          <Card>
            <CardHeader className="header-color">
              USA Overall
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="2">
                  <label>Available Appraiser</label><br/>
                  {state.usa.appraiser_total}
                </Col>
                <Col sm="2">
                  <label>Complex</label><br/>
                  {state.usa.total_complex}
                </Col>
              </Row>
              <Row>
                <Col sm="2">
                  <label>Overall TAT</label><br/>
                  {state.usa.overall_avg_turntime} business day
                </Col>
                <Col sm="2">
                  <label>Unclassified</label><br/>
                  {state.usa.unknown_avg_turntime} business day
                </Col>
                <Col sm="2">
                  <label>Urban</label><br/>
                  {state.usa.urban_avg_turntime} business day
                </Col>
                <Col sm="2">
                  <label>Suburban</label><br/>
                  {state.usa.suburban_avg_turntime} business day
                </Col>
                <Col sm="2">
                  <label>Rural</label><br/>
                  {state.usa.rural_avg_turntime} business day
                </Col>
              </Row>

            </CardBody>
          </Card>
          <br/>

          <Nav tabs>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
                onClick={() => { activeTabToggle('2'); }}
                to="#"
              >
                State Level
              </NavLinkL>
            </NavItem>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '3'?"active":"inactive" )}
                onClick={() => { activeTabToggle('3'); }}
                to="#"
              >
                County Level
              </NavLinkL>
            </NavItem>
          </Nav>
          <br/>
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="2">
              {table2}
            </TabPane>
            <TabPane tabId="3">
              {table1}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
        <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-user"></i> Report Details</h5>
          </center>
          <br/>
          <div className="large-scroll-container">
            {content}
          </div>
          <br/>
          <center>
            <Button color="info" onClick={reportDetailsToggle}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
    </div>
  );
}


export default TurntimeReport;
