//Author June Leow
//Date Jul 12th, 2024
import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { getReducer, getSetStateFunction, putAPICallGenerator } from '../../../util/util';
import Tracker from '../../tracker/tracker';
import './bottom-bar.css';
//initialize the state
const initialState = {
  announcementPopUp:[],
  announcementPopUpOpen:false
};

//reducer function that perform state update
const reducer = getReducer();


const BottomBar  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleAnnouncement=()=>{
    setState({announcementPopUpOpen:!state.announcementPopUpOpen});
  }

  //API call

  const updateSeenIndex=()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      console.log(code)
      if(code==='00'){
        toggleAnnouncement();
      }
    };
    httpPut('announcement/seenIndex', [], '', '', callBack);
  }

  //render
  let url = window.location.href;
  let className = 'invisible';
  if(url.includes('/appraisal/')){
    if(!url.includes('new-appraisal')){
      className='';
    }
  }

  let newAnnouncement;
  let newAnnouncementCount = 0;
  if(state.announcementPopUp.length>0){
    newAnnouncement = state.announcementPopUp.map(
      (announcement)=>{

        return(
          <div key={announcement.ID}>
            <div className="card">
              <div className="card-header header-color">
                <Row>
                  <Col sm="6">
                    <b>{announcement.title}</b>
                  </Col>
                  <Col className="align-right">
                    <b>{announcement.creator}&nbsp;-&nbsp;{announcement.datetime_created}</b>
                  </Col>
                </Row>
              </div>

              <div className="card-body">
                <div dangerouslySetInnerHTML={{__html:announcement.body}}/>
              </div>
            </div>
            <br/>
          </div>
        );
      }
    );

    newAnnouncementCount = state.announcementPopUp.length;
  }

  return(
    <div className="bottom-bar-container">
      <Modal className="my-modal-medium" isOpen={state.announcementPopUpOpen} toggle={toggleAnnouncement} >
        <ModalHeader hidden={true} toggle={toggleAnnouncement}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-bullhorn"></i> New Announcement</h5>
          </center>
          <div>
            <center>
              Hello, we have <font color="red">{newAnnouncementCount}</font> new announcement(s). You can view them <b>below</b> or go to <NavLink to="/announcement"><b>Announcement</b></NavLink>.
            </center>
            <br/>
            <div className="ex-large-scroll-container">{newAnnouncement}</div>
          </div>
          <br/>
          <center>
            <Button color="info" onClick={toggleAnnouncement}>Read Later</Button>&nbsp;
            <Button color="warning" onClick={()=>updateSeenIndex()}>Confirm</Button>
          </center>
        </ModalBody>
      </Modal>
      &nbsp;
      <div className={"bottom-bar-sub-container "+className}><Tracker logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/></div>
      &nbsp;
    </div>
  );
}


export default BottomBar;
